import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IconButton, Link } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ThemeInput from '../../components/shared-layout/Input'
import { ThemeDarkButton } from '../../components/shared-layout/buttons/Button'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import { UserContext } from '../../context/UserContext'
import { useContext } from 'react'
import { AuthContoller } from '../../controllers/AuthController'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageLoader from '../../components/pageLoader'


export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false);
    const userCtx = useContext(UserContext);
    const { setAuth } = useContext(UserContext);
    const { setToken } = useContext(UserContext);

    const navigate = useNavigate()



    const handleSubmit = async () => {
        if (loading === false) {
            if (email !== '' && password !== '') {
                setLoading(true);
                const instance = new AuthContoller();
                const result = await instance.loginUser(email, password);
                console.log(result, 'result');
                if (result.access_token) {
                    userCtx.setUser(result.user);
                    console.log(result.user)
                    setToken(result.access_token);
                    setAuth(true);
                    navigate("/");
                    toast('Welcome to velo');
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast(result.error);
                }
            } else {
                toast('Please enter email and password');
            }
        }
    }

    return (
        <>
            <Box sx={{
                minHeight: "100vh",
                maxHeight: "100%",
                width: { md: "400px", xs: "100%" },
                marginX: "auto",
                overflow: "hidden",
                borderInline: "1px solid lightgray",
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    // height: "100%",
                    alignItems: "center",
                    backgroundColor: "white",
                    height: "60px"
                }}>
                    <img src="/images/logos/veloBlack.svg" alt='' />
                </Box>
                <Divider />
                <Box sx={{ height: "calc(100vh - 60px)" }}>
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                    }}>
                        <Box sx={{ pt: 2, px: 5 }}>
                            <Typography sx={{ fontSize: "25px", fontFamily: "Gotham-light", fontWeight: "600" }}>LOGIN</Typography>
                        </Box>
                        <Box sx={{ px: 5 }}>
                            <Box mb={4}>
                                <ThemeInput
                                    label={"E-MAIL ADDRESS"}
                                    type='text'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </Box>
                            <Box mb={4}>
                                <ThemeInput
                                    label={"PASSWORD"}
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                            <Box mb={3}>
                                <ThemeDarkButton onClick={handleSubmit} style={{ fontSize: "15px", fontWeight: 100, fontFamily: "Gotham-Light", height: "50px", letterSpacing: "1px" }} >Login</ThemeDarkButton>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Link sx={{ cursor: "pointer", textDecoration: "none", fontFamily: "Gotham-Light", fontSize: "12px" }} onClick={() => navigate('/forgot-password')}>FORGOT PASSWORD?</Link>
                            </Box>
                        </Box>
                        <Box sx={{ pb: 8, px: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant='subtitle2' sx={{ cursor: "pointer", fontSize: "12px", fontFamily: "Gotham-Light", mb: 1 }}>DON’T HAVE AN ACCOUNT?</Typography>
                            <Link sx={{ cursor: "pointer", textDecoration: "none", fontFamily: "Gotham-Medium", fontSize: "12px" }} onClick={() => navigate("/sign-up")}>SIGN UP</Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
            {loading &&
                <PageLoader />
            }
        </>
    )
}
