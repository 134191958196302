import React, { useEffect, useState } from 'react'
import { Box, Button, InputLabel, TextField, Typography } from '@mui/material'

export default function DeleteMyAccount() {
    const [email, setEmail] = useState("")
    const [reason, setReason] = useState("")

    const handleSubmit = () => {
        console.log("data", email, reason)
    }


    return (
        <Box sx={{ position: "relative", width: "100%", height: "100vh" }}>
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                minWidth: { sm: "600px", xs: "90%" },
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                px: 2
            }}>
                <Typography sx={{ textAlign: "center", fontWeight: 700 }} variant='h5'>Delete my account</Typography>
                <Box>
                    <InputLabel sx={{ fontSize: "18px" }}>E-mail</InputLabel>
                    <TextField
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="outlined-basic"
                        type='email'
                        variant="outlined"
                    />
                </Box>
                <Box>
                    <InputLabel sx={{ fontSize: "18px" }}>Reason for deleting my account</InputLabel>
                    <TextField
                        multiline
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        sx={{ minWidth: "100%" }}
                        color="primary"
                        disabled={false}
                        minRows={8}
                        size="lg"
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={() => handleSubmit()} variant='contained'>Submit</Button>
                </Box>
            </Box>
        </Box>
    )
}
