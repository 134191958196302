import React, { PureComponent } from 'react';

import moment from 'moment';
import { Box, Typography } from '@mui/material';

export default class Date extends PureComponent {
  props: {
    // Date to render
    date: Moment,
    // Index for `onPress` and `onRender` callbacks
    index: number,
    // Whether it's the currently selected date or no
    isActive: boolean,
    // Called when user taps a date
    onPress: (index: number) => void,
    // Called after date is rendered to pass its width up to the parent component
    onRender: (index: number, width: number) => void,
  };

  // Style helper functions that merge active date styles with the default ones
  // when rendering a date that was selected by user or was set active by default

  getContainerStyle = date => ({
    ...(date.format('DD') === moment(new Date()).format('DD')
      ? styles.container2
      : styles.container),
    ...(this.props.isActive ? styles.containerActive : {}),
  });

  isToday = date => {
    if (date.format('DD') === moment(new Date()).format('DD')) {
      return true;
    } else {
      return false;
    }
  };

  getDayStyle = () => ({
    ...styles.text,
    ...styles.day,
    ...(this.props.isActive ? styles.textActive : {}),
  });

  getDateStyle = () => ({
    ...styles.text,
    ...styles.date,
    ...(this.props.isActive ? styles.textActive : {}),
  });

  // Call `onRender` and pass component's with when rendered
  onLayout = (event: {
    nativeEvent: {
      layout: { x: number, y: number, width: number, height: number },
    },
  }) => {
    const { index, onRender } = this.props;
    const {
      nativeEvent: {
        layout: { width },
      },
    } = event;
    onRender(index, width);
  };

  // Call `onPress` passed from the parent component when date is pressed
  onClick = () => {
    const { index, onClick } = this.props;
    onClick(index);
  };

  render() {
    const { date } = this.props;
    return (
      <>
      <Box
        sx={this.getContainerStyle(date)}
        // onLayout={this.onLayout}
        onClick={this.onClick}>
        <Typography sx={this.getDayStyle()}>
          {date.format('ddd').toUpperCase()}
        </Typography>
        <Typography sx={this.getDateStyle()}>{date.format('DD')}</Typography>
      </Box>
      {this.isToday(date) === true && (
          <Box
            style={{
              height: "2px",
              backgroundColor: '#000',
              marginTop: "4px",
              width: "30px",
              alignSelf: 'center',
              borderRadius: "20px",
              marginLeft:'auto',
              marginRight:'auto'
            }}></Box>
        )}
      </>
    );
  }
}

const styles = {
  container: {
    px: "7px",
    py: "4px",
    backgroundColor: '#f2f2f2',
    mx: "2px",
    borderRadius: "10px",
    marginTop: "5px",
    borderWidth: "1px",
    borderColor: 'transparent'
  },
  container2: {
    px: '7px',
    py: '4px',
    backgroundColor: '#f2f2f2',
    mx: "2px",
    borderRadius: '10px',
    marginTop: "5px",
    borderWidth: "1px",
    borderColor: '#161415'
  },
  containerActive: {
    backgroundColor: '#161415'
  },
  day: {
    fontSize: "10px",
    fontFamily:'Gotham-Medium'
  },
  date: {
    fontSize: "12px",
    color:'#000',
    fontFamily:'Gotham-Medium',
  },
  text: {
    color: '#161415',
    textAlign: 'center',
    fontFamily:'Gotham-Medium'
  },
  textActive: {
    color: '#fff',
    fontFamily:'Gotham-Medium'

  },
};
