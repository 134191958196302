import { Box, Button, Dialog, DialogActions, DialogContent, Slide, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TermsController } from '../../controllers/TermsController';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TermsAndCondition({ open, handleClose }) {
    const [data, setData] = useState({});

    useEffect(() => {
        getTerms();
    }, [])
    const getTerms = async () => {
        const instance = new TermsController();
        const result = await instance.getTermsCondition();
        if (result.status === 'success') {
            setData(result.terms_and_conditions);
        }
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <DialogContent>
                <Box sx={{ pt: 1.5, pb: 3 }}>
                    <Typography variant='button' sx={{ fontFamily: "Gotham-Book", mb: 1 }}>TERMS & CONDITIONS</Typography>

                    <Box sx={{ padding: "12px", maxHeight: "560px", overflow: "auto" }}>

                        <Box sx={{ mt: 1 }}>
                            <Typography fontSize={'12px'}>{data.description}</Typography>
                        </Box>

                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", }}>
                    <Button variant='contained' onClick={handleClose}>Close</Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
