import React, { useContext } from 'react'
import MainLayout from '../../layout/mainLayout'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography'
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import { FaqsController } from '../../controllers/FaqsController';
import { UserContext } from '../../context/UserContext';
import PageLoader from '../../components/pageLoader';

const FaqPage = () => {

    const [expanded, setExpanded] = React.useState(false);
    const { getToken } = useContext(UserContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    useEffect(() => {
        getAllFaqs();
    },[])

    const getAllFaqs = async() => {
        setLoading(true)
        const token =  await getToken();
        const instance = new FaqsController();
        const result = await instance.getFaqs(token);
        setLoading(false)
        setData(result.faqs)
    }



    return (
        <>
            {loading === true &&
                <PageLoader />
            }
            
        <MainLayout>
            <Box sx={{ px: 4, pt: 1.5, pb: 3, }}>
                <Typography variant='button' sx={{ fontFamily: "Gotham-Book" }}>FAQ</Typography>

                <Box sx={{ padding: "12px"}}>
                    {data.map((item, index) =>
                        <Accordion key={index} sx={{ backgroundColor: "#f2f2f2", mb: "15px", borderRadius: "15px !important", boxShadow: "rgba(0, 0, 0, 0.34) 0px 1px 9px;", "&::before": { opacity: 0 } }} expanded={expanded === index} onChange={handleChange(index)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '100%', fontSize: "10px", fontFamily: "Gotham-Book" }}>
                                    {item.questions}
                                </Typography>
                            </AccordionSummary>

                            <Divider sx={{ borderWidth: "1px" }} />

                            <AccordionDetails sx={{ backgroundColor: "white", paddingTop: "15px", borderRadius: "0px 0px 15px 15px" }}>
                                <Typography sx={{ width: '100%', fontSize: "10px", fontFamily: "Gotham-Book" }}>
                                    {item.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>)}
                </Box>

                <Typography variant='button' sx={{ fontFamily: "Gotham-Book", fontSize: "15px" }} >CONTACT</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: 'column' }}>

                    <Box component={'a'} sx={{ display: "flex", justifyContent: "center", alignItems: "center",textDecoration:'none',color:'#161415',margin:'5px 0px' }} href="whatsapp://send?text=Hello&phone=+97444800204">
                            <img src="/images/icons/whatsapp.png" style={{ width: "16px",height:"16px", marginRight: "4px" }} />
                            <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "15px" }}>+974 4480 0204</Typography>
                        </Box>

                        <Box component={'a'} sx={{ display: "flex", justifyContent: "center", alignItems: "center",textDecoration:'none',color:'#161415',margin:'5px 0px' }} href={`tel:${+97444800204}`}>
                        <img src="/images/icons/phone-call.png" style={{ width: "16px",height:"16px", marginRight: "4px" }} />
                            <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "15px" }}>+974 4480 0204</Typography>
                        </Box>

                        <Box component={'a'} sx={{ display: "flex", justifyContent: "center", alignItems: "center",textDecoration:'none',color:'#161415',margin:'5px 0px' }} href="mailto:info@velo.qa">
                        <img src="/images/icons/email.png" style={{ width: "16px",height:"16px", marginRight: "4px" }} />
                            <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "15px" }}  >info@velo.qa</Typography>
                        </Box>

                        <Box component={'a'} sx={{ display: "flex", justifyContent: "center", alignItems: "center",textDecoration:'none',color:'#161415',margin:'5px 0px' }} href="https://www.instagram.com/veloqatar/">
                        <img src="/images/icons/instagram.png" style={{ width: "16px",height:"16px", marginRight: "4px" }} />
                            <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "15px" }}>@veloqatar</Typography>
                        </Box>

                    </Box>
                </Box>
                {/* {supportData &&
                    <>
                        <Typography sx={{ paddingLeft: 15, height: 30, textTransform: 'uppercase' }}>
                            {supportData.heading}
                        </Typography>

                        <Box p={2}>
                            <Typography sx={{
                                fontSize: "14px",
                                fontFamily: 'Gotham-Book',
                                lineHeight: "18px",
                            }} >{supportData.description}
                            </Typography>
                        </Box>
                    </>
                } */}
            </Box>
        </MainLayout >
        </>
    )
}

export default FaqPage