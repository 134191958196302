import React, { useState, useContext, useEffect } from 'react'
import ViewLayout from '../../layout/viewLayout'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ThemeDarkButton, ThemeLightButton } from '../../components/shared-layout/buttons/Button'
import AppleIcon from '@mui/icons-material/Apple';
import { BuyController } from '../../controllers/BuyController'
import { UserContext } from '../../context/UserContext'
import { ToastContainer, toast } from 'react-toastify'
import PageLoader from '../../components/pageLoader'
import { deviceDetect, mobileModel } from "react-device-detect"


export default function PaymentMethod({ item }) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [device, setDevice] = useState("")
    console.log("device: ", device);
    const { getToken } = useContext(UserContext);

    useEffect(() => {
        setDevice(mobileModel)
        console.log("deviceData deviceDetect", deviceDetect())
    }, [])


    const payNow = (val) => {
        if (val === 'wallet') {
            handlePayment('Wallet');
        } else if (val === 'debit') {
            handlePayment('Package');
        }
        else if (val === 'gpay') {
            handlePayment('Gpay')
        }
        else if (val === 'apple') {
            handlePayment('ApplePay')
        }
    }




    const handlePayment = async (type) => {
        setLoading(true)
        const token = getToken();
        const instance = new BuyController();
        const result = await instance.paymentPackage(item, type, token);
        setData(result)
        if (result.IsSuccess === true) {
            window.location.href = result.Data.PaymentURL;
        } else {
            toast(result.msg)
            setLoading(false)
        }
    }


    return (
        <>
            <ToastContainer autoClose={2000} />
            {loading ? <PageLoader /> :
                <Box sx={{
                    height: "calc(100vh - 60px)",
                    position: "relative",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    flexDirection: "column",
                    p: 6,
                    alignItems: "center",
                }}>
                    <Typography sx={{ fontSize: "18px", fontFamily: "Gotham-Medium", mb: 8 }}>PAYMENT METHOD</Typography>
                    <Box sx={{ display: "flex", width: "100%", flexDirection: "column", rowGap: 2 }}>

                        <ThemeDarkButton onClick={() => payNow('gpay')} style={{ height: "50px", width: "100%" }}>
                            <img src="images/icons/google.png" style={{ width: 24, height: 24, marginRight: 10 }} alt='' />
                            <Typography sx={{ fontSize: "17px", fontFamily: "Gotham-Medium" }}>
                                PAY
                            </Typography>
                        </ThemeDarkButton>

                        {device && device === "iPhone" &&
                            <ThemeDarkButton onClick={() => payNow('apple')} style={{ height: "50px", width: "100%" }}>
                                <img src="images/icons/apple.png" style={{ width: 24, height: 24, marginRight: 10, filter: "invert(1)" }} alt='' />
                                <Typography sx={{ fontSize: "17px", fontFamily: "Gotham-Medium" }}>
                                    PAY
                                </Typography>
                            </ThemeDarkButton>
                        }


                        <ThemeDarkButton onClick={() => payNow('wallet')} style={{ height: "50px", width: "100%" }}>
                            <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "17px" }}>WALLET</Typography>
                        </ThemeDarkButton>

                        <ThemeDarkButton onClick={() => payNow('debit')} style={{ height: "50px", width: "100%" }}>
                            <Box>
                                <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>PAY WITH DEBIT/ CREDIT CARD</Typography>
                            </Box>
                        </ThemeDarkButton>
                    </Box>

                </Box>
            }
        </>
        // </ViewLayout>
    )
}
