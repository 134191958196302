import React, { useState, useEffect, useContext } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Typography, Box, Avatar } from '@mui/material'
import { ThemeLightButton } from '../../components/shared-layout/buttons/Button';
import { useNavigate } from 'react-router';
import { ProfileController } from '../../controllers/ProfileController';
import { UserContext } from '../../context/UserContext';
import PageLoader from '../../components/pageLoader';
import { API_SUCCESS } from '../../config/ApiConfig';


const Profile = () => {
    const { getToken } = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({});

    const navigate = useNavigate()

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = async () => {
        setLoading(true)
        const token = await getToken();
        console.log("token: ", token);
        const instance = new ProfileController();
        const result = await instance.getUserDetail(token);
        setUser(result.user);
        setLoading(false)
    }

    return (
        <MainLayout>
            <Box sx={{ px: 4, pb: 1.5, position: "relative" }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                    <Box sx={{ display: "flex", width: "120px", justifyContent: 'center', alignItems: "center", mt: 6.5, position: "relative", }}>
                        <Box sx={{ overflow: "hidden", borderRadius: "50%", width: "120px", height: "120px" }}>
                            <Avatar src={API_SUCCESS + '/' + user.image} sx={{ width: "100%", height: "100%" }} />
                        </Box>
                    </Box>

                    {!loading ?
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", mt: 1 }}>
                            <Typography sx={{ fontWeight: 600, fontSize: "19px", letterSpacing: "1px", fontFamily: "Gotham-Medium", textTransform: "uppercase" }}>{user?.first_name} {user?.last_name}</Typography>
                            <Typography sx={{ fontSize: "11px", letterSpacing: "1px", fontFamily: "Gotham-Light", textTransform: "uppercase", mb: 0.2 }}>{user?.email}</Typography>
                            <Typography sx={{ fontSize: "11px", letterSpacing: "1px", fontFamily: "Gotham-Light", textTransform: "uppercase" }}>{user?.phone}</Typography>
                        </Box>
                        : <><PageLoader /></>
                    }

                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column", mt: 2, px: 2 }}>
                        <ThemeLightButton onClick={() => navigate('/buy-package')} style={{ fontSize: "14px", mb: 2, boxShadow: "none", fontFamily: "Gotham-Book", }}>  MY PACKAGES </ThemeLightButton>
                        <ThemeLightButton onClick={() => navigate('/change-password')} style={{ fontSize: "14px", mb: 2, boxShadow: "none", fontFamily: "Gotham-Book", }}>  CHANGE PASSWORD </ThemeLightButton>
                        <ThemeLightButton onClick={() => navigate('/add-card')} style={{ fontSize: "14px", mb: 2, boxShadow: "none", fontFamily: "Gotham-Book", }}>  MY WALLET </ThemeLightButton>
                        <ThemeLightButton onClick={() => navigate('/journey')} style={{ fontSize: "14px", mb: 2, boxShadow: "none", fontFamily: "Gotham-Book", }}>  JOURNEY </ThemeLightButton>
                        <ThemeLightButton onClick={() => navigate('/delete-my-account')} style={{ fontSize: "14px", mb: 2, boxShadow: "none", fontFamily: "Gotham-Book", }}>  DELETE MY ACCOUNT </ThemeLightButton>
                    </Box>
                </Box>
            </Box>
        </MainLayout>
    )
}

export default Profile