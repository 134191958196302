import React from 'react';
import './App.css';
import AppRoutes from './routes';
import ThemeComponent from './theme/themeComponent';
import UserProvider from './context/UserContext';
import { RecoilRoot } from 'recoil';


function App() {
  return (
    <>

      <RecoilRoot>
        <ThemeComponent>
          <UserProvider>
            <AppRoutes />
          </UserProvider>
        </ThemeComponent>
      </RecoilRoot>
    </>
  );
}

export default App;
