import React from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useState, useEffect, useRef, useContext } from 'react';
import { RoundedDarkButton, RoundedLightButton } from '../../components/shared-layout/buttons/Button';
import Badge from '@mui/material/Badge';
import ShoppingCard from '../../components/shopping/shoppingCard';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { GroveController } from '../../controllers/GroveController';
import PageLoader from '../../components/pageLoader';
import ViewLayout from '../../layout/viewLayout';

export default function DoubleJoy() {
    const [loading, setLoading] = useState(false)
    const [height, setHeight] = useState(null)
    const [category, setCategory] = useState("")
    const [single, setSingle] = useState("")
    const [id, setId] = useState("all")
    const [cartData, setCartData] = useState("")
    const [active, setActive] = useState("all")
    const navigate = useNavigate()
    const { getToken } = useContext(UserContext);


    const ref = useRef()

    const getAllData = async () => {
        setLoading(true)
        const token = getToken()
        const instance = new GroveController()
        const result = await instance.getDoubleJoy(token)
        const order = await instance.getCartItem(token)
        let newResult = [];
        result.data.map(item =>
            item?.relation?.optional_items.map((item1, index1) => {
                if (order?.data?.relation?.items?.length > 0) {
                    let citem = order?.data?.relation?.items?.filter(
                        i => i.attributes.optional_item_id === JSON.parse(item1.id),
                    );
                    if (citem.length > 0) {
                        item1.quantity = citem[0].attributes.quantity;
                        newResult.push({ catId: item.id, ...item1 });
                    } else {
                        item1.quantity = 0;
                        newResult.push({ catId: item.id, ...item1 });
                    }
                    setLoading(false)
                } else {
                    item1.quantity = 0;
                    newResult.push({ catId: item.id, ...item1 });
                    setLoading(false)
                }
                setLoading(false)
            })
        );
        setCategory(result.data)
        setCartData(order.data)
        setLoading(false)
    }

    const handleJoyList = (id, index) => {
        if (id === "all") {
            let Arr = [];
            category.forEach((category) => category.relation.optional_items.forEach(item => Arr.push(item)))
            setSingle(Arr)
            setActive("all");
        } else {
            const result = category.filter(data => id === data.id)
            setSingle(result[0].relation.optional_items)
            setActive(index);
        }
        setId(id)
    }

    useEffect(() => {
        if (category.length) {
            handleJoyList(id, active);
        }
    }, [category]);

    const handleAddItem = async item => {
        const token = await getToken();
        const instance = new GroveController();
        let myNotes = '';
        let getAddons = [];

        if (item.quantity > 0) {
            const myCartItem = cartData.relation.items.filter(
                i => i.attributes.optional_item_id === JSON.parse(item.id),
            );
            if (myCartItem[0].attributes.addons.length > 0) {
                getAddons = JSON.parse(myCartItem[0].attributes.addons);
            }
            if (myCartItem[0].attributes.notes) {
                myNotes = myCartItem[0].attributes.notes;
            }
        }

        const result = await instance.addItem(token, item.id, item.quantity + 1, myNotes, JSON.stringify(getAddons));
        if (result.status === 'success') {
            getAllData()
        }
    };

    const handleRemoveItem = async (item) => {
        const token = await getToken();
        const instance = new GroveController();
        let myNotes = '';
        let getAddons = [];

        if (item.quantity > 0) {
            const myCartItem = cartData.relation.items.filter(
                i => i.attributes.optional_item_id === JSON.parse(item.id),
            );
            if (myCartItem[0].attributes.addons.length > 0) {
                getAddons = JSON.parse(myCartItem[0].attributes.addons);
            }
            if (myCartItem[0].attributes.notes) {
                myNotes = myCartItem[0].attributes.notes;
            }
        }

        if (item.quantity > 1) {
            const result = await instance.addItem(token, item.id, item.quantity - 1, myNotes, JSON.stringify(getAddons));
            if (result.status === 'success') {
                getAllData()
            }
        } else {
            setLoading(true);
            const result = await instance.removeItem(token, item.id);
            if (result.status === 'success') {
                getAllData()
            } else {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        setHeight(ref.current.offsetHeight);
        getAllData()
    }, [])


    return (
        <ViewLayout backFunction={"/"} title="DOUBLE JOY">
            <Box sx={{ height: "calc(100vh - 90px)", position: "relative" }} mx={2}>
                <Box ref={ref} pt={2}>
                    <Box sx={{ position: "relative", mb: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <RoundedDarkButton
                            onClick={() => navigate("/doublejoy-order")}
                            style={{ py: 0.5, px: 2, width: "auto" }}>
                            <Box component={"span"} sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                fontSize: "12px",
                                fontFamily: "Gotham-Book"
                            }}>
                                MY ORDER
                            </Box>
                        </RoundedDarkButton>
                        <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                            <IconButton onClick={() => navigate("/cart")}>
                                <Badge badgeContent={cartData?.relation?.items.length} color="error">
                                    <ShoppingCartOutlinedIcon sx={{ color: "black" }} />
                                </Badge>
                            </IconButton>
                        </Box>
                    </Box>
                    {category &&
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2, overflowX: "auto", width: "100%" }} mt={2}>
                            {active === 'all' ?
                                <RoundedDarkButton
                                    style={{ minWidth: "30%", maxWidth: "30%", px: 2, mb: 1, }}
                                >
                                    <Box component={"span"} sx={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                        fontFamily: "Gotham-Book"
                                    }}>
                                        All
                                    </Box>
                                </RoundedDarkButton>
                                :
                                <RoundedLightButton
                                    style={{ minWidth: "30%", maxWidth: "30%", px: 2, mb: 1, }}
                                    onClick={() => handleJoyList("all", "all")}                            >
                                    <Box component={"span"} sx={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                        fontFamily: "Gotham-Book"
                                    }}>
                                        All
                                    </Box>
                                </RoundedLightButton>
                            }
                            {/* {category.map((data, index) =>
                                <RoundedLightButton
                                    key={index}
                                    style={{ minWidth: "30%", maxWidth: "30%", px: 2, mb: 1, }}
                                    onClick={() => handleJoyList(data.id, index)}                           >
                                    <Box component={"span"} sx={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                        fontFamily: "Gotham-Book"
                                    }}>
                                        {data.attributes.name}
                                    </Box>
                                </RoundedLightButton>
                            )} */}
                            {category.filter(data => data.relation.optional_items.length > 0).map((data, index) =>
                                <>
                                    {active === index ?
                                        <RoundedDarkButton
                                            key={index}
                                            style={{ minWidth: "30%", maxWidth: "30%", px: 2, mb: 1, }}
                                        // onClick={() => handleJoyList(data.id, index)}     
                                        >
                                            <Box component={"span"} sx={{
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                fontSize: "12px",
                                                overflow: "hidden",
                                                fontFamily: "Gotham-Book",
                                            }}>
                                                {data.attributes.name}
                                            </Box>
                                        </RoundedDarkButton>
                                        :
                                        <RoundedLightButton
                                            key={index}
                                            style={{ minWidth: "30%", maxWidth: "30%", px: 2, mb: 1, }}
                                            onClick={() => handleJoyList(data.id, index)}                           >
                                            <Box component={"span"} sx={{
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                fontSize: "12px",
                                                overflow: "hidden",
                                                fontFamily: "Gotham-Book",
                                            }}>
                                                {data.attributes.name}
                                            </Box>
                                        </RoundedLightButton>
                                    }
                                </>
                            )}
                        </Box>
                    }
                </Box>

                <Box sx={{
                    height: `calc(100vh - (140px + ${height}px))`,
                    maxHeight: "100%",
                    overflowY: "auto",
                    py: 2,
                    '&::-webkit-scrollbar': { width: 0 }
                }}>
                    {single &&
                        <Grid container>
                            {single.map((data, index) =>
                                <Grid key={index} item xs={6}>
                                    <ShoppingCard
                                        data={data}
                                        handleAddItem={() => handleAddItem(data)}
                                        handleRemoveItem={() => handleRemoveItem(data)}
                                        onClick={() => navigate(`/items?id=${data.id}`)}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    }
                </Box>
            </Box>
            {loading && <PageLoader />}
        </ViewLayout>
    )
}
