import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, Divider } from '@mui/material';
import { Link } from '@mui/material';
import OtpInput from 'react-otp-input';
import { AuthContoller } from '../../controllers/AuthController';
import { toast } from 'react-toastify';
import { ThemeDarkButton } from '../../components/shared-layout/buttons/Button';

export default function Verify() {
    const navigate = useNavigate()
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams()
    const email = searchParams.get('email')

    const resendOtp = async () => {
        setLoading(true);
        const instance = new AuthContoller();
        const otpResult = await instance.resendOtp(email);
        if (otpResult.status === 'success') {
            toast(otpResult.message);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };
    const verifyOtp = async () => {
        if (otp && otp?.length === 6) {
            setLoading(true)
            const instance = new AuthContoller();
            const result = await instance.verifyOtp(email, otp);
            if (result.status === 'success') {
                toast.success(result.message);
                setLoading(false)
                navigate('/login');
            } else {
                setLoading(false)
                toast.error(result.message);
            }
        } else {
            toast('Invalid OTP');
        }
    };

    return (
        <>
            <Box sx={{
                minHeight: "100vh",
                maxHeight: "100%",
                width: { md: "400px", xs: "100%" },
                marginX: "auto",
                overflow: "hidden",
                borderInline: "1px solid lightgray",
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    // height: "100%",
                    alignItems: "center",
                    backgroundColor: "white",
                    height: "60px"
                }}>
                    <img src="/images/logos/veloBlack.svg" alt='' />
                </Box>
                <Divider />
                <Box sx={{ height: "calc(100vh - 60px)" }}>
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                    }}>
                        <Box sx={{ pt: 2, px: 5 }}>
                            <Typography sx={{ fontSize: "25px", fontFamily: "Gotham-light", fontWeight: "600" }}>Verify</Typography>
                        </Box>
                        <Box sx={{ px: 5 }}>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    color: '#333',
                                    fontSize: 12,
                                    fontFamily: 'Gotham-Medium',
                                    mb: 5
                                }}
                            >
                                We have sent a verification code on your email
                                <Typography component={"span"} sx={{ color: '#000', fontWeight: '700' }}> {email} </Typography>
                                please enter and verify.
                            </Typography>
                            <Box sx={{ mb: 2 }}>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    containerStyle={{ width: "100%", justifyContent: "center", columnGap: 5 }}
                                    inputStyle={{ fontSize: "20px" }}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </Box>
                            <Box sx={{ mb: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Link sx={{ cursor: "pointer", textDecoration: "none", fontFamily: "Gotham-Medium", fontSize: "12px" }} onClick={() => resendOtp()}>Resend OTP</Link>
                            </Box>
                            <Box>
                                <ThemeDarkButton style={{ fontSize: "18px" }} onClick={verifyOtp}>Verify</ThemeDarkButton>
                            </Box>
                        </Box>
                        <Box sx={{ pb: 8, px: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Link sx={{ cursor: "pointer", textDecoration: "none", fontFamily: "Gotham-Medium", fontSize: "12px" }} onClick={() => navigate("/sign-up")}>SIGN UP</Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
