import React, { useState, useContext, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { RoundedDarkButton, RoundedLightButton, RoundedRedButton } from '../shared-layout/buttons/Button'
import { BuyController } from '../../controllers/BuyController';
import BookNow from '../modal/bookNow';
import { UserContext } from '../../context/UserContext';
import ModalLayout from '../modal/ModalLayout';
import { toast } from 'react-toastify';



export default function BookingLayoutFooter({ booking, loading1, setLoading1, seatData, handleBook, userData, handleUpdate, joinWaiting, cancelBooking }) {
    console.log("seatData: ", seatData);
    const [open, setOpen] = useState(false)
    const [userpackage, setUserPackages] = useState()
    const { getToken } = useContext(UserContext);
    const [cancelModal, setCancelModal] = useState(false);
    const [waitingVal, setWaitingVal] = useState(false)

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };


    const getUserAllPackages = async () => {
        setLoading1(true)
        const token = await getToken();
        const instance = new BuyController();
        const result = await instance.getUserPackages(token);
        if (result.status === 'error') {
            setLoading1(false)
        } else {
            setUserPackages(result);
            setLoading1(false)
        }
    };

    const Checkout = async type => {
        if (waitingVal === true) {
            const data = {
                classes_id: userData.id,
                type: type,
                device: 'mobile',
            };
            joinWaiting(data);
        }
        else {
            const data = {
                classes_id: userData.id,
                type: type,
                seat: seatData.seat,
                seat_text: seatData.seat_text,
                device: 'mobile',
            };
            handleBook(data);
        }
    };

    const CheckoutFromPackage = async value => {
        if (waitingVal === true) {
            const data = {
                classes_id: userData.id,
                type: 'Package',
                device: 'mobile',
                package_id: value.id,
                seat_text: seatData.seat_text,
            };
            joinWaiting(data);
        }
        else {
            const data = {
                classes_id: userData.id,
                type: 'Package',
                seat: seatData.seat,
                device: 'mobile',
                seat_text: seatData.seat_text,
                package_id: value.id,
            };
            handleBook(data);
        }
    };


    const handleBooking = () => {
        if (seatData?.seat_text) {
            getUserAllPackages()
            setOpen(true)
        }
        else {
            toast("please select seat.")
        }
    }

    const handleJoinWaiting = () => {
        setWaitingVal(true)
        setOpen(true)
    }

    const renderButton = () => {
        if (userData?.attributes) {
            if (
                userData?.attributes?.mine_booking === true &&
                userData?.attributes?.user_waiting === false
            ) {
                return (
                    <Box>
                        <Typography sx={{ fontSize: "8px", textAlign: "center", fontFamily: 'Gotham-medium', lineHeight: '12px' }}>BOOKED</Typography>
                        <RoundedRedButton onClick={() => setCancelModal(true)} style={{ fontSize: "10px", padding: '3px 3px', fontFamily: 'Gotham-medium', }}>CANCEL</RoundedRedButton>
                        <RoundedDarkButton onClick={handleUpdate} style={{ fontSize: "10px", padding: '3px 3px', fontFamily: 'Gotham-medium' }}>UPDATE BIKE</RoundedDarkButton>
                    </Box>
                );
            } else if (userData?.attributes?.user_waiting === true) {
                return (
                    <RoundedDarkButton style={{ fontSize: "10px", padding: '3px 3px', fontFamily: 'Gotham-medium' }} onClick={cancelBooking}>LEAVE WAITLIST</RoundedDarkButton>
                );
            } else if (
                userData.attributes.user_waiting === false &&
                userData.attributes.mine_booking === false &&
                userData.attributes.booking_count_status.available === 0 &&
                userData.attributes.booking_count_status.waiting_available !== 0
            ) {
                return (
                    <RoundedDarkButton onClick={() => handleJoinWaiting()} style={{ fontSize: "10px", padding: '3px 3px', fontFamily: 'Gotham-medium' }}>JOIN WAITLIST</RoundedDarkButton>
                );
            }
            else if (userData.attributes.booking_count_status.waiting_available === 0 &&
                userData.attributes.booking_count_status.available === 0 &&
                userData.attributes.user_waiting === false &&
                userData.attributes.mine_booking === false) {
                return (
                    <RoundedDarkButton style={{ fontSize: "10px", padding: '3px 3px', fontFamily: "Gotham-Medium" }}>FULLY BOOKED</RoundedDarkButton>
                )
            }
            else {
                return (
                    <RoundedDarkButton onClick={() => handleBooking()} style={{ fontSize: "10px", padding: '3px 3px', fontFamily: "Gotham-Medium" }}>BOOK NOW</RoundedDarkButton>
                );
            }
        }
    }
    const renderWaiting = () => {
        if (
            userData.attributes.user_waiting === false &&
            userData.attributes.mine_booking === false &&
            userData.attributes.booking_count_status.available === 0 &&
            userData.attributes.booking_count_status.waiting_available !== 0
        ) {
            return (
                <Typography sx={{ fontSize: "8px", fontFamily: "Gotham-Book" }}>WAITING LIST : {userData?.attributes?.booking_count_status?.waiting}</Typography>
            )
        }
        else if (userData.attributes.user_waiting === true) {
            return (
                <Typography sx={{ fontSize: "8px", fontFamily: "Gotham-Book" }}>WAITING : {booking.waiting_no}</Typography>
            )
        }
    }

    return (
        <>
            {userData.attributes &&
                <Grid sx={{ px: 2, alignItems: "flex-end" }} spacing={2} container>
                    <Grid item xs={8}>
                        {userData.attributes && userData.attributes.mine_booking === true ?
                            <>
                                <Typography sx={{ fontSize: "8px", fontFamily: "Gotham-Book" }}>THOSE WHO ARRIVE 5 MINS AFTER THE START OF THE CLASS WILL NOT BE PERMITTED TO ENTER</Typography>
                            </>
                            :
                            <>
                                {renderWaiting()}
                                <Typography sx={{ fontSize: "8px", fontFamily: "Gotham-Book", textAlign: "justify" }}>YOU WILL AUTOMATICALLY BE ENROLLED IN THE CLASS WHEN THERE IS AVAILABILITY</Typography>
                            </>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            {renderButton()}
                        </Box>

                        <ModalLayout openModal={cancelModal} setCancel={() => setCancelModal} title={"CANCEL BOOKING"} >
                            <Typography>
                                Are you sure you want to cancel your booking?
                            </Typography>
                            <Box sx={{ display: "flex", rowGap: 1, flexDirection: "row", justifyContent: 'center', marginTop: 3 }}>
                                <RoundedDarkButton onClick={() => cancelBooking()} style={{ fontSize: "10px", width: '100px' }}>Yes</RoundedDarkButton>
                                <RoundedLightButton onClick={() => setCancelModal(false)} style={{ marginLeft: 1, fontSize: "10px", width: '100px' }}>No</RoundedLightButton>
                            </Box>
                        </ModalLayout>
                        <BookNow
                            open={open}
                            CheckoutFromPackage={CheckoutFromPackage}
                            Checkout={Checkout}
                            seatText={seatData.seat_text}
                            waitingVal={waitingVal}
                            userpackage={userpackage}
                            item={userData}
                            handleBook={handleBook}
                            toggleDrawer={toggleDrawer}
                            title={"proceed to checkout"}
                            type={"unlimited"}
                        />
                    </Grid>
                </Grid>
            }
        </>
    )
}
