import { Box, Button, Typography } from '@mui/material'
import React from 'react'

export default function BackDiv({ title, sx, img, onClick ,imageStyle }) {
    return (
        <>
            <Button
                onClick={onClick}
                sx={{
                    p: 0,
                    ...sx
                }} fullWidth>
                <Box
                    sx={{
                        width: '100%',
                        borderRadius: "16px",
                        position: "relative",
                        p: 4,
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow:"hidden",
                    }}
                >
                    <img style={{ position: "absolute", width: "100%", height: "100%" ,...imageStyle }} src={img} alt='' />
                    <Typography variant='button' sx={{ color: "white", zIndex: 2, fontSize: "22px", fontFamily: "Gotham-light",fontWeight:400, textTransform: "uppercase" }}>{title}</Typography>
                </Box>
            </Button>
        </>
    )
}
