import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
// import { useNavigate } from 'react-router-dom'
import { CustomShadowCard } from './card';

export default function NotificationModel({ OpenNotification, viewNotification, notificationData, clearNotification }) {
  // const navigate = useNavigate()

  return (
    <Box sx={{
      minHeight: "100vh",
      maxHeight: "100%",
      width: { md: "400px", xs: "100%" },
      marginX: "auto",
      borderInline: "1px solid lightgray",
    }}>
      <Box sx={{ position: "fixed", width: { md: "400px", xs: "100%" }, height: "42px", zIndex: "9999" }}>
        <Box sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}>
          <Box sx={{ position: "absolute", top: 0, left: 0, bottom: 0, my: "auto", display: "flex" }}>
            <IconButton onClick={OpenNotification}>
              <img src="/images/icons/back.png" style={{ width: 20, height: 20 }} alt='menu icon' />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: "42px", height: "calc(100vh - 42px)", width: { md: "400px", xs: "100%" }, marginX: "auto" }}>
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant='button' sx={{ fontFamily: "Gotham-Book" }}>NOTIFICATIONS</Typography>
            <IconButton disabled={notificationData} onClick={clearNotification}>
              <Typography variant='button' sx={{ fontFamily: "Gotham-Book" }}>clear</Typography>
            </IconButton>
          </Box>
          {notificationData ?
            <Box sx={{ padding: "12px", maxHeight: "560px", overflow: "auto" }}>
              {notificationData.map((item, index) =>
                <Box key={index} sx={{ backgroundColor: "#f6f6f6", paddingX: 2, paddingY: 1.5, mb: "15px", borderRadius: "10px", boxShadow: "rgba(0 0 0 / 19%) 4px 5px 4px 0px;" }}>
                  <Grid onClick={() => viewNotification(item.id)} container spacing={1}>
                    <Grid item xs={2.5}>
                      <CustomShadowCard sx={{ p: 1, borderRadius: "8px", }}>
                        <Box sx={{
                          display: "flex",
                          justifyContent: "center",
                          overflow: "hidden",
                          aspectRatio: "1/1"
                        }}>
                          <img src="/images/logos/veloBlack.svg" alt='' width={"100%"} />
                        </Box>
                      </CustomShadowCard>
                    </Grid>
                    <Grid item xs={9.5}>
                      <Box pb={0.5}>
                        <Typography sx={{ fontSize: "14px", pb: 0.5, fontWeight: 400, fontFamily: "Gotham-Medium" }}>{item.attributes.title}</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: 400, fontFamily: "Gotham-Book" }}>{item.attributes.message}</Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "12px", fontWeight: 400, fontFamily: "Gotham-Medium", textAlign: "end" }}>{item.attributes.date}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
            :
            <></>
          }
        </Box>
      </Box>
    </Box>
  )
}
