import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'
import { ThemeLightButton } from '../../components/shared-layout/buttons/Button'
import { useNavigate } from 'react-router-dom'

export default function WelcomePage() {

  const navigate = useNavigate()

  const handleLogin = ()=>{
    navigate("/login")
  }

  const handleSignup = ()=>{
    navigate("/sign-up")
  }


  return (
    <Box sx={{ backgroundColor: "black", minHeight: "100vh", width: { md: "400px", xs: "100%" }, position: "relative", mx: "auto" }}>
      <Box sx={{
        width: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50% ,-50%)",
      }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img src="/images/logos/veloWhite.svg" alt='' width="38%" />
          <Typography sx={{ color: "#fff", mt: 2, fontSize: "18px", letterSpacing: "1px" ,fontFamily:"Gotham-Medium", fontWeight: "600"}} variant='caption'>EMBRACE EVERY MOVEMENT</Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%", position: "absolute", bottom: 0 }}>
        <Box sx={{ px: 5, pb: 10, display: "flex", flexDirection: "column", rowGap: 2 }}>
          <ThemeLightButton style={{ fontSize: "16px", fontFamily: "Gotham-Medium", fontWeight: "600" }} onClick={handleLogin}>Login</ThemeLightButton>
          <ThemeLightButton style={{ fontSize: "16px", fontFamily: "Gotham-Medium", fontWeight: "600" }} onClick={handleSignup}>Sign Up</ThemeLightButton>
        </Box>
      </Box>
    </Box>
  )
}
