import Box from '@mui/material/Box';
import React from 'react'
import IconButton from '@mui/material/IconButton';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ViewLayout({ children, title, backgroundColor, backFunction }) {
    const navigate = useNavigate()


    return (
        <>
            <Box sx={{
                minHeight: "100vh",
                maxHeight: "100%",
                width: { md: "400px", xs: "100%" },
                marginX: "auto",
                overflow: "hidden",
                borderInline: "1px solid lightgray",
            }}>
                <Box sx={{ position: "fixed", width: { md: "400px", xs: "100%" }, height: "42px", zIndex: "9999" }}>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        backgroundColor: backgroundColor
                    }}>
                        <Box sx={{ position: "absolute", top: 0, left: 0, bottom: 0, my: "auto", display: "flex" }}>
                            <IconButton onClick={() => navigate(backFunction ? backFunction : -1)}>
                                <img src="/images/icons/back.png" style={{ width: 20, height: 20 }} alt='menu icon' />
                            </IconButton>
                        </Box>
                        <Typography sx={{ fontSize: "16px", textAlign: "center", fontFamily: "Gotham-Black" }}>{title}</Typography>
                    </Box>
                </Box>
                <Box sx={{ pt: "42px", height: "calc(100vh - 42px)", width: { md: "400px", xs: "100%" }, marginX: "auto" }}>
                    {children}
                </Box>
            </Box>
        </>
    )
}
