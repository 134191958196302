import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button'
import { ThemeDarkButton, ThemeLightButton } from '../shared-layout/buttons/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AppleIcon from '@mui/icons-material/Apple';
import { useNavigate } from 'react-router-dom';


// const drawerBleeding = 0;

function BookNow(props) {
    const { open, waitingVal, userpackage, toggleDrawer, item, CheckoutFromPackage, Checkout, title, seatText } = props;
    const navigate = useNavigate()

    return (
        <>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        width: { md: "400px", xs: "100%" },
                    },
                }}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "30px 30px 0px 0px",
                        padding: 6
                    }
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>

                    <Typography sx={{ fontSize: "18px", pb: 1, fontFamily: "Gotham-Medium", fontWeight: 600, textTransform: "uppercase" }}>{title}</Typography>

                    {waitingVal === false &&
                        <Box sx={{ display: "flex", pb: 4, justifyContent: "space-between", alignItems: "center", paddingInline: "8px" }}>
                            <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>{item?.location?.spot_name}</Typography>
                            <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>{seatText}</Typography>
                        </Box>
                    }


                    {item?.priceType === 'Amount' && (
                        <Box sx={{ display: "flex", pb: 4, justifyContent: "space-between", alignItems: "center", paddingInline: "8px" }}>
                            <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>Amount</Typography>
                            <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>{item?.price} QR</Typography>
                        </Box>
                    )}

                    {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingInline: "8px" }}>
                        <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>YOUR WAITING</Typography>
                        <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>{item?.attributes?.booking_count_status?.waiting + 1}</Typography>
                    </Box> */}



                    <Box sx={{ overflow: 'auto' }}>

                        {item?.priceType === 'Amount' && (
                            <ThemeDarkButton style={{ borderRadius: "10px", mt: 1, display: "flex", justifyContent: "space-between", alignItems: "center", paddingInline: "20px" }} onClick={() => Checkout('Wallet')}>
                                <Box>
                                    <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>Pay from wallet</Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>
                                        {item?.price} QR
                                    </Typography>
                                </Box>
                            </ThemeDarkButton>
                        )}

                        {item?.priceType === 'Free' && (
                            <ThemeDarkButton style={{ borderRadius: "10px", mt: 1, display: "flex", justifyContent: "space-between", alignItems: "center", paddingInline: "20px" }} onClick={() => Checkout('Free')}>
                                <Box>
                                    <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>Free Checkout</Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>
                                        0 QR
                                    </Typography>
                                </Box>
                            </ThemeDarkButton>
                        )}

                        {item?.priceType === 'Credit' && (
                            <>
                                {userpackage ?
                                    <>
                                        {userpackage.map((item1, index) => (
                                            <ThemeDarkButton onClick={() => CheckoutFromPackage(item1)} style={{ mt: 1, borderRadius: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingInline: "20px" }}>
                                                <Box>
                                                    <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>{item1.attributes.name}</Typography>
                                                </Box>
                                                <Box>
                                                    {item.attributes.remaining_rides === 'unlimited' ? (
                                                        <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>
                                                            {item1.attributes.remaining_rides} UNLIMITED
                                                        </Typography>
                                                    ) : (
                                                        <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>
                                                            {item1.attributes.remaining_rides} CLASS
                                                        </Typography>
                                                    )}
                                                </Box>

                                            </ThemeDarkButton>
                                        ))}
                                    </>
                                    :
                                    <ThemeDarkButton onClick={() => navigate('/buy-package')}>
                                        <Box>
                                            <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium", my: 1 }}>Prchase Package</Typography>
                                        </Box>
                                    </ThemeDarkButton>
                                }
                            </>
                        )}
                    </Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
                        <Box>
                            <Typography sx={{ fontSize: "14px", fontFamily: "Gotham-Medium", fontWeight: 600 }}>45 QR</Typography>
                            <Typography sx={{ fontSize: "10px", fontFamily: "Gotham-Light" }}>SUB TOTAL</Typography>
                        </Box>
                        <Box>
                            <ThemeDarkButton style={{ paddingInline: "40px" }}>
                                <Typography sx={{ display: "flex", alignItems: "center", fontFamily: "Gotham-Medium", fontSize: "22px", textTransform: "capitalize", justifyContent: "center" }}>
                                    <AppleIcon sx={{ fontSize: "20px" }} />
                                    Pay
                                </Typography>
                            </ThemeDarkButton>
                        </Box>
                    </Box> */}
                </Box>
            </SwipeableDrawer>
        </>
    );
}

BookNow.propTypes = {
    window: PropTypes.func,
};

export default BookNow;
