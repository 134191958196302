import React from 'react'
import { Box } from '@mui/material'
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ThemeDarkButton, ThemeLightButton } from './buttons/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { destroyCookie } from 'nookies';
import { authState } from '../../recoil/atoms/authState';
import { useSetRecoilState } from 'recoil';

const nevItem = [
    { title: "profile", path: "/profile" },
    { title: "FAQ", path: "/faq" },
    { title: "TERMS & CONDITIONS", path: "/terms-condition" },
]

export default function NavItem({ toggleDrawer }) {
    const setUserRecoilState = useSetRecoilState(authState)

    const navigate = useNavigate()
    const location = useLocation()

    const handleLogout = () => {
        setUserRecoilState(null)
        destroyCookie(null, "access_token")
        localStorage.clear()
        navigate("/welcome-page")
    }

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", justifyContent: "space-between" }}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                    <IconButton onClick={toggleDrawer}>
                        <Close />
                    </IconButton>
                </Box>
                <Box sx={{ width: "80%", mt: 5, mx: "auto", display: "flex", flexDirection: "column", rowGap: 2 }}>
                    {nevItem.map((nav, index) =>
                        <Box key={index}>
                            {location.pathname === nav.path ?
                                <ThemeDarkButton
                                    onClick={() => navigate(nav.path)}
                                    style={{
                                        justifyContent: "flex-start",
                                        padding: "12px 25px",
                                        fontSize: "15px"
                                    }}
                                >
                                    {nav.title}
                                </ThemeDarkButton> :
                                <ThemeLightButton
                                    onClick={() => navigate(nav.path)}
                                    style={{
                                        justifyContent: "flex-start",
                                        padding: "12px 25px",
                                        fontFamily: "Gotham-Book",
                                        fontSize: "15px",
                                        boxShadow: "none"
                                    }}
                                >
                                    {nav.title}
                                </ThemeLightButton>
                            }

                        </Box>
                    )}

                    <ThemeLightButton
                        onClick={handleLogout}
                        style={{
                            justifyContent: "flex-start",
                            padding: "12px 25px",
                            fontFamily: "Gotham-Book",
                            fontSize: "15px",
                            boxShadow: "none"
                        }}
                    >
                        LOGOUT
                    </ThemeLightButton>


                </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Box mb={12}>
                    <img src="/images/logos/veloBlack.svg" alt='' height={"65px"} />
                </Box>
            </Box>
        </Box>
    )
}
