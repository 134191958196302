import { useEffect, useState } from "react"
import { Box } from "@mui/material";
import { phoneBookings } from "../../services/bookings";
import { ClassController } from "../../controllers/ClassController";
import { useSearchParams } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import OutdoorClass from "../../components/appComponent/outdoorClass";
import PageLoader from "../../components/pageLoader";
import moment from "moment";


const Seat = ({ xPos, yPos, SeatNumber, status, type, stage, itemIndex, seatText, setSelectedIndex, selectedIndex, setSeatObject, item }) => {



    let fillColor;
    if (status === 'yourSpot' || selectedIndex === itemIndex) {
        fillColor = 'black';
    } else if (status === 'Booked') {
        if (item.mine_booked === true) {
            fillColor = 'black';
        } else { fillColor = 'gray'; }
    } else if (status === 'Available') {
        fillColor = '#EDEDED';
    }


    let fillTextColor;
    if (fillTextColor === "yourSpot" || selectedIndex === itemIndex) {
        fillTextColor = 'white';
    } else if (status === 'Booked' || selectedIndex === itemIndex) {
        fillTextColor = 'white';
    } else if (status === "Available") {
        fillTextColor = 'black';
    }

    let selectedWidth;
    if (stage === "bottom") {
        selectedWidth = "100";
    } else if (stage === 'center') {
        selectedWidth = "100";
    } else if (stage === 'right') {
        selectedWidth = "80";
    }

    const handleClick = () => {
        if (status === 'Available') {
            setSelectedIndex(itemIndex)
            setSeatObject({ seat_text: seatText, seat: item.seat })
            // console.log("data.seats[itemIndex]: ", data.seats[itemIndex]);
            window.ReactNativeWebView.postMessage(JSON.stringify({ seat_text: seatText, seat: item.seat }))
        }
    }

    return (
        <foreignObject width={selectedWidth} height={selectedWidth} className={selectedIndex === itemIndex ? "selectedSeat" : ""} onClick={() => handleClick(seatText)} x={xPos} y={yPos} style={{ cursor: "pointer" }}>
            <div>
                <svg width={selectedWidth} height={selectedWidth}>
                    {(type === "floor") &&
                        <circle cx="50%" cy="50%" r="50%" fill={fillColor} />
                    }
                    {(type === "rack" || !type) &&
                        <rect x="0" y="0" width={selectedWidth} height={selectedWidth} rx="16" fill={fillColor} />
                    }
                    <text x="50%" y="50%" fill={fillTextColor} dominantBaseline="middle" textAnchor="middle" style={{ fontSize: "35px" }}>{seatText}</text>
                </svg>
            </div>
        </foreignObject>
    )
}


const ClassLayout = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    // const [data, setData] = useState()
    const [seats, setSeats] = useState()
    // const [status, setStatus] = useState("available")
    const [selectedIndex, setSelectedIndex] = useState()
    const [seatObject, setSeatObject] = useState({})
    // console.log("seatObject: ", seatObject);
    const [details, setDetails] = useState({})
    // console.log("details: ", details);
    const [stage, setStage] = useState()
    const [seatType, setSeatType] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const height = searchParams.get("height");






    useEffect(() => {
        getClassDetail();
    }, []);

    const getClassDetail = async () => {
        const id = searchParams.get("id");
        const token = searchParams.get("token");
        const instance = new ClassController();
        const result = await instance.getClassDetails(id, token);
        if (result?.class?.plan) {
            const plan = JSON.parse(result?.class?.plan?.description);
            const seats = result.class.seat_booking_status;
            const AllSeats = await mergeSeatPlans(plan.seat, seats, plan.stage);
            setSeats(AllSeats);
            setSeatObject(result)
            setDetails(result.class)
            const uniqueTypes = {};
            const filteredSeats = AllSeats.filter(seat => {
                if (!uniqueTypes[seat.type]) {
                    uniqueTypes[seat.type] = true;
                    return true;
                }
                return false;
            });
            setSeatType(filteredSeats);
        }
        else {
            setDetails(result.class)
        }
        // const plan = JSON.parse(result?.class?.plan?.description);
        // const seats = result.class.seat_booking_status;
        // const AllSeats = await mergeSeatPlans(plan, seats);
        // setSeatObject(result)
        // setDetails(result.class)
        // setSeats(AllSeats);
    };

    const mergeSeatPlans = async (plan, seats, stage) => {
        setStage(stage);
        let AllSeats = [];
        plan.forEach((item, index) => {
            const num = parseInt(item.num);
            const seatItem = seats.filter((seat) => seat.seat === num);
            let updatedSeat = { ...seatItem[0], ...item };
            delete updatedSeat.num;
            AllSeats.push(updatedSeat)
        })
        return AllSeats;
    }

    // useEffect(() => {
    //     (async () => {
    //         setLoading(true)
    //         const result = await phoneBookings(1)
    //         // console.log(result)
    //         if (result.success) {
    //             setData(result.mobileLayoutObject)
    //             setLoading(false)
    //         }
    //         else {
    //             setError(true)
    //             setLoading(false)
    //         }
    //     })()
    // }, [])

    if (loading) {
        return (
            <>
                {details.indoor === 1 ?
                    <Box sx={{ height: "calc(100vh - 60px)", overflowY: "auto", width: "100%", display: "flex", justifyContent: "center", backgroundColor: "white", fontFamily: "Gotham-Book", }}>
                        <Box sx={{ backgroundColor: "white", width: "100%", height: "680px ", paddingInline: 6, pt: 2, overflow: "hidden" }}>
                            <Stack spacing={1}>
                                <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} height={20} />
                                <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} height={20} />
                                <Box sx={{ display: "flex", justifyContent: "space-between" }} pt={4}>
                                    <Box height={600} width={"40%"}>
                                        <Skeleton variant="rounded" sx={{ borderRadius: "10px" }} width="100%" height="100%" />
                                    </Box>
                                    <Box height={600} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} width={"40%"}>
                                        <Skeleton variant="rounded" sx={{ borderRadius: "50% 0 0 50%" }} width="100%" height={350} />
                                    </Box>
                                </Box>
                            </Stack>
                        </Box >
                    </Box > :
                    <PageLoader />}
            </>
        )
    }
    else {
        return (
            <>{details.indoor === 1 ?
                <Box sx={{ height: "100vh", overflowY: "auto", width: "100%", display: "flex", justifyContent: "center", backgroundColor: "white", fontFamily: "Gotham-Book", }}>
                    <div className="scrollbarHide" style={{ backgroundColor: "white", width: "100%", height: "100%", paddingInline: 40, overflow: "auto" }}>
                        <svg
                            // height={"100%"}
                            height={height}
                            width="100%" fill="white" overflow="auto" style={{ display: "block" }} preserveAspectRatio="xMinYMin meet" viewBox="0 0 1000 800">
                            {details.trainer &&

                                <foreignObject x="0%" y="30" width="100%" height="300">
                                    <div xmlns="http://www.w3.org/1999/xhtml" style={{ display: "flex", justifyContent: "center" }}>
                                        <span xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: "40px", textAlign: "center", textTransform: "uppercase" }}>{details.trainer.first_name} I {details.theme_name} I {moment(details.start_date + ' ' + details.start_time).format('hh:mm A')} - {moment(details.start_date + ' ' + details.end_time).format('hh:mm A')}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", columnGap: "40px", marginTop: "20px", marginBottom: "40px" }} xmlns="http://www.w3.org/1999/xhtml">
                                        <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
                                            <span style={{ backgroundColor: "black", width: "50px", height: "50px", borderRadius: "16px" }}></span>
                                            <span style={{ fontSize: "35px", textTransform: "uppercase" }}>Your {details.location.spot_name}</span>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
                                            <span style={{ backgroundColor: "gray", width: "50px", height: "50px", borderRadius: "16px" }}></span>
                                            <span style={{ fontSize: "35px", textTransform: "uppercase" }}>Booked</span>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
                                            <span style={{ backgroundColor: "#EDEDED", width: "50px", height: "50px", borderRadius: "16px" }}></span>
                                            <span style={{ fontSize: "35px", textTransform: "uppercase" }}>Available</span>
                                        </div>
                                    </div>
                                    {/* for rack class */}
                                    {stage === "bottom"
                                        &&
                                        <div style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "center", columnGap: "80px", overflow: "hidden", }}>
                                            {seatType.map((item, index) =>
                                                <div key={index} style={{ display: "flex", alignItems: "center", columnGap: "20px", }}>
                                                    <span style={{ backgroundColor: "black", width: "70px", height: "70px", borderRadius: item.type === "floor" ? "50%" : "16px", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <p style={{ color: "white", fontSize: "45px", fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{item.type[0]}</p>
                                                    </span>
                                                    <span style={{ fontSize: "45px", fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{item.type}</span>
                                                </div>
                                            )}
                                        </div>
                                    }
                                </foreignObject>
                                // <text x="50%" y="80" dominantBaseline="middle" textAnchor="middle" fill="black" style={{ fontSize: "40px", textTransform: "uppercase" }}>{details.trainer.first_name} I {details.theme_name} I {details.start_time} - {details.end_time}</text>
                            }
                            {/* <svg x={650} y={650} width="275" height="900">
                                <rect x="2" width="600" height="900" rx="270" style={{ stroke: "black", strokeWidth: "2px" }} />
                            </svg> */}
                            {details.trainer &&
                                <>
                                    {stage === "right" &&
                                        <>
                                            <svg x={650} y={650} width="275" height="900">
                                                <rect x="2" width="600" height="900" rx="270" style={{ stroke: "black", strokeWidth: "2px" }} />
                                            </svg>
                                            <svg width="300" height="500" x={650} y={800}>
                                                <defs>
                                                    <pattern id="image" x="0%" y="0%" height="100%" width="100%"
                                                        viewBox="0 0 512 512">
                                                        <image x="0%" y="0%" width="512" height="512" preserveAspectRatio="xMidYMid slice" xlinkHref={details.trainer.image}></image>
                                                    </pattern>
                                                </defs>
                                                <circle id="sd" className="medium" cx="50%" cy="50%" r="25%" fill="url(#image)" stroke="gray" strokeWidth="0.5%" />
                                                <foreignObject x="0%" y="380" width="100%" height="300">
                                                    <p xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: "35px", textAlign: "center" }}>{details.trainer.first_name}</p>
                                                </foreignObject>
                                            </svg>
                                        </>
                                    }

                                    {stage === "center" &&
                                        <foreignObject x="0%" y="900" width="100%" height="500">
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                {/* <svg width="900" height="275">
                                                            <rect y="4" width="900" height="600" rx="270" style={{ stroke: "black", strokeWidth: "2px" }} />
                                                        </svg> */}
                                                <svg width="500" height="500" x={0} y={0}>
                                                    <defs>
                                                        <pattern id="image" x="0%" y="0%" height="100%" width="100%"
                                                            viewBox="0 0 512 512">
                                                            <image x="0%" y="0%" width="512" height="512" preserveAspectRatio="xMidYMid slice" xlinkHref={details.trainer.image}></image>
                                                        </pattern>
                                                    </defs>
                                                    <circle id="sd" className="medium" cx="50%" cy="25%" r="25%" fill="url(#image)" stroke="gray" strokeWidth="0.5%" />
                                                    <foreignObject x="0%" y="60%" width="100%" height="300">
                                                        <p xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: "35px", textAlign: "center" }}>{details.trainer.first_name}</p>
                                                    </foreignObject>
                                                </svg>
                                            </div>
                                        </foreignObject>
                                    }

                                    {stage === "bottom" &&
                                        <foreignObject x="0%" y="1620" width="100%" height="500">
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                {/* <svg width="900" height="275">
                                                            <rect y="4" width="900" height="600" rx="270" style={{ stroke: "black", strokeWidth: "2px" }} />
                                                        </svg> */}
                                                <svg width="500" height="500" x={0} y={0}>
                                                    <defs>
                                                        <pattern id="image" x="0%" y="0%" height="100%" width="100%"
                                                            viewBox="0 0 512 512">
                                                            <image x="0%" y="0%" width="512" height="512" preserveAspectRatio="xMidYMid slice" xlinkHref={details.trainer.image}></image>
                                                        </pattern>
                                                    </defs>
                                                    <circle id="sd" className="medium" cx="50%" cy="25%" r="25%" fill="url(#image)" stroke="gray" strokeWidth="0.5%" />
                                                    <foreignObject x="0%" y="60%" width="100%" height="300">
                                                        <p xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: "35px", textAlign: "center" }}>{details.trainer.first_name}</p>
                                                    </foreignObject>
                                                </svg>
                                            </div>
                                        </foreignObject>
                                    }
                                </>
                            }

                            <g>
                                {
                                    seats &&
                                    <g>
                                        {seats.map((item, index) =>
                                            <Seat
                                                key={index}
                                                type={item.type}
                                                xPos={item.x}
                                                seatText={item.seatText ? item.seatText : item.seat}
                                                item={item}
                                                yPos={item.y}
                                                SeatNumber={item.seat}
                                                Cname={item.name}
                                                stage={stage}
                                                status={item.status}
                                                itemIndex={index}
                                                setSelectedIndex={setSelectedIndex}
                                                selectedIndex={selectedIndex}
                                                setSeatObject={setSeatObject}
                                            />)}
                                    </g>
                                }
                            </g>
                        </svg>
                    </div >
                </Box>
                :
                <OutdoorClass item={details} />
            }
            </>
        )
    }
}


export default ClassLayout