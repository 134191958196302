import React from 'react'
import { useState, useRef, useEffect, useContext } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Box, Grid, Typography } from '@mui/material'
import { RoundedDarkButton, RoundedLightButton } from '../../components/shared-layout/buttons/Button'
import { CustomCard } from '../../components/shared-layout/card'
import MyPackages from './MyPackages'
import { useNavigate, useLocation } from 'react-router-dom'
import { BuyController } from '../../controllers/BuyController'
import { UserContext } from '../../context/UserContext'
import PageLoader from '../../components/pageLoader'
import BookingSummary from '../Booking/BookingSummary'


const PackageHome = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [summeryShow, setSummeryShow] = useState(false)
    console.log("summeryShow: ", summeryShow);
    const [item, setItem] = useState([])
    console.log("item: ", item);
    const [loading, setLoading] = useState(false)
    const [myPackage, setMyPackage] = useState(false)
    const [allPackage, setAllPackage] = useState(true)
    const [packageData, setPackageData] = useState({})
    const [height, setHeight] = useState(null)
    const { getToken } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            setLoading(true)
            const token = getToken();
            console.log("token: ", token);
            const instance = new BuyController();
            const result = await instance.getAllPackages(token);
            setPackageData(result)
            setLoading(false)
        })();
    }, []);

    const navigate = useNavigate();
    const ref = useRef();
    const location = useLocation();

    useEffect(() => {
        setHeight(ref.current.offsetHeight);
    }, [])


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const packageType = searchParams.get('package');

        if (packageType === 'mypackage') {
            handleMyPackages(1);
        } else if (packageType === 'allpackages') {
            handleAllPackages(0);
        }
    }, [location.search]);



    const handleAllPackages = (index) => {
        setSelectedIndex(index);
        setAllPackage(true);
        setMyPackage(false);
    };

    const handleMyPackages = (index) => {
        setSelectedIndex(index);
        setMyPackage(true);
        setAllPackage(false);
    }

    // console.log(packageData)

    const handleBuyNow = (item) => {
        setSummeryShow(true)
        setItem(item)
    }

    return (
        <MainLayout>
            {!summeryShow ?
                <Box sx={{ px: 1.5, pt: 1.5, pb: 3, overflowY: "hidden" }}>
                    <Box ref={ref} mb={1} mt={2.5} sx={{ display: "flex", justifyContent: "space-around", alignItems: "center",columnGap:1 }}>
                        {selectedIndex === 0 ?
                            <RoundedDarkButton
                                //  onClick={() => handleAllPackages(0)}
                                style={{
                                    // backgroundColor: selectedIndex === 0 ? 'black' : '#f2f2f2',
                                    // color: selectedIndex === 0 ? 'white' : 'black',
                                    width: '165px',
                                    height: '35px',
                                    fontSize: '12px',
                                    fontFamily: 'Gotham-Book',
                                    // ":hover": { backgroundColor: selectedIndex === 1 ? 'white' : 'black', }
                                }} >
                                ALL PACKAGES
                            </RoundedDarkButton>
                            :
                            <RoundedLightButton onClick={() => handleAllPackages(0)}
                                style={{
                                    // backgroundColor: selectedIndex === 0 ? 'black' : '#f2f2f2',
                                    // color: selectedIndex === 0 ? 'white' : 'black',
                                    width: '165px',
                                    height: '35px',
                                    fontSize: '12px',
                                    fontFamily: 'Gotham-Book',
                                    // ":hover": { backgroundColor: selectedIndex === 1 ? 'white' : 'black', }
                                }} >
                                ALL PACKAGES
                            </RoundedLightButton>
                        }

                        {selectedIndex === 1 ?
                            <RoundedDarkButton
                                // onClick={() => handleMyPackages(1)}
                                style={{
                                    width: '165px',
                                    height: '35px',
                                    fontSize: '12px',
                                    fontFamily: 'Gotham-Book',
                                }}>MY PACKAGES</RoundedDarkButton>
                            :
                            <RoundedLightButton onClick={() => handleMyPackages(1)}
                                style={{
                                    width: '165px',
                                    height: '35px',
                                    fontSize: '12px',
                                    fontFamily: 'Gotham-Book',
                                }}>MY PACKAGES</RoundedLightButton>
                        }
                    </Box>


                    {allPackage ?
                        <Box sx={{
                            height: `calc(100vh - (132px + ${height}px))`,
                            maxHeight: "100%",
                            overflowY: "auto",
                            '&::-webkit-scrollbar': { width: 0 }
                        }}>

                            {loading ? <PageLoader />
                                :
                                (
                                    packageData.data?.length &&
                                    <Grid container mt={2} sx={{ justifyContent: "space-between", rowGap: 2, pb: 2 }}>
                                        {packageData.data.map((item, index) =>
                                            <Grid key={index} item xs={6}>
                                                <CustomCard key={index} sx={{ py: 3, mx: 1, height: "120px", textAlign: 'center' }}>
                                                    <Typography sx={{ fontSize: "14px", fontWeight: 1000, mb: 0.4, fontFamily: "Gotham-Medium", textTransform: "uppercase" }}>{item.attributes.name}</Typography>
                                                    <Typography sx={{ fontWeight: 500, fontSize: "18px", mb: 0.4, fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{item.attributes.amount} QR</Typography>
                                                    <Typography sx={{ fontSize: "10px", fontWeight: 1000, fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{item?.attributes?.rides} {item?.attributes?.type !== 'unlimited' ? "class" : "unlimited"}</Typography>
                                                    <Typography sx={{ fontSize: "8px", fontWeight: 1000, fontFamily: "Gotham-Light", textTransform: "uppercase" }}>{item.attributes.validity} DAYS VALIDITY</Typography>
                                                    {index === packageData.length - 1 ? "" : <RoundedDarkButton style={{ width: "80px", height: "25px", mt: "8px", fontSize: "10px", fontFamily: "Gotham-Medium" }} onClick={() => handleBuyNow(item)} >BUY NOW</RoundedDarkButton>}
                                                </CustomCard>
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            }
                        </Box>
                        : <MyPackages />
                    }

                </Box>

                :
                <BookingSummary item={item} setSummeryShow={setSummeryShow} />
            }

        </MainLayout >
    )
}

export default PackageHome