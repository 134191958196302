import { API_BASE } from '../config/ApiConfig';

export class NotificationController {
    async getAllNotification(token) {
        return fetch(API_BASE + '/notification/get', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                Accept: 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return responseJson;
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }

    async readNotification(token) {
        return fetch(API_BASE + '/notification/read', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                Accept: 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return responseJson;
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }

    async deleteNotification(token) {
        return fetch(API_BASE + '/notification/clear', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                Accept: 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return responseJson;
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }
}