import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import React from 'react'
import CircleIcon from '@mui/icons-material/Circle';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

const menuItems = [
  { title: "HOME", path: "/" },
  { title: "CLASSES", path: "/classes" },
  { title: "PLANNER", path: "/upcoming-classes" },
  { title: "BUY", path: "/buy-package" },
  { title: "JOURNEY", path: "/journey" }
]

export default function Footer() {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Box sx={{
      backgroundColor: "white",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      // boxShadow: "0px 12px 20px 0px black"
    }}>
      <Grid container >
        {menuItems.map((menu, index) =>
          <Grid key={index} item xs={2.4}>
            <Button
              fullWidth
              onClick={() => navigate(menu.path)}
            >
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: 0.5
              }}>
                <CircleIcon fontSize='14px' sx={{ color: menu.path === location.pathname ? "black" : "#a7a9ac" }} />
                <Typography sx={{ fontSize: "10px",fontFamily:"Gotham-Light" }}>{menu.title}</Typography>
              </Box>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
