import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import Login from '../pages/login';
import SignUp from '../pages/sign-up';
import WelcomePage from '../pages/welcome';
import HomePage from '../pages/home';
import EditProfile from '../pages/profile/EditProfile';
import Profile from '../pages/profile/Profile';
import AddCard from '../pages/wallet/AddCard';
import ManageCard from '../pages/wallet/ManageCard';
import AddAmount from '../pages/wallet/AddAmount';
import PackageHome from '../pages/packages/PackageHome';
import BookingSummary from '../pages/Booking/BookingSummary';
import AllAchievements from '../pages/Achievements/AllAchievements';
import HomeAchievements from '../pages/Achievements/HomeAchievements';
import MyPackages from '../pages/packages/MyPackages';
import Classes from '../pages/classes';
import OutdoorClassDetail from '../pages/classes/outdoor';
import DoubleJoy from '../pages/doublejoy';
import Store from '../pages/store';
import UpcomingClasses from '../pages/classes/UpcomingClasses';
import FaqPage from '../pages/Faq';
import TermsCondition from '../pages/TermsCondition';
import ClassLayout from '../pages/ClassLayout';
import Items from '../pages/items';
import Cart from '../pages/cart';
import PaymentMethod from '../pages/payment';
import ForgotPassword from '../pages/forgotPassword';
import AppClassLayout from '../pages/appClassLayout';
import ChangePassword from '../pages/changePassword';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/atoms/userState';
import { authState } from '../recoil/atoms/authState';
import HappeningDetail from '../pages/happening-detail';
import HappeningReport from '../pages/happening-report';
import Verify from '../pages/Verify';
import PaymentStatus from '../pages/paymentStatus';
import DoubleJoyOrder from '../pages/doublejoy/order';
import TermsConditions from '../pages/TermsConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import DeleteMyAccount from '../pages/profile/DeleteMyAccount';

const AppRoutes = () => {
    const userToken = useRecoilValue(userState)
    // const authToken = useRecoilValue(authState)

    // console.log("RecoilUserToken:=>", userToken)
    // console.log("RecoilAuthToken:=>", authToken)


    const LoginRoutes = () => {
        if (!userToken) {
            return (
                <Outlet />
            )
        }
        else {
            return (
                <Navigate to="/" replace />
            )
        }
    }

    const PrivateRoutes = () => {
        if (userToken) {
            return (
                <Outlet />
            )
        }
        else {
            return (
                <Navigate to="/welcome-page" replace />
            )
        }

    }


    return (
        <BrowserRouter>
            <Routes>

                <Route element={<LoginRoutes />}>
                    <Route exact path="/welcome-page" element={<WelcomePage />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/sign-up" element={<SignUp />} />
                    <Route exact path="/Verify" element={<Verify />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword />} />
                </Route>
                <Route exact path="/app/class-layout" element={<AppClassLayout />} />
                <Route exact path="/payment-status" element={<PaymentStatus />} />
                <Route exact path="/terms-condition" element={<TermsCondition />} />
                <Route exact path="/terms-conditions" element={<TermsConditions />} />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/delete-my-account" element={<DeleteMyAccount />} />
                <Route element={<PrivateRoutes />}>
                    <Route exact path="/class-layout" element={<ClassLayout />} />
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/payment" element={<PaymentMethod />} />
                    <Route exact path="/items" element={<Items />} />
                    <Route exact path="/cart" element={<Cart />} />
                    <Route exact path="/double-joy" element={<DoubleJoy />} />
                    <Route exact path="/doublejoy-order" element={<DoubleJoyOrder />} />
                    <Route exact path="/store" element={<Store />} />
                    <Route exact path="/classes" element={<Classes />} />
                    <Route exact path="/details" element={<OutdoorClassDetail />} />
                    <Route exact path="/profile-edit" element={<EditProfile />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/add-card" element={<AddCard />} />
                    <Route exact path="/manage-card" element={<ManageCard />} />
                    <Route exact path="/add-amount" element={<AddAmount />} />
                    <Route exact path="/buy-package" element={<PackageHome />} />
                    <Route exact path="/booking-summary" element={<BookingSummary />} />
                    <Route exact path="/journey" element={<HomeAchievements />} />
                    <Route exact path="/all-achievements" element={<AllAchievements />} />
                    <Route exact path="/my-packages" element={<MyPackages />} />
                    <Route exact path="/upcoming-classes" element={<UpcomingClasses />} />
                    <Route exact path="/faq" element={<FaqPage />} />
                    <Route exact path="/change-password" element={<ChangePassword />} />
                    <Route exact path="/happening-detail" element={<HappeningDetail />} />
                    <Route exact path="/happening-report" element={<HappeningReport />} />
                </Route>
            </Routes>
        </BrowserRouter >
    );
};

export default AppRoutes;

{/* <Route exact path="/upload-data" element={<UploadData />} /> */ }