import React from 'react'
import MainLayout from '../../layout/mainLayout'
import { Avatar, Box, Button, Divider, Grid, Typography } from '@mui/material'
import { RoundedDarkButton, RoundedLightButton } from '../../components/shared-layout/buttons/Button';
import { CustomCard } from '../../components/shared-layout/card';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import Calendar from '../../components/calendar/Calendar';
import { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClassController } from '../../controllers/ClassController';
// import PageLoader from '../../components/pageLoader';
import Skeleton from '@mui/material/Skeleton';
import { ProfileController } from '../../controllers/ProfileController';
import { SkeltonCard } from '../../components/skeleton/skeleton';

export default function Classes() {
    const [globalIndex, setGlobalIndex] = useState('');
    const [height, setHeight] = useState(null)
    console.log("height: ", height);
    const [loading, setLoading] = useState(true);
    const [allData, setAllData] = useState([]);
    console.log("allData: ", allData);
    const [classes, setClasses] = useState();
    console.log("classes: ", classes);
    const [active, setActive] = useState();
    const [selectedDate, setSelectedDate] = useState('');
    const { getToken } = useContext(UserContext);
    const [activeStudios, setActiveStudios] = useState([]);
    const [catIndex, setCatIndex] = useState([]);
    const [forceReload, setForseReload] = useState(false);
    // const [refresh, setRefresh] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [uid, setUid] = useState();

    const navigate = useNavigate()

    useEffect(() => {
        console.log('Refreshed');
        setForseReload(!forceReload);
        setAllData([]);
        setClasses();
        getData();
        const result = localStorage.getItem('di');
        if (result) {
            setGlobalIndex(JSON.parse(result));
            var date = moment(new Date(), 'YYYY-MM-DD')
                .add(result, 'days')
                .format('YYYY-MM-DD');
            setSelectedDate(date);
        } else {
            setGlobalIndex(0);
        }
    }, []);

    const renderWaiting = (data) => {

        if (data.attributes?.user_waiting === true) {
            let users = data?.attributes?.waitingUsers.filter(
                user => user.user_id === uid,
            );
            return users[0].waiting_no
        }

    }

    const getData = async () => {
        setLoading(true);
        const token = await getToken();
        const instance = new ClassController();
        const result = await instance.getAllLocation(token);
        setAllData(result.locations);

        const instance1 = new ProfileController();
        const userDetail = await instance1.getUserDetail(token);
        setUid(userDetail.user.id)

        console.log('reload');
        const aStudio = await localStorage.getItem('activeStudio');

        const id = searchParams.get("id");

        if (id) {
            const activeLocation = result.locations.filter(
                item => item.id === id,
            );
            setActiveStudios([activeLocation[0]]);
            setActive(activeLocation[0]?.studio_classes);
            setFilteredClass(activeLocation[0].studio_classes);
        } else {

            if (aStudio?.length) {

                const allCatIndex = JSON.parse(aStudio);
                setCatIndex(allCatIndex);

                let studios = [];
                result.locations.forEach((item, index) => {
                    const exist = allCatIndex.filter(item => item === index);
                    if (exist.length) {
                        studios.push(item)
                    }
                })
                setActiveStudios(studios);
                const allClasses = getAllFilteredClassess(studios);
                setActive(allClasses);
                setFilteredClass(allClasses);
            } else {

                selectCategory(result.locations[0], 0);
            }


        }
    };

    const setFilteredClass = async activeData => {
        setLoading(true);
        const newData = activeData;
        const date = new Date();
        let activeDate = '';
        const result = await localStorage.getItem('di');
        if (result) {
            activeDate = moment(date, 'YYYY-MM-DD')
                .add(result, 'days')
                .format('YYYY-MM-DD');
            setSelectedDate(activeDate);
        } else {
            activeDate = moment(date).format('YYYY-MM-DD');
            setSelectedDate(activeDate);
        }

        const filterData = newData.filter(item =>
            moment(item.start_date).isSame(activeDate),
        );
        const sortedData = filterData.slice().sort((a, b) => {
            const timeA = new Date(`1970-01-01T${a.start_time}`);
            const timeB = new Date(`1970-01-01T${b.start_time}`);
            return timeA - timeB;
        });
        setClasses(sortedData);
        setLoading(false);
    };

    const selectCategory = async (item, index) => {
        const filterData = activeStudios.filter(item1 => item1.id === item.id);


        if (filterData.length) {
            if (activeStudios.length > 1) {

                const filterData1 = activeStudios.filter(item1 => item1.id !== item.id);
                const filterData2 = catIndex.filter(item1 => item1 !== index);

                setActiveStudios(filterData1);

                setCatIndex(filterData2)
                localStorage.setItem('activeStudio', JSON.stringify(filterData2));
                setCatIndex(filterData2)
                let allClasses = getAllFilteredClassess(filterData1);
                setActive(allClasses);
                setFilteredClass(allClasses);
            }

        } else {
            const allData = [...activeStudios, item];
            setActiveStudios(allData);

            const catIndexData = [...catIndex, index];
            console.log(catIndexData, 'ttt');
            setCatIndex(catIndexData)
            localStorage.setItem('activeStudio', JSON.stringify(catIndexData));

            let allClasses = getAllFilteredClassess(allData);

            setActive(allClasses);
            setFilteredClass(allClasses);
        }
    };

    const getAllFilteredClassess = allStudioData => {
        let allClasses = [];
        allStudioData.forEach((val, index) => {
            val.studio_classes.forEach((classVal, index2) => {
                allClasses.push(classVal);
            });
        });
        return allClasses;
    };

    const GotoLayout = (index) => {
        const selectedSeat = classes[index].id
        console.log("selectedSeat: ", selectedSeat);
        navigate('/class-layout?id=' + selectedSeat)
    }


    const onSelectDate = (date, i) => {
        setLoading(true);
        var dt = moment(date).format('YYYY-MM-DD');
        localStorage.setItem('date', dt);
        localStorage.setItem('di', JSON.stringify(i));
        if (active) {
            const filterData = active.filter(item =>
                moment(item.start_date).isSame(dt),
            );
            const sortedData = filterData.slice().sort((a, b) => {
                const timeA = new Date(`1970-01-01T${a.start_time}`);
                const timeB = new Date(`1970-01-01T${b.start_time}`);
                return timeA - timeB;
            });
            setClasses(sortedData);
        }
        setLoading(false);
    };

    const ref = useRef()

    useEffect(() => {
        setHeight(ref.current.offsetHeight);
    }, [classes])


    const isInclude = id => {
        const filterData = activeStudios.filter(item => item.id === id);
        if (filterData.length) {
            return true;
        }
    };

    return (
        <>
            <MainLayout>
                <Box sx={{ height: "calc(100vh - 130px)", position: "relative" }} mx={2} my={2}>
                    <Box ref={ref}>
                        {allData.length ?
                            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2, overflowX: "auto", width: "100%" }} mt={2}>
                                {allData.map((data, index) =>
                                    <>
                                        {isInclude(data.id) ?
                                            <RoundedDarkButton
                                                key={index}
                                                style={{ minWidth: "30%", maxWidth: "30%", px: 2, mb: 1, }}
                                                onClick={() => selectCategory(data, index)}
                                            >
                                                <Box component={"span"} sx={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    fontSize: "12px",
                                                    fontFamily: "Gotham-Book"
                                                }}>
                                                    {data.name}
                                                </Box>
                                            </RoundedDarkButton>
                                            :
                                            <RoundedLightButton
                                                key={index}
                                                style={{ minWidth: "30%", maxWidth: "30%", px: 2, mb: 1 }}
                                                onClick={() => selectCategory(data, index)}
                                            >
                                                <Box component={"span"} sx={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    fontSize: "12px",
                                                    fontFamily: "Gotham-Book"
                                                }}>
                                                    {data.name}
                                                </Box>
                                            </RoundedLightButton>
                                        }
                                    </>
                                )}
                            </Box>
                            :
                            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2, overflowX: "auto", width: "100%" }} mt={2}>
                                <Skeleton variant="rounded" sx={{ borderRadius: "20px" }} width={"100%"} height={38} />
                                <Skeleton variant="rounded" sx={{ borderRadius: "20px" }} width={"100%"} height={38} />
                                <Skeleton variant="rounded" sx={{ borderRadius: "20px" }} width={"100%"} height={38} />
                            </Box>
                        }
                        {(globalIndex !== '') ?
                            <Calendar
                                onSelectDate={onSelectDate}
                                globalIndex={globalIndex}
                            />
                            :
                            <></>
                        }
                    </Box>

                    {loading ?
                        // <PageLoader />
                        <>
                            <SkeltonCard />
                            <SkeltonCard />
                            <SkeltonCard />
                        </>
                        :
                        <Box sx={{
                            height: `calc(100vh - (110px + ${height}px))`,
                            maxHeight: "100%",
                            overflowY: "auto",
                            position: "relative",
                            '&::-webkit-scrollbar': { width: 0 }
                        }}>
                            {classes?.length ? (
                                <Box minHeight={"100%"}>
                                    {classes.map((data, index) =>
                                        <Box key={index} mt={1} >
                                            <CustomCard>
                                                <Box component={Button}
                                                    disabled={moment(data.start_date).format("YYYY-DD-MM") <= moment().format("YYYY-DD-MM") && moment(data.start_time, "HH:mm").format("HH:mm") <= moment().format("HH:mm")}
                                                    onClick={() => GotoLayout(index)} sx={{ width: "100%", cursor: "pointer", p: 0 }}>
                                                    <Grid sx={{ alignItems: "center" }} container spacing={1}>
                                                        <Grid item xs={2.5}>
                                                            <Box sx={{ width: "100%", height: '70px', position: 'relative' }}>
                                                                <Avatar style={{ borderRadius: "10px", height: "100%", width: "100%" }} src={data.trainer.image} alt={data.trainer.first_name} />
                                                                {data.indoor === 0 &&
                                                                    <Typography sx={{
                                                                        position: 'absolute',
                                                                        fontFamily: "Gotham-Book",
                                                                        width: '100%',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#000',
                                                                        alignItems: 'center',
                                                                        top: 0,
                                                                        zIndex: 999,
                                                                        left: 0,
                                                                        fontSize: '12px',
                                                                        lineHeight: '16px',
                                                                        color: '#fff',
                                                                        borderRadius: '12px',
                                                                    }}>Outdoor</Typography>
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={6.3}>
                                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", rowGap: 0.4, alignItems: "flex-start" }}>
                                                                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                                                                    <Typography sx={{ fontFamily: "Gotham-Medium", lineHeight: 1, display: "flex", textTransform: "uppercase", fontSize: "14px", fontWeight: 700 }} variant='button'>{data.title}
                                                                        {data.theme_name && <Divider orientation="vertical" sx={{ mx: 0.5, borderColor: "#00000096" }} />}
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: "9px", textTransform: "uppercase", fontFamily: "Gotham-Light", textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} variant='button'>{data.theme_name}</Typography>
                                                                </Box>
                                                                <Box sx={{ display: "flex", alignItems: "center", mt: 0.4 }}>
                                                                    <WatchLaterOutlinedIcon sx={{ fontSize: '12px', pr: 0.5, fontFamily: "Gotham-Light" }} />
                                                                    <Typography sx={{ fontSize: '12px', lineHeight: 1, fontFamily: "Gotham-Book" }} variant='button'>{moment(data.start_date + ' ' + data.start_time).format(
                                                                        'hh:mm A',
                                                                    )}</Typography>
                                                                </Box>
                                                                <Typography sx={{ fontSize: "10px", textTransform: "uppercase", fontFamily: "Gotham-Light", fontWeight: 400 }} variant='button'>
                                                                    {data.trainer.first_name}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3.2} sx={{ display: "flex", alignItems: "center", flexDirection: "column", rowGap: 0.2 }}>
                                                            {data.gender === 'Female' && (
                                                                <Typography sx={{ fontSize: "8px", color: "black", fontFamily: "Gotham-Medium" }} variant='button'>LADIES ONLY</Typography>
                                                            )}
                                                            {data.attributes.booking_count_status.waiting_available === 0 &&
                                                                data.attributes.booking_count_status.available === 0 &&
                                                                data.attributes.user_waiting === false &&
                                                                data.attributes.mine_booking === false && (
                                                                    <RoundedDarkButton onClick={(event) => console.log(event)} style={{ fontSize: "8px", width: "auto", py: "3px", fontFamily: "Gotham-Medium", backgroundColor: '#161415' }}>
                                                                        Fully Booked
                                                                    </RoundedDarkButton>
                                                                )}

                                                            {data.attributes.mine_booking === true &&
                                                                data.attributes.user_waiting === false && (
                                                                    <RoundedDarkButton onClick={(event) => console.log(event)} style={{ fontSize: "8px", width: "20px", py: "5px", fontFamily: "Gotham-Medium", backgroundColor: '#161415' }}>
                                                                        Booked
                                                                    </RoundedDarkButton>
                                                                )}

                                                            {data.attributes.booking_count_status.waiting_available !== 0 &&
                                                                data.attributes.booking_count_status.available === 0 &&
                                                                data.attributes.user_waiting === false &&
                                                                data.attributes.mine_booking === false && (
                                                                    <RoundedDarkButton style={{ fontSize: "8px", width: "auto", py: "3px", fontFamily: "Gotham-Medium", backgroundColor: '#161415' }}>
                                                                        Join Waitlist
                                                                    </RoundedDarkButton>
                                                                )}

                                                            {data.attributes.user_waiting === true && (
                                                                <RoundedDarkButton style={{ fontSize: "8px", width: "auto", py: "3px", fontFamily: "Gotham-Medium", backgroundColor: '#161415' }}>
                                                                    Waiting: {renderWaiting(data)}
                                                                </RoundedDarkButton>
                                                            )}

                                                            {data.attributes.available_seat_text !== '' &&
                                                                data.attributes.mine_booking === false && (
                                                                    <RoundedDarkButton style={{ fontSize: "8px", width: "auto", py: "3px", fontFamily: "Gotham-Medium", backgroundColor: 'red' }}>
                                                                        {data.attributes.available_seat_text}
                                                                    </RoundedDarkButton>
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </CustomCard>
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <Typography
                                    sx={{ mt: "50%", fontFamily: 'Gotham-Book', textAlign: 'center' }}
                                >
                                    No data found
                                </Typography>
                            )}
                        </Box>
                    }
                </Box>
            </MainLayout >
        </>
    )
}
