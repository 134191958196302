import React, { useEffect, useContext, useRef } from 'react'
import ViewLayout from '../../layout/viewLayout'
import { Box, Grid, Typography } from '@mui/material'
import { CustomCard, CustomShadowCard } from '../../components/shared-layout/card'
import RemoveIcon from '@mui/icons-material/Remove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// import Button from '@mui/material/Button'
import { RoundedDarkButton, ThemeDarkButton, ThemeDisabledButton, ThemeIconButton } from '../../components/shared-layout/buttons/Button';
// import CheckOutModal from '../../components/modal/checkOutModal';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import { UserContext } from '../../context/UserContext';
import { GroveController } from '../../controllers/GroveController';
import { useState } from 'react';
import { API_SUCCESS } from '../../config/ApiConfig';
import ThemeInput from '../../components/shared-layout/Input';
import PageLoader from '../../components/pageLoader';
import { useNavigate } from 'react-router-dom';
import PaymentMethod from '../../components/payment/pay';
import { toast } from 'react-toastify';
import ConfirmModal from '../../components/modal/confirmModal';

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));


export default function Cart() {
    const [open, setOpen] = useState(false)
    console.log("open: ", open);
    const [payment, setPayment] = useState(false);
    const [total, setTotal] = useState(false);
    const [data, setData] = useState("")
    console.log("data: ", data);
    // const [note, setNote] = useState(false);
    // const [notesValue, setNotesValue] = useState("");
    const [loading, setLoading] = useState(false);
    const { getToken } = useContext(UserContext);
    const ref = useRef()
    const navigate = useNavigate()

    const getCartData = async () => {
        setLoading(true);
        const token = getToken()
        const instance = new GroveController()
        const result = await instance.getCartItem(token)
        if (result.success) {
            let totalAmount = 0;
            result.data?.relation?.items.map(itemNew => totalAmount = totalAmount + itemNew?.attributes?.total_price);
            setTotal(totalAmount);
            setData(result.data)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCartData()
    }, [])

    const handleAddItem = async item => {
        setLoading(true)
        console.log("item: ", JSON.parse(item.attributes.addons));
        const addons = JSON.parse(item.attributes.addons)
        const token = await getToken();
        const instance = new GroveController();

        console.log(item, 'item');
        const result = await instance.addItem(token, item.attributes.optional_item_id, item.attributes.quantity + 1, item.attributes.notes, JSON.stringify(addons));
        console.log(result, 'addcart');
        if (result.status === 'success') {
            getCartData()
            setLoading(false)
        }
        setLoading(false)
    };

    const handleRemoveItem = async (item) => {
        const token = await getToken();
        const instance = new GroveController();
        const addons = JSON.parse(item.attributes.addons)

        if (item.attributes.quantity > 1) {
            const result = await instance.addItem(token, item.attributes.optional_item_id, item.attributes.quantity - 1, item.attributes.notes, JSON.stringify(addons));
            console.log(result, 'addcart');
            if (result.status === 'success') {
                getCartData()
            }
        } else {
            setLoading(true);
            const result = await instance.removeItem(token, item.attributes.optional_item_id);
            console.log(result, 'remove');
            if (result.status === 'success') {
                getCartData()
            } else {
                setLoading(false);
            }
        }
    }

    const payNow = (val) => {
        console.log("val: ", val);
        if (val === 'wallet') {
            setOpen(true);
        } else if (val === 'debit') {
            handlePayment('VISA');
        }
        else if (val === 'gpay') {
            handlePayment('Gpay')
        }
        else if (val === 'apple') {
            handlePayment('ApplePay')
        }
    }



    const handlePayment = async (type) => {
        setLoading(true)
        const token = await getToken();
        const cart_id = data.id
        const instance = new GroveController();

        const result = await instance.checkout(token, cart_id, type);
        setData(result)
        if (result.IsSuccess === true) {
            if (type === 'Wallet') {
                toast(result.Message);
                setLoading(false);
                setOpen(false);
                navigate('/double-joy')
            } else {
                window.location.href = result.Data.PaymentURL;
                setLoading(false);
            }
        } else {
            toast(result.msg)
            setLoading(false)
        }
    }

    const handleWalletPay = () => {
        handlePayment('Wallet');
    }

    return (
        <Root>
            <ViewLayout backgroundColor={"#ffffff"} title="Double Joy" fontFamily="Gotham-Black" backFunction="/double-joy">
                {!payment ?
                    <Box sx={{
                        height: "calc(100vh - 45px)",
                        overflowY: "auto",
                        position: "relative",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}>
                        {data?.relation?.items.length !== 0 ?
                            <>
                                <Box sx={{ height: `calc(100% - (20px + ${ref?.current?.clientHeight}px))`, overflowY: "auto", p: 2 }}>
                                    {data?.relation?.items.map((item, index) =>
                                        <CustomCard sx={{ mb: 2 }}>
                                            <Grid key={index} sx={{ justifyContent: "space-between" }} spacing={4} container>
                                                <Grid
                                                    onClick={() => navigate(`/items?id=${item.attributes.optional_item_id}`)}
                                                    item xs={4}>
                                                    <CustomShadowCard sx={{ p: 1, borderRadius: "12px" }}>
                                                        <Box sx={{
                                                            height: "10vh",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            borderRadius: "8px",
                                                            overflow: "hidden",
                                                            alignItems: "center",
                                                        }}>
                                                            <img src={API_SUCCESS + '/' + item.attributes.image} alt='' width={"100%"} />
                                                        </Box>
                                                    </CustomShadowCard>
                                                </Grid>
                                                <Grid sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                }} item xs={8}>
                                                    <Typography sx={{ fontSize: "18px", fontFamily: "Gotham-Medium", fontWeight: "600" }}>{item.attributes.optional_item}</Typography>
                                                    {item.attributes.addons
                                                        &&
                                                        <Box sx={{ width: "auto", display: "flex", alignItems: "center" }}>
                                                            {JSON.parse(item.attributes.addons).map((item, index) =>
                                                                <Typography component={'span'} sx={{ fontSize: "10px", fontFamily: "Gotham-Light", px: "4px", py: "2px", backgroundColor: "white", borderRadius: "20px", border: "0.5px solid black" }}>{item.name}</Typography>
                                                            )}
                                                        </Box>
                                                    }
                                                    <Box sx={{ display: "flex", columnGap: 4, mt: "5px", justifyContent: "space-between" }}>
                                                        <Typography sx={{ fontSize: "16px", fontFamily: "Gotham-Medium", fontWeight: 600 }}>{item.attributes.price} QR</Typography>
                                                        <Box sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            borderRadius: "5px",
                                                            backgroundColor: "#f2f2f2",
                                                            "& .MuiButton-root": { p: 0, minWidth: 0 }
                                                        }}>
                                                            <ThemeIconButton onClick={() => handleRemoveItem(item)} >
                                                                <RemoveIcon sx={{ fontSize: "18px" }} />
                                                            </ThemeIconButton>
                                                            <Typography sx={{ fontSize: "14px", fontFamily: "Gotham-Medium", mx: 1 }}>{item.attributes.quantity}</Typography>
                                                            <ThemeIconButton onClick={() => handleAddItem(item)}>
                                                                <AddOutlinedIcon sx={{ fontSize: "18px" }} />
                                                            </ThemeIconButton>
                                                        </Box>
                                                    </Box>

                                                </Grid>
                                            </Grid>
                                        </CustomCard>
                                    )}
                                </Box>

                            </>
                            :
                            <Typography sx={{ textAlign: "center", position: "relative", top: -60, bottom: 0, marginBlock: "auto" }}>No Order in your cart</Typography>
                        }
                        <Box ref={ref} sx={{ position: "absolute", backgroundColor: "white", bottom: 0, width: "-webkit-fill-available", px: 2, pb: 4 }} >
                            <Box>
                                {data ?
                                    <>
                                        {data?.relation?.items.length !== 0 ?
                                            <ThemeDarkButton onClick={() => setPayment(true)}>
                                                <Box sx={{ width: "100%", p: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography sx={{ fontSize: "12px" }} >GO TO CHECKOUT</Typography>
                                                    <Typography sx={{ fontSize: "16px", fontFamily: "Gotham-Black" }}>{total} QR</Typography>
                                                </Box>
                                            </ThemeDarkButton>
                                            :
                                            <ThemeDarkButton onClick={() => navigate('/double-joy')}>
                                                <Box sx={{ width: "100%", p: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Typography sx={{ fontSize: "12px" }} >Continue shopping</Typography>
                                                </Box>
                                            </ThemeDarkButton>
                                        }
                                    </>
                                    :
                                    <ThemeDarkButton onClick={() => navigate('/double-joy')}>
                                        <Box sx={{ width: "100%", p: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Typography sx={{ fontSize: "12px" }} >Continue shopping</Typography>
                                        </Box>
                                    </ThemeDarkButton>
                                }

                            </Box>
                        </Box>
                    </Box>
                    :
                    <PaymentMethod payNow={payNow} />
                }
            </ViewLayout>
            <ConfirmModal setOpen={setOpen} title={"You want to book package from wallet?"} open={open} handleWalletPay={handleWalletPay} />
            {loading && <PageLoader />}
        </Root >
    )
}
