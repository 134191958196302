import React from 'react'
import { CustomShadowCard } from '../../components/shared-layout/card';
import RemoveIcon from '@mui/icons-material/Remove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, Grid, Typography } from '@mui/material'
import { API_SUCCESS } from '../../config/ApiConfig';
import { ThemeIconButton } from '../shared-layout/buttons/Button';


export default function ShoppingCard({ data, onClick, handleAddItem, handleRemoveItem }) {
  return (
    <CustomShadowCard sx={{ p: 1.5, m: 1 }}>
      <Box width={"100%"}>
        <Box
          onClick={onClick}
          sx={{
            width: "100%",
            height: "110px",
            display: "flex",
            justifyContent: "center",
            mb: 1,
            borderRadius: "12px",
            overflow: "hidden",
            alignItems: "center",
          }}>
          <img src={API_SUCCESS + "/" + data.attributes.image} alt='' height="100%" />
        </Box>
        <Box>
          <Typography sx={{ fontFamily: "Gotham-Black", fontSize: "10px" }} variant='subtitle2'>{data.attributes.name}</Typography>
        </Box>
        <Box>
          <Grid container>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <Grid item xs={6}>
                <Typography sx={{ fontFamily: "Gotham-Black", fontSize: "15px" }} variant='subtitle2'>{data.attributes.price} QAR</Typography>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: "flex", justifyContent: data.quantity !== 0 ? "space-between" : "flex-end", width: "100%", backgroundColor: data.quantity !== 0 ? "#f2f2f2" : "", paddingInline: "3px", borderRadius: "5px", alignItems: "center", "& .MuiButton-root": { p: 0, minWidth: 0 } }}>
                  {data.quantity !== 0 &&
                    <>
                      <ThemeIconButton onClick={handleRemoveItem} style={{ backgroundColor: "black" }}>
                        <RemoveIcon sx={{ color: 'white', fontSize: "small" }} />
                      </ThemeIconButton>
                      <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>{data.quantity}</Typography>
                    </>
                  }
                  <ThemeIconButton onClick={handleAddItem} style={{ backgroundColor: "black" }}>
                    <AddOutlinedIcon sx={{ color: 'white', fontSize: "small" }} />
                  </ThemeIconButton>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </CustomShadowCard>
  )
}
