import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Box, Divider, Grid, Stack } from '@mui/material';
import { CustomCard } from '../shared-layout/card';
import ViewLayout from '../../layout/viewLayout';

export const SkeltonBlackCard = () => <Skeleton variant="rounded" sx={{ borderRadius: "17px" }} width={"100%"} height={90} />;

export const SkeltonCard = () => {
    return (
        <CustomCard sx={{
            borderRadius: "12px",
            marginTop: "10px",
        }}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Box sx={{ width: "100%", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Skeleton variant="rounded" width={"95%"} height={"100%"} />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ height: "60px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Skeleton height={24} width={120} />
                        <Skeleton height={16} width={100} />
                        <Skeleton height={15} width={110} />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <Skeleton sx={{ borderRadius: "14px" }} height={35} width={"95%"} />
                    </Box>
                </Grid>
            </Grid>
        </CustomCard>
    );
};

export const SkeltonClassLayout = () => {
    return (
        <Box sx={{ height: "calc(100vh - 60px)", overflowY: "auto", width: "100%", display: "flex", justifyContent: "center", backgroundColor: "white", fontFamily: "Gotham-Book", }}>
            <Box sx={{ backgroundColor: "white", width: "100%", height: "680px ", paddingInline: 6, pt: 1, overflow: "hidden" }}>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} height={20} />
                    <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} height={20} />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }} pt={4}>
                        <Box height={500} width={"40%"}>
                            <Skeleton variant="rounded" sx={{ borderRadius: "10px" }} width="100%" height="100%" />
                        </Box>
                        <Box height={500} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} width={"40%"}>
                            <Skeleton variant="rounded" sx={{ borderRadius: "50% 0 0 50%" }} width="100%" height={350} />
                        </Box>
                    </Box>
                </Stack>
            </Box >
        </Box >
    );
};

// export const SkeltonStudio = () => {
//     return (
//         <SkeletonPlaceholder borderRadius={32}>
//             <Skeleton
//                 flexDirection="row"
//                 width={width - 40}
//                 justifyContent="space-between">
//                 <Skeleton
//                     marginTop={10}
//                     width={width / 3 - 25}
//                     height={35}
//                 />
//                 <Skeleton
//                     marginTop={10}
//                     width={width / 3 - 25}
//                     height={35}
//                 />
//                 <Skeleton
//                     marginTop={10}
//                     width={width / 3 - 25}
//                     height={35}
//                 />
//             </Skeleton>
//         </SkeletonPlaceholder>
//     );
// };
