import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import MainLayout from '../../layout/mainLayout'
// import ViewLayout from '../../layout/viewLayout'
import { Box, Typography } from '@mui/material'
import Divider from '@mui/material/Divider';
import { CustomShadowCard } from '../../components/shared-layout/card'
import PaymentMethod from '../payment';
import { RoundedLightButton } from '../../components/shared-layout/buttons/Button'

const BookingSummary = ({ item, setSummeryShow }) => {

  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const handlePayment = () => {
    setShow(true)
    // setSummeryShow(false)
  }

  return (
    <>
      {!show ?
        <Box sx={{ px: 1.5, pt: 1.5, pb: 4 }}>
          <Typography variant='a' sx={{ position: 'absolute', left: '15px' }} onClick={() => setSummeryShow(false)}>
            <img src="images/icons/back.png" style={{ width: 16, height: 16, marginRight: 10 }} alt='' />
          </Typography>
          <Typography sx={{ fontSize: "14px", fontFamily: "Gotham-Medium", mb: 1, mt: 2, textAlign: 'center' }}>BOOKING SUMMARY</Typography>


          {/* {item.map(item, index)} */}
          <Box>
            <CustomShadowCard sx={{ px: 4, py: 5, mt: 4, backgroundColor: "black", borderRadius: "30px", boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px" }}>
              <Box sx={{ color: "white", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", mb: 1.5 }}>
                <Typography sx={{ fontFamily: "Gotham-Book" }}> TRIAL RIDE</Typography>
                <Typography sx={{ fontFamily: "Gotham-Book" }}>{item.attributes.amount} QR</Typography>
              </Box>
              <Divider sx={{ color: "white", width: "100%", backgroundColor: "white", borderWidth: 0.1, mb: 1.5 }} />

              <Box sx={{ color: "white", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", mb: 1.5 }}>
                <Typography sx={{ fontFamily: "Gotham-Book" }}>RIDES</Typography>
                <Typography sx={{ fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{item.attributes.rides}</Typography>
              </Box>
              <Divider sx={{ color: "white", width: "100%", backgroundColor: "white", borderWidth: 0.1, mb: 1.5 }} />


              <Box sx={{ color: "white", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", mb: 1.5 }}>
                <Typography sx={{ fontFamily: "Gotham-Book" }}> VALIDITY</Typography>
                <Typography sx={{ fontFamily: "Gotham-Book" }}>{item.attributes.validity} DAYS</Typography>
              </Box>
              <Divider sx={{ color: "white", width: "100%", backgroundColor: "white", borderWidth: 0.1, mb: 1.5 }} />


              <Box sx={{ color: "white", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", mb: 1.5 }}>
                <Typography sx={{ fontFamily: "Gotham-Book" }}> TOTAL</Typography>
                <Typography sx={{ fontFamily: "Gotham-Book" }}>{item.attributes.amount} QR</Typography>
              </Box>

              <Box sx={{ width: "100%", mt: 1 }}>
                <RoundedLightButton onClick={() => handlePayment()} style={{ height: "35px", ":hover": { color: "white", border: "1px solid white" } }}>
                  <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", width: "100%", paddingX: 1 }}>
                    <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Book" }}>PAY </Typography>
                    <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Book" }}>{item.attributes.amount} QR</Typography>
                  </Box>
                </RoundedLightButton>

              </Box>

            </CustomShadowCard>

          </Box>

        </Box >
        : <PaymentMethod item={item} />
      }
    </ >
  )
}

export default BookingSummary