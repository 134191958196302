import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Card, Box, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { JourneyContoller } from '../../controllers/JourneyController';
import { API_SUCCESS } from '../../config/ApiConfig';
import PageLoader from '../../components/pageLoader';

const HomeAchievements = () => {
    const navigate = useNavigate()
    const [locations, setLocations] = useState([]);
    const { getToken } = useContext(UserContext)
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [bedges, setBedges] = useState([]);
    console.log("bedges: ", bedges);

    useEffect(() => {
        getBookings();
        getBadges()
    }, []);

    const getBookings = async () => {
        setLoading(true);
        const token = await getToken();
        const instance = new JourneyContoller();
        const result = await instance.getAllBooking(token);
        setLoading(false);
        setLocations(result.locations);
        setTotal(getTotal(result.locations));
    };

    const getBadges = async () => {
        setLoading(true);
        const token = await getToken();
        const instance = new JourneyContoller();
        const result = await instance.getAllBedges(token);
        setLoading(false);
        const mybedge = result.badges.filter(item => item?.status === true);
        setBedges(mybedge);
    };

    const getTotal = allLocation => {
        let totalVal = 0;
        allLocation.forEach(element => {
            totalVal = totalVal + element.bookings_count;
        });
        return totalVal;
    };

    return (
        <>
            {loading === true &&
                <PageLoader />
            }
            <MainLayout>
                <Box sx={{ px: 4, pt: 1.5, pb: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", mt: 5 }}>
                        <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "14px", lineHeight: 0.5 }}>YOUR JOURNEY</Typography>
                        <Typography variant='span' sx={{ fontFamily: "Gotham-Medium", fontSize: "70px", letterSpacing: "1px", lineHeight: 1.2 }}> {total}</Typography>
                        <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "11px", lineHeight: 0.5 }}>TOTAL CLASSES</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: "center", columnGap: "27px", alignItems: 'center', mt: 5 }}>
                        {locations.map((item, index) => (
                            <Card key={index + 'booking'} sx={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", height: "65px", width: "70px", borderRadius: "20px", p: 1, boxShadow: "5px 4px 10px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);" }}>
                                <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "12px", textTransform: 'uppercase' }}>{item.name}</Typography>
                                <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "30px", lineHeight: 0.9 }}>{item.bookings_count}</Typography>
                            </Card>
                        ))}
                    </Box>

                    {/* {(bedges && bedges.length > 0) && */}
                        <Box sx={{ display: 'flex', justifyContent: "space-between", flexDirection: "column", alignItems: 'center', mt: 6, width: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%" }}>
                                <Typography sx={{ fontSize: "13px", fontFamily: "Gotham-Book" }}>ACHIEVEMENTS</Typography>
                                <Typography sx={{ fontSize: "13px", fontFamily: "Gotham-Book", cursor: "pointer" }} onClick={() => navigate("/all-achievements")}>SEE ALL</Typography>
                            </Box>
                            <Grid container sx={{ rowGap: 2, mt: 2.5 }}>
                                {bedges?.map((item, index) => (
                                    <Grid sx={{ display: index > 2 ? "none" : "flex", justifyContent: "center" }} item xs={4} sm={4}>
                                        <Card
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "50px",
                                                width: "50px",
                                                borderRadius: "20px",
                                                p: 1,
                                                backgroundColor: "#f2f2f2",
                                                boxShadow: "none",
                                                backgroundImage: `url(${API_SUCCESS}/${item.image})`,
                                                backgroundSize: 'cover',
                                                border: '1px solid #f2f2f2'
                                            }}
                                        >
                                            {!item?.status &&
                                                <LockIcon fontSize="large" />
                                            }
                                        </Card>
                                    </Grid>
                                ))}

                            </Grid>
                        </Box>
                    {/* // } */}


                </Box>
            </MainLayout>
        </>
    )
}

export default HomeAchievements;
