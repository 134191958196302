import React, { useState, useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ThemeInput from '../../components/shared-layout/Input'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThemeDarkButton, ThemeDisabledButton } from '../../components/shared-layout/buttons/Button'
import RadioGroup from '@mui/material/RadioGroup';
import PhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { AuthContoller } from '../../controllers/AuthController';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../context/UserContext';
import 'react-phone-input-2/lib/material.css'
import PageLoader from '../../components/pageLoader';
import TermsAndCondition from '../../components/modal/termsAndCondition';

export default function SignUp() {


  const today = new Date();
  const tenYearsAgo = new Date(today);
  tenYearsAgo.setFullYear(today.getFullYear() - 10);
  // const formattedDate = tenYearsAgo.toISOString().split('T')[0];

  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [agree, setAgree] = useState("")
  const [loading, setLoading] = useState(false);
  const userCtx = useContext(UserContext);
  const [open, setOpen] = React.useState(false);

  // const { setAuth } = useContext(UserContext);
  // const { setToken } = useContext(UserContext);
  // const dt = new Date();
  // const maxDate = new Date(dt.setFullYear(dt.getFullYear() - 15));


  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    gender: "male",
    password: "",
  })
  console.log("data: ", data);

  const handleSubmit = async () => {
    const validate = validateDetail();
    if (!validate.msg) {
      setLoading(true)

      const instance = new AuthContoller();
      const result = await instance.signUpUser(data);
      console.log(result)
      if (result?.status) {
        userCtx.setUser(result.user);
        toast("We have sent a verify link on your email please verify.");
        navigate(`/Verify?email=${data.email}`,)
        setLoading(false);
        toast(result.msg);
      } else {
        setLoading(false);
        toast(result.message);
      }
    } else {
      toast(validate.msg);
    }
  };

  function validateDetail() {
    if (
      data.firstName !== '' &&
      data.lastName !== '' &&
      data.email !== '' &&
      data.phone !== '' &&
      data.password !== ''
    ) {
      if (data.password.length > 7) {
        return true;
      } else {
        return { msg: 'Password must be 8 characters.' };
      }
    } else {
      return { msg: 'please fill all details' };
    }
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      {loading === true &&
        <PageLoader />
      }
      <ToastContainer autoClose={2000} />
      <Box sx={{
        minHeight: "100vh",
        maxHeight: "100%",
        width: { md: "400px", xs: "100%" },
        marginX: "auto",
        overflow: "hidden",
        borderInline: "1px solid lightgray",
      }}>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          height: "60px"
        }}>
          <img src="/images/logos/veloBlack.svg" alt='' />
        </Box>
        <Divider />
        <Box sx={{
          height: "calc(100vh - 60px)",
          overflowY: "auto",
        }}>
          <Box sx={{
            height: "100%",
          }}>
            <Box sx={{ pt: 2, px: 5, mb: 2, }}>
              <Typography sx={{ fontSize: "25px", fontFamily: "Gotham-light", fontWeight: "600" }}>SIGN UP</Typography>
            </Box>
            <Box sx={{ px: 5, pb: 1 }}>
              <Box mb={2}>
                <ThemeInput
                  label={"FIRST NAME"}
                  type='text'
                  onChange={(e) => setData({ ...data, firstName: e.target.value })}
                />
              </Box>
              <Box mb={2}>
                <ThemeInput
                  label={"LAST NAME"}
                  type='text'
                  onChange={(e) => setData({ ...data, lastName: e.target.value })}
                />
              </Box>
              <Box mb={2}>
                <ThemeInput
                  label={"E-MAIL ADDRESS"}
                  type='mail'
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </Box>
              <Box mb={2}>
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    fontSize: "17px"
                  }}
                  country={'us'}
                  value={data.phone}
                  onChange={phone => setData({ ...data, phone: phone })}
                />
              </Box>
              <Box mb={2}>
                <ThemeInput
                  shrink={true}
                  label={"BIRTH DATE"}
                  type='date'
                  min="1980-01-01" max="2000-01-01"
                  onChange={(e) => setData({ ...data, dob: e.target.value })}
                />
              </Box>
              <Box mb={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={data.gender}
                    onChange={(e) => setData({ ...data, gender: e.target.value })}
                  >
                    <FormControlLabel sx={{}} value="Male" control={<Radio />} label="Male" />
                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mb={2}>
                <ThemeInput
                  label={"PASSWORD"}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setData({ ...data, password: e.target.value })}

                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>

              <Box mb={2}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Checkbox
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{ py: 0 }} />
                  <Typography sx={{ textDecoration: "none", fontSize: "12px", fontFamily: "Gotham-Book" }}>By signup you agree our <Box sx={{ fontFamily: "Gotham-Medium" }} onClick={handleClickOpen} component={"span"}>Terms and Conditions</Box> and <Box sx={{ fontFamily: "Gotham-Medium" }} component={"span"}>Privacy Policy</Box>.</Typography>
                </Box>
              </Box>
              <Box mb={2}>
                {agree ?
                  <ThemeDarkButton style={{ fontSize: "15px", fontWeight: 100, fontFamily: "Gotham-Light", letterSpacing: "1px" }} onClick={handleSubmit}>SIGN UP</ThemeDarkButton>
                  :
                  <ThemeDisabledButton style={{ fontSize: "15px", fontWeight: 100, fontFamily: "Gotham-Light", letterSpacing: "1px" }}>SIGN UP</ThemeDisabledButton>
                }
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
                <Link sx={{ cursor: "pointer", textDecoration: "none", fontFamily: "Gotham-Light", fontSize: "12px" }} onClick={() => navigate('/login')}>BACK TO LOGIN</Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <TermsAndCondition open={open} handleClose={handleClose} />
      <ToastContainer />
    </>
  )
}
