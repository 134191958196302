import React from 'react'
import MainLayout from '../../layout/mainLayout'
import { Typography, Box, CardContent } from '@mui/material'
import Card from '@mui/material/Card';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { RoundedDarkButton } from '../../components/shared-layout/buttons/Button';

const ManageCard = () => {

    return (
        <MainLayout>
            <Box sx={{ px: 4, pt: 1.5, pb: 4 }}>
                <Typography variant='button'  sx={{ fontFamily: "Gotham-Book" }}>Wallet</Typography>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: 'column', mt: 2 }}>
                    <Card sx={{ display: 'flex', justifyContent: "center", alignitems: "center", borderRadius: "30px", height: "190px", width: "100%", boxShadow: " rgba(0, 0, 0, 0.15) 0px 6px 13px 2px" }}>
                        <CardContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>0 QR </Typography>
                        </CardContent>
                    </Card>

                    <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", mt: 2 }}>
                        <Box mb={1.5}>
                            <RoundedDarkButton style={{ width: "130px", height: "35px", fontSize: "15px" }} >
                                TOP UP
                            </RoundedDarkButton>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: 'column', mt: 2 }}>
                    <Card sx={{ display: 'flex', justifyContent: "", alignitems: "center", borderRadius: "30px", height: "190px", width: "100%", boxShadow: " rgba(0, 0, 0, 0.15) 0px 6px 13px 2px" }}>
                        <CardContent sx={{ paddingX: 4, paddingY: 4 }}>
                            <Box sx={{ display: "flex", justifyContent: "left", alignContent: "left" }}>
                                <img src='/images/logos/visaLogo.png' style={{ width: "80px", height: "25px", paddingLeft: 3 }} alt='' />
                            </Box>
                            <Box sx={{ display: 'flex', mt: 4, alignItems: "stretch", justifyContent: "center" }}>
                                <Typography mr={1}>
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                </Typography>

                                <Typography mr={1}>
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                </Typography>

                                <Typography mr={1}>
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                    <FiberManualRecordIcon fontSize='8px' />
                                </Typography>

                                <Typography mr={1} sx={{ fontSize: "12px", fontWeight: 1000 }}>
                                    2880
                                </Typography>

                            </Box>

                            <Box mt={3} sx={{display:"flex" , justifyContent:"space-between" , alignItems:"center" }}>
                                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                    <Typography sx={{ fontSize: "10px", fontWeight: 500 }}>NAME</Typography>
                                    <Typography sx={{ fontSize: "12px", fontWeight: 1000 }}>LORREM IPSUM</Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: "10px", fontWeight: 500 }}>VALID THRU</Typography>
                                    <Typography sx={{ fontSize: "12px", fontWeight: 1000 }}>10/27</Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                    <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", mt: 2 }}>
                        <Box mb={1.5}>
                            <RoundedDarkButton style={{ width: "130px", height: "35px" }} >
                                REMOVE CARD
                            </RoundedDarkButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MainLayout >
    )
}

export default ManageCard