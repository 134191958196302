import React, { useContext, useEffect, useState } from 'react'
import ViewLayout from '../../layout/viewLayout'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { CustomCard } from '../../components/shared-layout/card'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button'
import { RoundedDarkButton } from '../../components/shared-layout/buttons/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_SUCCESS } from '../../config/ApiConfig';
import { UserContext } from '../../context/UserContext';
import { HappeningContoller } from '../../controllers/HappeningController';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ProfileController } from '../../controllers/ProfileController';

export default function HappeningReport() {

    const navigate = useNavigate()
    const [item, setItem] = useState();
    const { getToken } = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({});
    const [data, setData] = useState([]);
    const [rides, setRides] = useState(0)

    useEffect(() => {
        getDetail();
        getHappeningDetail()
    }, [])

    const getDetail = async () => {
        setLoading(true)
        const token = await getToken();
        console.log("token: ", token);
        const instance = new ProfileController();
        const result = await instance.getUserDetail(token);
        setUser(result.user);
        setLoading(false)
    }

    const getHappeningDetail = async () => {
        const token = await getToken();
        const id = searchParams.get("id");
        const instance = new HappeningContoller();
        const result = await instance.getChallenges(id, token);
        setItem(result?.happening);
        if (result?.happening) {
            let newData = [];
            for (var i = 0; i < result?.happening.attended_classes; i++) {
                newData.push(i);
            }
            setData(newData);
        }
        console.log(API_SUCCESS + '/' + result?.happening?.icon);
        if (result?.rides) {
            setRides(result?.rides);
        }
    };


    return (
        <ViewLayout title="" backgroundColor="#ffffff">
            <Box sx={{ height: "calc(100vh - 60px)", position: "relative", backgroundColor: "#e2e3e5" }}>
                <Box sx={{ height: "40%" }}>
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundImage: `url(${API_SUCCESS + '/' + item?.image})`,
                        backgroundPosition:"center",
                        backgroundSize:"cover",
                    }}/>
                        {/* <img src={API_SUCCESS + '/' + item?.image} alt='' height={"85%"} />
                    </Box> */}
                </Box>
                <Box sx={{ height: "70%",overflowY: "auto",mt:"-10%", backgroundColor: "#ffffff", borderRadius: "40px 40px 0px 0px" }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}>
                        <Box sx={{
                            p: 4,
                            rowGap: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}>
                            <Box sx={{
                                "& .MuiTypography-root": { fontFamily: "Gotham-Medium", fontWeight: 700 }
                            }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ textTransform: 'uppercase', fontSize: 12 }}>{user?.first_name} {user?.last_name}</Typography>
                                    <Typography sx={{ textTransform: 'uppercase', fontSize: 12 }}>{moment(item?.start_date).format('DD MMM, YYYY')} -{' '}
                                        {moment(item?.end_date).format('DD MMM, YYYY')}</Typography>
                                </Box>
                                <Typography sx={{ textTransform: 'uppercase', fontSize: "20px" }}>{item?.title}</Typography>

                                <Box sx={{
                                    backgroundColor: '#000',
                                    width: '100%',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    alignSelf: 'center',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-around',
                                }}>
                                    {data.map((i, index) => (
                                        <Box sx={{
                                            borderWidth: "1px",
                                            borderColor: '#000',
                                            width: "32px",
                                            height: "32px",
                                            borderRadius: "20px",
                                            margin: "12px",
                                            backgroundColor: '#fff',
                                            zIndex: 999,
                                            textAlign: 'center',
                                            position: 'relative',
                                        }}>
                                            {index < rides && (
                                                <img src={API_SUCCESS + '/' + item?.icon} style={{
                                                    textAlign: 'center',
                                                    lineHeight: "25px",
                                                    borderWidth: "1px",
                                                    borderColor: '#000',
                                                    borderRadius: "28px",
                                                    margin: "2px",
                                                    width: "auto",
                                                    maxWidth: '100%',
                                                    height: "26px",
                                                }} />
                                            )}
                                        </Box>
                                    ))}


                                </Box>


                                <Typography sx={{ textTransform: 'uppercase', fontSize: "20px" }}>{item?.description}</Typography>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ViewLayout>
    )
}
