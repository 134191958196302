import { useEffect, useState, useContext } from "react"
import { Box, Divider, Typography } from "@mui/material";
import { phoneBookings } from "../../services/bookings";
import ViewLayout from "../../layout/viewLayout";
import { ClassController } from "../../controllers/ClassController";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from '../../context/UserContext'
import BookingLayoutFooter from "../../components/booingLayoutFooter";
import { ToastContainer, toast } from 'react-toastify';
import PageLoader from "../../components/pageLoader";
import 'react-toastify/dist/ReactToastify.css';
// import Skeleton from '@mui/material/Skeleton';
// import Stack from '@mui/material/Stack';
import OutdoorClassDetail from "../classes/outdoor";
import moment from "moment";
import { ProfileController } from "../../controllers/ProfileController";
import { SkeltonClassLayout } from "../../components/skeleton/skeleton";


// for 12 center class
// const allPlan = {
//     "seat": [
//         { "x": 300, "y": 1650, "num": "01", "seatText": "1", "type": "floor" },
//         { "x": 580, "y": 1650, "num": "02", "seatText": "2", "type": "floor" },
//         { "x": 830, "y": 1440, "num": "03", "seatText": "3", "type": "floor" },
//         { "x": 830, "y": 1180, "num": "04", "seatText": "4", "type": "floor" },
//         { "x": 830, "y": 920, "num": "05", "seatText": "5", "type": "floor" },
//         { "x": 830, "y": 660, "num": "06", "seatText": "6", "type": "floor" },
//         { "x": 580, "y": 450, "num": "07", "seatText": "7", "type": "floor" },
//         { "x": 300, "y": 450, "num": "08", "seatText": "8", "type": "floor" },
//         { "x": 50, "y": 660, "num": "09", "seatText": "9", "type": "floor" },
//         { "x": 50, "y": 920, "num": "10", "seatText": "10", "type": "floor" },
//         { "x": 50, "y": 1180, "num": "11", "seatText": "11", "type": "floor" },
//         { "x": 50, "y": 1440, "num": "12", "seatText": "12", "type": "floor" }
//     ],
//     "stage": "center"
// }

// for 14 center class
// const allPlan = {
//     "seat": [
//         { "x": 300, "y": 420, "num": "09", "seatText": "9", "type": "floor" },
//         { "x": 50, "y": 620, "num": "10", "seatText": "10", "type": "floor" },
//         { "x": 50, "y": 820, "num": "11", "seatText": "11", "type": "floor" },
//         { "x": 50, "y": 1020, "num": "12", "seatText": "12", "type": "floor" },
//         { "x": 50, "y": 1220, "num": "13", "seatText": "13", "type": "floor" },
//         { "x": 50, "y": 1420, "num": "14", "seatText": "14", "type": "floor" },
//         { "x": 300, "y": 1620, "num": "01", "seatText": "1", "type": "floor" },
//         { "x": 580, "y": 420, "num": "08", "seatText": "8", "type": "floor" },
//         { "x": 830, "y": 620, "num": "07", "seatText": "7", "type": "floor" },
//         { "x": 830, "y": 820, "num": "06", "seatText": "6", "type": "floor" },
//         { "x": 830, "y": 1020, "num": "05", "seatText": "5", "type": "floor" },
//         { "x": 830, "y": 1220, "num": "04", "seatText": "4", "type": "floor" },
//         { "x": 830, "y": 1420, "num": "03", "seatText": "3", "type": "floor" },
//         { "x": 580, "y": 1620, "num": "02", "seatText": "2", "type": "floor" },
//     ],
//     "stage": "center"
// }

// for 18 bottom class
// const allPlan = {
//     "seat": [
//         { "x": 220, "y": 400, "num": "01", "seatText": "F1", "type": "floor" },
//         { "x": 220, "y": 540, "num": "02", "seatText": "F2", "type": "floor" },
//         { "x": 220, "y": 680, "num": "03", "seatText": "F3", "type": "floor" },
//         { "x": 220, "y": 820, "num": "04", "seatText": "F4", "type": "floor" },
//         { "x": 220, "y": 960, "num": "05", "seatText": "F5", "type": "floor" },
//         { "x": 220, "y": 1100, "num": "06", "seatText": "F6", "type": "floor" },
//         { "x": 220, "y": 1240, "num": "07", "seatText": "F7", "type": "floor" },
//         { "x": 220, "y": 1380, "num": "08", "seatText": "F8", "type": "floor" },
//         { "x": 220, "y": 1520, "num": "09", "seatText": "F9", "type": "floor" },
//         { "x": 680, "y": 400, "num": "10", "seatText": "F10", "type": "floor" },
//         { "x": 680, "y": 540, "num": "11", "seatText": "F11", "type": "floor" },
//         { "x": 680, "y": 680, "num": "12", "seatText": "F12", "type": "floor" },
//         { "x": 680, "y": 820, "num": "13", "seatText": "F13", "type": "floor" },
//         { "x": 680, "y": 960, "num": "14", "seatText": "F14", "type": "floor" },
//         { "x": 680, "y": 1100, "num": "15", "seatText": "F15", "type": "floor" },
//         { "x": 680, "y": 1240, "num": "16", "seatText": "F16", "type": "floor" },
//         { "x": 680, "y": 1380, "num": "17", "seatText": "F17", "type": "floor" },
//         { "x": 680, "y": 1520, "num": "18", "seatText": "F18", "type": "floor" },
//         { "x": 80, "y": 400, "num": "19", "seatText": "R1", "type": "rack" },
//         { "x": 80, "y": 540, "num": "20", "seatText": "R2", "type": "rack" },
//         { "x": 80, "y": 680, "num": "21", "seatText": "R3", "type": "rack" },
//         { "x": 80, "y": 820, "num": "22", "seatText": "R4", "type": "rack" },
//         { "x": 80, "y": 960, "num": "23", "seatText": "R5", "type": "rack" },
//         { "x": 80, "y": 1100, "num": "24", "seatText": "R6", "type": "rack" },
//         { "x": 80, "y": 1240, "num": "25", "seatText": "R7", "type": "rack" },
//         { "x": 80, "y": 1380, "num": "26", "seatText": "R8", "type": "rack" },
//         { "x": 80, "y": 1520, "num": "27", "seatText": "R9", "type": "rack" },
//         { "x": 830, "y": 400, "num": "28", "seatText": "R10", "type": "rack" },
//         { "x": 830, "y": 540, "num": "29", "seatText": "R11", "type": "rack" },
//         { "x": 830, "y": 680, "num": "30", "seatText": "R12", "type": "rack" },
//         { "x": 830, "y": 820, "num": "31", "seatText": "R13", "type": "rack" },
//         { "x": 830, "y": 960, "num": "32", "seatText": "R14", "type": "rack" },
//         { "x": 830, "y": 1100, "num": "33", "seatText": "R15", "type": "rack" },
//         { "x": 830, "y": 1240, "num": "34", "seatText": "R16", "type": "rack" },
//         { "x": 830, "y": 1380, "num": "35", "seatText": "R17", "type": "rack" },
//         { "x": 830, "y": 1520, "num": "36", "seatText": "R18", "type": "rack" }
//     ],
//     "stage": "bottom"
// }

// for 14 bottom class
// const allPlan = {
//     "seat": [
//         { "x": 220, "y": 400, "num": "01", "seatText": "F1", "type": "floor" },
//         { "x": 220, "y": 560, "num": "02", "seatText": "F2", "type": "floor" },
//         { "x": 220, "y": 720, "num": "03", "seatText": "F3", "type": "floor" },
//         { "x": 220, "y": 880, "num": "04", "seatText": "F4", "type": "floor" },
//         { "x": 220, "y": 1050, "num": "05", "seatText": "F5", "type": "floor" },
//         { "x": 220, "y": 1210, "num": "06", "seatText": "F6", "type": "floor" },
//         { "x": 220, "y": 1370, "num": "07", "seatText": "F7", "type": "floor" },
//         { "x": 680, "y": 400, "num": "08", "seatText": "F8", "type": "floor" },
//         { "x": 680, "y": 560, "num": "09", "seatText": "F9", "type": "floor" },
//         { "x": 680, "y": 720, "num": "10", "seatText": "F10", "type": "floor" },
//         { "x": 680, "y": 880, "num": "11", "seatText": "F11", "type": "floor" },
//         { "x": 680, "y": 1050, "num": "12", "seatText": "F12", "type": "floor" },
//         { "x": 680, "y": 1210, "num": "13", "seatText": "F13", "type": "floor" },
//         { "x": 680, "y": 1370, "num": "14", "seatText": "F14", "type": "floor" },
//         { "x": 80, "y": 400, "num": "15", "seatText": "R1", "type": "rack" },
//         { "x": 80, "y": 560, "num": "16", "seatText": "R2", "type": "rack" },
//         { "x": 80, "y": 720, "num": "17", "seatText": "R3", "type": "rack" },
//         { "x": 80, "y": 880, "num": "18", "seatText": "R4", "type": "rack" },
//         { "x": 80, "y": 1050, "num": "19", "seatText": "R5", "type": "rack" },
//         { "x": 80, "y": 1210, "num": "20", "seatText": "R6", "type": "rack" },
//         { "x": 80, "y": 1370, "num": "21", "seatText": "R7", "type": "rack" },
//         { "x": 830, "y": 400, "num": "22", "seatText": "R8", "type": "rack" },
//         { "x": 830, "y": 560, "num": "23", "seatText": "R9", "type": "rack" },
//         { "x": 830, "y": 720, "num": "24", "seatText": "R10", "type": "rack" },
//         { "x": 830, "y": 880, "num": "25", "seatText": "R11", "type": "rack" },
//         { "x": 830, "y": 1050, "num": "26", "seatText": "R12", "type": "rack" },
//         { "x": 830, "y": 1210, "num": "27", "seatText": "R13", "type": "rack" },
//         { "x": 830, "y": 1370, "num": "28", "seatText": "R14", "type": "rack" }
//     ],
//     "stage": "bottom"
// }

// for 12 bottom class
// const allPlan = {
//     "seat": [
//         { "x": 220, "y": 400, "num": "01", "seatText": "F1", "type": "floor" },
//         { "x": 220, "y": 600, "num": "02", "seatText": "F2", "type": "floor" },
//         { "x": 220, "y": 800, "num": "03", "seatText": "F3", "type": "floor" },
//         { "x": 220, "y": 1000, "num": "04", "seatText": "F4", "type": "floor" },
//         { "x": 220, "y": 1200, "num": "05", "seatText": "F5", "type": "floor" },
//         { "x": 220, "y": 1400, "num": "06", "seatText": "F6", "type": "floor" },
//         { "x": 680, "y": 400, "num": "07", "seatText": "F7", "type": "floor" },
//         { "x": 680, "y": 600, "num": "08", "seatText": "F8", "type": "floor" },
//         { "x": 680, "y": 800, "num": "09", "seatText": "F9", "type": "floor" },
//         { "x": 680, "y": 1000, "num": "10", "seatText": "F10", "type": "floor" },
//         { "x": 680, "y": 1200, "num": "11", "seatText": "F11", "type": "floor" },
//         { "x": 680, "y": 1400, "num": "12", "seatText": "F12", "type": "floor" },
//         { "x": 80, "y": 400, "num": "13", "seatText": "R1", "type": "rack" },
//         { "x": 80, "y": 600, "num": "14", "seatText": "R2", "type": "rack" },
//         { "x": 80, "y": 800, "num": "15", "seatText": "R3", "type": "rack" },
//         { "x": 80, "y": 1000, "num": "16", "seatText": "R4", "type": "rack" },
//         { "x": 80, "y": 1200, "num": "17", "seatText": "R5", "type": "rack" },
//         { "x": 80, "y": 1400, "num": "18", "seatText": "R6", "type": "rack" },
//         { "x": 830, "y": 400, "num": "19", "seatText": "R7", "type": "rack" },
//         { "x": 830, "y": 600, "num": "20", "seatText": "R8", "type": "rack" },
//         { "x": 830, "y": 800, "num": "21", "seatText": "R9", "type": "rack" },
//         { "x": 830, "y": 1000, "num": "22", "seatText": "R10", "type": "rack" },
//         { "x": 830, "y": 1200, "num": "23", "seatText": "R11", "type": "rack" },
//         { "x": 830, "y": 1400, "num": "24", "seatText": "R12", "type": "rack" }
//     ],
//     "stage": "bottom"
// }

const Seat = ({ xPos, yPos, stage, seatText, type, SeatNumber, status, itemIndex, setSelectedIndex, selectedIndex, setSeatObject, item }) => {

    let fillColor;
    if (status === 'yourSpot' || selectedIndex === itemIndex) {
        fillColor = 'black';
    } else if (status === 'Booked') {
        if (item.mine_booked === true) {
            fillColor = 'black';
        } else { fillColor = 'gray'; }
    } else if (status === 'Available') {
        fillColor = '#EDEDED';
    }


    let fillTextColor;
    if (fillTextColor === "yourSpot" || selectedIndex === itemIndex) {
        fillTextColor = 'white';
    } else if (status === 'Booked' || selectedIndex === itemIndex) {
        fillTextColor = 'white';
    } else if (status === "Available") {
        fillTextColor = 'black';
    }

    let selectedWidth;
    if (stage === "bottom") {
        selectedWidth = "100";
    } else if (stage === 'center') {
        selectedWidth = "100";
    } else if (stage === 'right') {
        selectedWidth = "80";
    }


    const handleClick = (seatText) => {
        if (status === 'Available') {
            setSelectedIndex(itemIndex)
            setSeatObject({ seat_text: seatText, seat: item.seat })
            // setSeatObject({ ...data.seats[itemIndex], seat_text: seatText, seat: item.seat })
        }
    }

    return (
        <foreignObject width={selectedWidth} height={selectedWidth} className={selectedIndex === itemIndex ? "selectedSeat" : ""} onClick={() => handleClick(seatText)} x={xPos} y={yPos} style={{ cursor: "pointer" }}>
            <div>
                <svg width={selectedWidth} height={selectedWidth}>
                    {(type === "floor") &&
                        <circle cx="50%" cy="50%" r="50%" fill={fillColor} />
                    }
                    {(type === "rack" || !type) &&
                        <rect x="0" y="0" width={selectedWidth} height={selectedWidth} rx="16" fill={fillColor} />
                    }
                    <text x="50%" y="50%" fill={fillTextColor} dominantBaseline="middle" textAnchor="middle" style={{ fontSize: "35px" }}>{seatText}</text>
                </svg>
            </div>
        </foreignObject>
    )
}

const ClassLayout = () => {
    const [loading, setLoading] = useState(true)
    const [loading1, setLoading1] = useState(false)
    const [error, setError] = useState(false)
    // const [data, setData] = useState()
    // console.log("data: ", data);
    const [stage, setStage] = useState()
    const [seats, setSeats] = useState()
    const [selectedIndex, setSelectedIndex] = useState()
    const [seatObject, setSeatObject] = useState({})
    const [details, setDetails] = useState({})
    const { getToken } = useContext(UserContext);
    const [refresh, setRefresh] = useState(false)
    const [booking, setBooking] = useState("")
    const [seatType, setSeatType] = useState()
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        getClassDetail();
    }, [refresh]);

    // api for grt class details
    const getClassDetail = async () => {
        setLoading(true)
        const id = searchParams.get("id");
        // const token = searchParams.get("token");
        const token = await getToken();
        const instance = new ClassController();
        const result = await instance.getClassDetails(id, token);

        const instance1 = new ProfileController();
        const userDetail = await instance1.getUserDetail(token);

        if (result.class?.attributes?.user_waiting === true) {
            let users = result.class?.attributes?.waitingUsers.filter(
                user => user.user_id === userDetail.user.id,
            );
            setBooking(users[0]);
        }

        setDetails(result.class)
        if (result?.class?.plan) {
            // const plan = allPlan;
            const plan = JSON.parse(result?.class?.plan?.description);

            const seats = result.class.seat_booking_status;
            const AllSeats = await mergeSeatPlans(plan.seat, seats, plan.stage);
            setSeats(AllSeats);
            const uniqueTypes = {};
            const filteredSeats = AllSeats.filter(seat => {
                if (!uniqueTypes[seat.type]) {
                    uniqueTypes[seat.type] = true;
                    return true;
                }
                return false;
            });
            setSeatType(filteredSeats);
        }
        if (result?.class?.attributes?.mine_booking === true) {
            setBooking(result.class.bookings[0])
        }
        setDetails(result.class);
        setLoading(false)
    };


    // api for Merge seat 
    const mergeSeatPlans = async (plan, seats, stage) => {
        setStage(stage);
        let AllSeats = [];
        plan.forEach((item, index) => {
            const num = parseInt(item.num);
            const seatItem = seats.filter((seat) => seat.seat === num);
            let updatedSeat = { ...seatItem[0], ...item };
            delete updatedSeat.num;
            AllSeats.push(updatedSeat)
        })
        return AllSeats;
    }


    // api for book class
    const handleBook = async (data) => {
        if (data?.seat_text) {
            setLoading1(true)
            // const data = { classes_id: details.id, type: details.priceType, seat: seatObject.num, device: "mobile" }
            if (details.attributes.mine_booking === false) {
                const token = await getToken();
                const instance = new ClassController();
                const result = await instance.bookClass(data, token);
                if (result.status === 'success') {
                    toast(result.msg);
                    setLoading1(false);
                    // navigate(-1);
                } else {
                    toast(result.msg);
                    setLoading1(false);
                }

                getClassDetail()
                setLoading1(false)
            } else {
                alert("you have already booked this class");
                setLoading1(false)
            }
        }
        else {
            toast("Please select seat.")
        }
    }

    // api for update seat now
    const handleUpdate = async () => {
        if (seatObject?.seat_text) {
            setLoading1(true)
            const data = { id: booking.id, seat: seatObject.seat, seat_text: seatObject.seat_text }
            const token = await getToken();
            const instance = new ClassController();
            const result = await instance.updateClass(data, token);
            if (result.status === 'success') {
                toast(result.msg);
                setLoading1(false);
                setRefresh(!refresh);
            } else {
                toast(result.msg);
                setLoading1(false);
            }
        }
        else {
            toast("Please select seat.");
        }
    }

    // api for cancel booking 
    const cancelBooking = async () => {
        setLoading1(true)
        const token = await getToken();
        const instance = new ClassController();
        const result = await instance.cancelClass(booking.id, token);
        if (result.status === 'success') {
            toast(result.msg);
            setLoading1(false)
            navigate(-1);
        } else {
            toast(result.msg);
            setLoading1(false)
        }
    }


    const joinWaiting = async (data) => {
        setLoading1(true)
        const token = await getToken();
        const instance = new ClassController();
        const result = await instance.joinWaitingClass(data, token);
        if (result.status === 'success') {
            toast(result.msg);
            setLoading1(false);
            navigate(-1);
        } else {
            toast(result.msg);
            setLoading1(false)
        }

    }

    // useEffect(() => {
    //     (async () => {
    //         // setLoading(true)
    //         const result = await phoneBookings(1)
    //         if (result.success) {
    //             setData(result.mobileLayoutObject)
    //             // setLoading(false)
    //         }
    //         else {
    //             setError(true)
    //             // setLoading(false)
    //         }
    //     })()
    // }, [])


    if (loading) {
        return (
            <ViewLayout>
                <Divider />
                {/* <SkeltonClassLayout /> */}
                <PageLoader />
            </ViewLayout>
        )
    }
    else if (error) {
        return (
            <>Error</>
        )
    }
    else {

        return (
            <>
                {loading1 === true &&
                    <PageLoader />
                }
                {details?.indoor === 1 ?
                    <ViewLayout>
                        <Divider />
                        <Box sx={{ height: "calc(100vh - 60px)", overflowY: "auto", width: "100%", display: "flex", justifyContent: "center", backgroundColor: "white", fontFamily: "Gotham-Book", }}>
                            <div className="scrollbarHide" style={{ backgroundColor: "white", width: "100%", height: "680px ", paddingInline: 40, overflow: "auto" }}>
                                <svg height="100%" width="100%" fill="white" overflow="auto" style={{ display: "block" }} preserveAspectRatio="xMinYMin meet" viewBox="0 0 1000 800">
                                    {details.trainer &&
                                        // <text x="50%" y="80" dominantBaseline="middle" textAnchor="middle" fill="black" style={{ fontSize: "40px", textTransform: "uppercase" }}>{details.trainer.first_name} | {details.theme_name ? details.theme_name + ' | ' : ''} {moment(details.start_date + ' ' + details.start_time).format('hh:mm A')} - {moment(details.start_date + ' ' + details.end_time).format('hh:mm A')}</text>
                                        <foreignObject x="0" y="30" width="100%" height="300">
                                            <div xmlns="http://www.w3.org/1999/xhtml" style={{ display: "flex", justifyContent: "center" }}>
                                                <span style={{ fontSize: "40px", textTransform: "uppercase", textAlign: "center" }}>{details.trainer.first_name} | {details.theme_name ? details.theme_name + ' | ' : ''} {moment(details.start_date + ' ' + details.start_time).format('hh:mm A')} - {moment(details.start_date + ' ' + details.end_time).format('hh:mm A')}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "center", columnGap: "40px", marginTop: "20px", marginBottom: "40px" }} xmlns="http://www.w3.org/1999/xhtml">
                                                <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
                                                    <span style={{ backgroundColor: "black", width: "50px", height: "50px", borderRadius: "16px" }}></span>
                                                    <span style={{ fontSize: "35px", textTransform: "uppercase" }}>Your {details.location.spot_name}</span>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
                                                    <span style={{ backgroundColor: "gray", width: "50px", height: "50px", borderRadius: "16px" }}></span>
                                                    <span style={{ fontSize: "35px", textTransform: "uppercase" }}>Booked</span>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
                                                    <span style={{ backgroundColor: "#EDEDED", width: "50px", height: "50px", borderRadius: "16px" }}></span>
                                                    <span style={{ fontSize: "35px", textTransform: "uppercase" }}>Available</span>
                                                </div>
                                            </div>
                                            {/* for rack class */}
                                            {stage === "bottom"
                                                &&
                                                <div style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "center", columnGap: "80px", overflow: "hidden", }}>
                                                    {seatType.map((item, index) =>
                                                        <div key={index} style={{ display: "flex", alignItems: "center", columnGap: "20px", }}>
                                                            <span style={{ backgroundColor: "black", width: "70px", height: "70px", borderRadius: item.type === "floor" ? "50%" : "16px", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <p style={{ color: "white", fontSize: "45px", fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{item.type[0]}</p>
                                                            </span>
                                                            <span style={{ fontSize: "45px", fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{item.type}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </foreignObject>
                                    }
                                    {details.trainer &&
                                        <>
                                            {stage === "right" &&
                                                <>
                                                    <svg x={650} y={650} width="275" height="900">
                                                        <rect x="2" width="600" height="900" rx="270" style={{ stroke: "black", strokeWidth: "2px" }} />
                                                    </svg>
                                                    <svg width="300" height="500" x={650} y={800}>
                                                        <defs>
                                                            <pattern id="image" x="0%" y="0%" height="100%" width="100%"
                                                                viewBox="0 0 512 512">
                                                                <image x="0%" y="0%" width="512" height="512" preserveAspectRatio="xMidYMid slice" xlinkHref={details.trainer.image}></image>
                                                            </pattern>
                                                        </defs>
                                                        <circle id="sd" className="medium" cx="50%" cy="50%" r="25%" fill="url(#image)" stroke="gray" strokeWidth="0.5%" />
                                                        <foreignObject x="0%" y="330" width="100%" height="300">
                                                            <p xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: "35px", textAlign: "center" }}>{details.trainer.first_name}</p>
                                                        </foreignObject>
                                                    </svg>
                                                </>
                                            }

                                            {stage === "bottom" &&
                                                <foreignObject x="0%" y="1620" width="100%" height="500">
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        {/* <svg width="900" height="275">
                                                            <rect y="4" width="900" height="600" rx="270" style={{ stroke: "black", strokeWidth: "2px" }} />
                                                        </svg> */}
                                                        <svg width="500" height="500" x={0} y={0}>
                                                            <defs>
                                                                <pattern id="image" x="0%" y="0%" height="100%" width="100%"
                                                                    viewBox="0 0 512 512">
                                                                    <image x="0%" y="0%" width="512" height="512" preserveAspectRatio="xMidYMid slice" xlinkHref={details.trainer.image}></image>
                                                                </pattern>
                                                            </defs>
                                                            <circle id="sd" className="medium" cx="50%" cy="25%" r="25%" fill="url(#image)" stroke="gray" strokeWidth="0.5%" />
                                                            <foreignObject x="0%" y="50%" width="100%" height="300">
                                                                <p xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: "35px", textAlign: "center" }}>{details.trainer.first_name}</p>
                                                            </foreignObject>
                                                        </svg>
                                                    </div>
                                                </foreignObject>
                                            }

                                            {stage === "center" &&
                                                <foreignObject x="0%" y="900" width="100%" height="500">
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        {/* <svg width="900" height="275">
                                                            <rect y="4" width="900" height="600" rx="270" style={{ stroke: "black", strokeWidth: "2px" }} />
                                                        </svg> */}
                                                        <svg width="500" height="500" x={0} y={0}>
                                                            <defs>
                                                                <pattern id="image" x="0%" y="0%" height="100%" width="100%"
                                                                    viewBox="0 0 512 512">
                                                                    <image x="0%" y="0%" width="512" height="512" preserveAspectRatio="xMidYMid slice" xlinkHref={details.trainer.image}></image>
                                                                </pattern>
                                                            </defs>
                                                            <circle id="sd" className="medium" cx="50%" cy="25%" r="25%" fill="url(#image)" stroke="gray" strokeWidth="0.5%" />
                                                            <foreignObject x="0%" y="50%" width="100%" height="300">
                                                                <p xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: "35px", textAlign: "center" }}>{details.trainer.first_name}</p>
                                                            </foreignObject>
                                                        </svg>
                                                    </div>
                                                </foreignObject>
                                            }
                                        </>
                                    }

                                    <g>
                                        {
                                            seats &&
                                            <>
                                                {
                                                    seats.map((item, index) => (
                                                        <Seat
                                                            key={index}
                                                            type={item.type}
                                                            stage={stage}
                                                            seatText={item.seatText ? item.seatText : item.seat}
                                                            xPos={item.x}
                                                            item={item}
                                                            yPos={item.y}
                                                            SeatNumber={item.seat}
                                                            Cname={item.name}
                                                            status={item.status}
                                                            itemIndex={index}
                                                            // data={data}
                                                            setSelectedIndex={setSelectedIndex}
                                                            selectedIndex={selectedIndex}
                                                            setSeatObject={setSeatObject}
                                                        />
                                                    ))
                                                }
                                            </>
                                        }
                                    </g>
                                </svg>
                            </div >
                        </Box>
                        {/* {loading && <PageLoader />} */}
                        <ToastContainer />
                    </ViewLayout>
                    :
                    <OutdoorClassDetail item={details} />
                }
                <Box sx={{ position: "fixed", left: 0, right: 0, bottom: 0, marginInline: "auto", width: { xs: "100%", md: "400px" }, py: 2, backgroundColor: "#fff" }}>
                    <BookingLayoutFooter
                        handleBook={handleBook}
                        handleUpdate={handleUpdate}
                        cancelBooking={cancelBooking}
                        joinWaiting={joinWaiting}
                        seatData={seatObject}
                        userData={details}
                        booking={booking}
                        loading1={loading1}
                        setLoading1={setLoading1}
                    />
                </Box>

            </>

        )
    }
}


export default ClassLayout