import React from 'react';
import Box from '@mui/material/Box';
import { Divider, IconButton } from '@mui/material';
// import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useNavigate, useLocation, Link } from 'react-router-dom';
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useState } from 'react';

// import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NavItem from './navItem';
import NotificationModel from './notificationModel';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { useEffect } from 'react';
import { NotificationController } from '../../controllers/notificationsController';
import ConfirmModal from '../modal/confirmModal';
export default function Header() {
    const navigate = useNavigate()
    const location = useLocation()
    const [state, setState] = useState(false);
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = useState(false);
    const [notificationData, setNotificationData] = useState()
    const [count, setCount] = useState()

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState(open);
    };

    // notification section
    const OpenNotification = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setNotification(open);
    };
    const { getToken } = useContext(UserContext);

    useEffect(() => {
        getNotification()
    }, [])

    const getNotification = async () => {
        const token = getToken();
        const instance = new NotificationController()
        const result = await instance.getAllNotification(token)
        setNotificationData(result.data)
        setCount(result.count)
    }

    const clearNotification = () => {
        setOpen(true)
    }

    const ClearConfirm = async () => {
        const token = getToken();
        const instance = new NotificationController()
        const result = await instance.deleteNotification(token)
        if (result.status === 'success') {
            getNotification()
            setOpen(false)
        }
        else {
            setOpen(false)
        }
    }

    const viewNotification = async (key) => {
        // const token = getToken();
        // const instance = new NotificationController()
        // const result = await instance.readNotification(token);
    }



    return (
        <>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
                alignItems: "center",
                backgroundColor: "white",
            }}>
                <IconButton onClick={toggleDrawer(true)}>
                    <img src="/images/icons/Hamburger_icon.png" style={{ width: 20, height: 20 }} alt='menu icon' />
                </IconButton>
                <SwipeableDrawer
                    sx={{ ".MuiPaper-root": { width: { xs: "100%", md: "400px" } } }}
                    anchor="left"
                    open={state}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >

                    {/* menu item hare */}
                    <NavItem toggleDrawer={toggleDrawer(false)} />
                </SwipeableDrawer>
                <Link to='/'>
                    <Box sx={{ display: "flex" }}>
                        <img src="/images/logos/veloBlack.svg" alt='' height="25px" />
                    </Box>
                </Link>
                {location.pathname === "/profile" ?
                    <IconButton onClick={() => navigate('/profile-edit')}>
                        <img src="/images/icons/edit.png" style={{ width: 20, height: 20 }} alt='edit icon' />
                    </IconButton>
                    :
                    <>
                        <IconButton onClick={OpenNotification(true)}>
                            <img src="/images/icons/bell.png" style={{ width: 20, height: 20 }} alt='menu icon' />
                        </IconButton>
                        <SwipeableDrawer
                            sx={{ ".MuiPaper-root": { width: { xs: "100%", md: "400px" } } }}
                            anchor="right"
                            open={notification}
                            onClose={OpenNotification(false)}
                            onOpen={OpenNotification(true)}
                        >
                            <NotificationModel
                                notificationData={notificationData}
                                OpenNotification={OpenNotification(false)}
                                clearNotification={clearNotification}
                                viewNotification={viewNotification}
                            />
                            <ConfirmModal
                                open={open}
                                setOpen={setOpen}
                                title={"You want to leave this class"}
                                handleWalletPay={ClearConfirm}
                            />
                        </SwipeableDrawer>
                    </>
                }
            </Box>
            <Divider />
        </>
    )
}
