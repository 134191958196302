import { createContext, useEffect, useState } from 'react';
import { parseCookies, setCookie, destroyCookie } from 'nookies'
import { authState } from '../recoil/atoms/authState';
import { useSetRecoilState } from 'recoil';


export const UserContext = createContext();

export default function UserProvider({ children }) {
  const [auth, setUserAuth] = useState();
  const setUserRecoilState =  useSetRecoilState(authState)

  // useEffect(() => {
  //   checkAuth();
  // }, []);

  useEffect(() => { }, [auth]);

  // const checkAuth = async () => {
  //   const value = await getAuth();
  //   if (value != null) {
  //     console.log('checkAuth', value);
  //     setUserAuth(value);
  //   }
  // };

  const getUser = async () => {
    try {
      const value = await localStorage.getItem('user');
      if (value !== null) {
        return JSON.parse(value);
      }
    } catch (e) {
      console.log('error in user context setUser', e);
      return e;
      // error reading value
    }
  };

  const setUser = async user => {
    try {
      const jsonValue = JSON.stringify(user);
      await setCookie(null, 'user', jsonValue, {
        maxAge: 30 * 24 * 60 * 60, 
        path: '/',
      });
      console.log("user:", jsonValue)

      setUserAuth(true);
    } catch (e) {
      console.log('error in user context setUser', e);
    }
  };

  const setAuth = value => {
    setUserRecoilState(value)
    setCookie(null, 'user', value, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    })
  };

  const getToken = () => {
    const cookies = parseCookies()
    return cookies.access_token

  };

  const setToken = (token) => {

    setCookie(null, 'access_token', token, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    })
  };


  // const getAuth = async () => {
  //   const value = await localStorage.getItem('auth');
  //   return JSON.parse(value);
  // };


  // const signOut = async () => {
  //   await localStorage.removeItem('auth');
  //   await localStorage.removeItem('user');
  //   await localStorage.clear();
  //   setUserAuth(false);
  // };

  return (
    <UserContext.Provider
      value={{
        setUser,
         getUser,
        setAuth,
        // getAuth,
        setToken,
        getToken,
        auth,
        // signOut,
      }}>
      {children}
    </UserContext.Provider>
  );
}

export const UserConsumer = UserContext.Consumer;
