import React from 'react'
import { FormControl } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'

export default function ThemeInput({ type, endAdornment, variant, label, shrink, onChange, value, readOnly }) {
    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
    return (
        <FormControl fullWidth>
            <InputLabel sx={{ fontSize: "14px", fontFamily: "Gotham-Book" }} shrink={shrink}>{label}</InputLabel>
            <Input
                sx={{
                    fontSize: "18px",
                    "& .MuiFilledInput-root": {
                        background: "rgb(232, 241, 250)"
                    },
                    fontFamily: "Gotham-light",
                    fontWeight: 600
                }}
                inputProps={{ style: inputStyle }}
                id="standard-basic"
                onChange={onChange}
                type={type}
                value={value}
                variant={variant ? variant : "standard"}
                endAdornment={endAdornment}
                readOnly={readOnly ? readOnly : false}
            />
        </FormControl>
    )
}
