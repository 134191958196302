import React from 'react'
import ViewLayout from '../../layout/viewLayout'
import { Typography, Box, IconButton, Avatar } from '@mui/material'
import { useState, useEffect, useContext } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ThemeInput from '../../components/shared-layout/Input';
import { UserContext } from '../../context/UserContext'
import { RoundedDarkButton, RoundedLightButton } from '../../components/shared-layout/buttons/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageLoader from '../../components/pageLoader'
import { ProfileController } from '../../controllers/ProfileController';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { API_SUCCESS } from '../../config/ApiConfig';
import TextField from '@mui/material/TextField';

const EditProfile = () => {
    const [image, setImage] = useState({ file: '', url: "" });
    const [email, setEmail] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [loading, setLoading] = useState(false);
    const { getToken } = useContext(UserContext);
    const [user, setUser] = useState({});
    const userCtx = useContext(UserContext);
    const navigate = useNavigate()


    useEffect(() => {
        if (image.file) {
            setImage({ ...image, url: URL.createObjectURL(image.file) });
        }
    }, [image.file]);

    useEffect(() => {
        getDetail();
    }, []);

    const getDetail = async () => {
        setLoading(true)
        const token = await getToken();
        const instance = new ProfileController();
        const result = await instance.getUserDetail(token);
        console.log(result, 'result');
        setLoading(false)
        setUser(result.user);
        setImage({ url: API_SUCCESS + '/' + result.user.image });
        setFirstName(result.user.first_name);
        setLastName(result.user.last_name);
        setEmail(result.user.email);
        setPhone(result.user.phone);
        setDob(result.user.dob);
        setLoading(false);
    };

    const submit = async () => {
        if (first_name !== '' && last_name !== '' && email !== '' && phone !== '') {
            setLoading(true);
            const data = {
                ...user,
                image: image.file,
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone,
                dob: moment(dob).format('YYYY-MM-DD'),
            };
            console.log(data, 'data');
            const token = await getToken();
            const instance = new ProfileController();
            const result = await instance.updateProfile(data, token);
            console.log(result, 'result');
            if (result.status === 'success') {
                toast(result.message);
                userCtx.setUser({ ...user.data, ...data });
                setLoading(false);
                navigate(-1)
            } else {
                var errors = result.errors;
                var value = '';
                if (errors.phone) {
                    var value = errors.phone + ' ,';
                    toast(value);
                }
                else if (errors.email) {
                    value = value + errors.email;
                    toast(value);
                }
                else if (errors.dob) {
                    value = value + errors.dob;
                    toast(value);
                }

                setLoading(false);

            }

        } else {
            toast.show('Please fill all details');
        }
    };


    return (
        <>
            {loading === true &&
                <PageLoader />
            }
            <ViewLayout>
                <Box sx={{ px: 1.5, pt: 1.5, pb: 4 }}>
                    <Typography variant='button' sx={{ fontFamily: "Gotham-Book" }}>Edit profile</Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", width: "120px", justifyContent: 'center', alignItems: "center", mt: 4, position: "relative" }}>
                            <Box sx={{ overflow: "hidden", borderRadius: "50%", width: "120px", height: "120px" }}>
                                <Avatar src={image.url} sx={{ width: "100%", height: "100%" }} />
                            </Box>
                            <input
                                accept="image/*"
                                type="file"
                                id="select-image"
                                style={{ display: "none" }}
                                onChange={(e) => setImage({ ...image, file: e.target.files[0] })}
                            />
                            <label
                                style={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    borderRadius: "50%",
                                    backgroundColor: "black",
                                    border: "2px solid white",
                                    ":hover": { backgroundColor: "black" }
                                }}
                                htmlFor="select-image">
                                <IconButton sx={{ color: "white" }} component="span">
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            </label>
                        </Box>
                    </Box>

                    <Box sx={{ px: 2, mt: 4 }}>
                        <Box mb={3}>
                            <ThemeInput
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                label={"First Name"} type={"text"} />
                        </Box>
                        <Box mb={3}>
                            <ThemeInput
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                label={"Last Name"}
                                type={"text"} />
                        </Box>
                        <Box mb={3}>
                            <ThemeInput
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                label={"Email"}
                                readOnly={true}
                                type={"email"} />
                        </Box>
                        <Box mb={3}>
                            <ThemeInput
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                label={"Contact Number"}
                                type={"number"} />
                        </Box>
                    </Box>

                    <Box sx={{ px: 2, mt: 5 }} >
                        <Box mb={3} mt={2} sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <RoundedLightButton style={{ width: "120px", height: "35px", fontFamily: "Gotham-Book", ":hover": { backgroundColor: "#b9b2b2" } }} >Cancel</RoundedLightButton>
                            <RoundedDarkButton style={{ width: "120px", fontFamily: "Gotham-Book", height: "35px" }} onClick={submit}  >Save</RoundedDarkButton>
                        </Box>
                    </Box>
                </Box>
            </ViewLayout>
            <ToastContainer />
            {loading &&
                <PageLoader />
            }
        </>
    )
}

export default EditProfile