import React, { PureComponent } from 'react';
// import {StyleSheet, View} from 'react-native';
import Date from './Date';
import { Box } from '@mui/material';

export default class Dates extends PureComponent {
  props: {
    currentDateIndex: ?number,
    dates: Array<Moment>,
    onSelectDay: (index: number) => void,
    onRenderDay: (index: number, width: number) => void,
  };

  render() {
    const { currentDateIndex, dates, onSelectDay, onRenderDay } = this.props;
    return (
      <Box style={{
        flexDirection: 'row',
        display: 'flex',
      }}>
        {dates.map((date, index) => (
          <Box key={index} >
            <Date
              date={date}
              index={index}
              isActive={index === currentDateIndex}
              onClick={onSelectDay}
              onRender={onRenderDay}
              key={index}
            />
          </Box>
        ))}
      </Box>
    );
  }
}
