import React, { useContext } from 'react'
import { useState } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Box, TextField, Typography } from '@mui/material'
import { RoundedDarkButton, ThemeDarkButton } from '../../components/shared-layout/buttons/Button'
import PageLoader from '../../components/pageLoader'
import { UserConsumer } from '../../context/UserContext'
import { WalletController } from '../../controllers/WalletController'
import { toast } from "react-toastify"
// import { useNavigate } from 'react-router-dom'


const AddAmount = () => {

    // const navigate = useNavigate()

    const [amount, setAmount] = useState("")
    const [payModal, setPayModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { getToken } = useContext(UserConsumer);

    const handleChange = (event) => {
        const value = event.target.value;
        if (value === "" || !isNaN(value)) {
            const number = Number(value);
            if (number <= 10000) {
                setAmount(value);
            }
        }
    }

    const checkout = () => {
        if (amount > 1) {
            setPayModal(true)
        }
    }


    const payNow = val => {
        if (val === 'debit') {
            completePaymentDebit('VISA');
        } else if (val === 'gpay') {
            completePaymentDebit('Gpay');
        }
    };

    const completePaymentDebit = async type => {
        const token = await getToken();
        setLoading(true);
        const instance = new WalletController();
        const result = await instance.executePayment(amount, type, token);
        if (result.IsSuccess === true) {
            if (type === 'Wallet') {
                toast.success(result.Message)
            } else {
                console.log(result.Data.PaymentURL);
                window.location.href = result.Data.PaymentURL;
            }
        } else {
            toast(result.Message);
            setLoading(false);
        }
    };

    return (
        <>
            {loading === true && <PageLoader />}
            {payModal === false ?
                <MainLayout>
                    <Box sx={{ px: 4, pt: 1.5, pb: 4 }}>
                        <Typography variant='button' sx={{ fontFamily: "Gotham-Book" }}>AMOUNT TO ADD</Typography>
                        <Box sx={{ mt: 4, }}>
                            <TextField type='number' sx={{
                                width: "100%",
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid black',
                                    borderRadius: "15px"
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "black",
                                    borderWidth: "2px"
                                }
                            }} value={amount} onChange={(event) => handleChange(event)} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                            <RoundedDarkButton onClick={() => checkout()} style={{ width: "220px", height: "35px" }} >CHECK OUT</RoundedDarkButton>
                        </Box>
                    </Box>
                </MainLayout>
                :
                <MainLayout>
                    <Box sx={{
                        height: "calc(100vh - 60px)",
                        position: "relative",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        flexDirection: "column",
                        p: 6,
                        alignItems: "center",
                    }}>
                        <Typography variant='a' sx={{ position: 'absolute', left: '15px' }} onClick={() => setPayModal(false)}>
                            <img alt='' src="images/icons/back.png" style={{ width: 16, height: 16, marginRight: 10 }} />
                        </Typography>
                        <Typography sx={{ fontSize: "18px", fontFamily: "Gotham-Medium", mb: 8 }}>PAYMENT METHOD</Typography>
                        <Box sx={{ display: "flex", width: "100%", flexDirection: "column", rowGap: 2 }}>

                            <ThemeDarkButton onClick={() => payNow('gpay')} style={{ height: "50px", width: "100%" }}>
                                <img src="images/icons/google.png" alt='' style={{ width: 24, height: 24, marginRight: 10 }} />
                                <Typography sx={{ fontSize: "17px", fontFamily: "Gotham-Medium" }}>
                                    PAY
                                </Typography>
                            </ThemeDarkButton>

                            <ThemeDarkButton onClick={() => payNow('debit')} style={{ height: "50px", width: "100%" }}>
                                <Box>
                                    <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>PAY WITH DEBIT/ CREDIT CARD</Typography>
                                </Box>
                            </ThemeDarkButton>
                        </Box>

                    </Box>
                </MainLayout>
            }
        </>
    )
}

export default AddAmount