import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Typography } from '@mui/material';


const drawerBleeding = 0;

function ModalLayout({ children, openModal, title, setCancel }) {


    return (
        <>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        // height: `calc(50% - ${drawerBleeding}px)`,
                        // height: "250px",
                        overflow: 'visible',
                    },
                }}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={openModal}
                onClose={
                    setCancel(false)
                }
                onOpen={setCancel(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "30px 30px 0px 0px",
                        p: 6
                    }
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 3, justifyContent: "center", height: "100%" }}>
                    <Typography sx={{ fontSize: "18px", fontFamily: "Gotham-Medium", fontWeight: 600 }}>{title}</Typography>

                    {children}
                </Box>
            </SwipeableDrawer>
        </>
    );
}

ModalLayout.propTypes = {
    window: PropTypes.func,
};

export default ModalLayout;
