import React from 'react'
import { Card, Box, Typography } from '@mui/material'
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { BuyController } from '../../controllers/BuyController';
import PageLoader from '../../components/pageLoader';

const MyPackages = () => {
    const [userPackage, setUserPackage] = useState([])
    const [loading, setLoading] = useState(false)
    const [height, setHeight] = useState(null)
    const { getToken } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            setLoading(true)
            const token = getToken();
            console.log("token: ", token);
            const instance = new BuyController();
            const result = await instance.getUserPackages(token);
            setUserPackage(result)
            setLoading(false)
        })();
    }, []);

    return (

        <Box sx={{
            height: `calc(100vh - (92px + ${height}px))`,
            maxHeight: "100%",
            overflowY: "auto",
            '&::-webkit-scrollbar': { width: 0 }
        }}>
            {loading === true && <PageLoader /> }

            {(userPackage && userPackage.length > 0) ?
            <>
            {(userPackage.map((item, index) =>
                <Box key={index} sx={{ px: 1.5, pt: 0.5, }}>
                    <Card sx={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", height: "150px", width: "100%", borderRadius: "30px", marginTop: "10px", backgroundColor: "black", color: "white", boxShadow: "5px 4px 15px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);" }}>
                        <Box sx={{ width: "100%", px: 2, }}>
                            <Box sx={{ marginInline: "25px", mb: 1.5 }}>
                                <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "18px", textTransform: "uppercase" }}>{item.attributes.name}</Typography>
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Box sx={{ display: "flex", marginInline: "25px", flexDirection: "column" }}>
                                    <Typography sx={{ fontFamily: "Gotham-Light", fontSize: "14px" }}>EXPIRES</Typography>
                                    <Typography sx={{ fontFamily: "Gotham-Light", lineHeight: 2.5, fontSize: "14px" }}>REMAINING</Typography>
                                </Box>

                                <Box sx={{ display: "flex", marginInline: "25px", flexDirection: "column" }}>
                                    <Typography sx={{ fontFamily: "Gotham-Light", fontSize: "14px" }}><span style={{ marginRight: 10 }}>:</span>{item.attributes.expired === false ? "---" : item.attributes.expired}</Typography>
                                    <Typography sx={{ fontFamily: "Gotham-Light", lineHeight: 2.5, fontSize: "14px", textTransform: "uppercase" }}><span style={{ marginRight: 10 }}>:</span>{item.attributes.remaining_rides}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>

            ))}
            </>
            :
            <Box sx={{ px: 1.5, pt: 0.5,textAlign:'center',mt:'50%' }}>
                <Typography sx={{fontSize:'16px',fontFamily:'Gotham-Book'}}>No package available.</Typography>
            </Box>
            }
        </Box>
    )
}

export default MyPackages