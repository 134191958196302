import { API_BASE } from "../config/ApiConfig"
export class ClassController {

  async getHomeLocation(token) {
    return fetch(API_BASE + '/location', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        return { success: false, error };
      });
  }

  async getAllLocation(token) {
    return fetch(API_BASE + '/locations/get', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        return { success: false, error };
      });
  }

  async getClassDetails(id, token) {
    return fetch(API_BASE + '/classes/' + id + '/details', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        return { success: false, error };
      });
  }

  async bookClass(data, token) {
    const newData = new FormData();
    newData.append('classes_id', data.classes_id);
    newData.append('type', data.type);
    newData.append('seat', data.seat);
    newData.append('seat_text', data.seat_text);
    newData.append('device', data.device);
    if (data.package_id) {
      newData.append('package_id', data.package_id);
    }

    return fetch(API_BASE + '/booking/complete', {
      method: 'POST',
      body: newData,
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        return { success: false, error };
      });
  }

  async joinWaitingClass(data, token) {
    const newData = new FormData();
    newData.append('classes_id', data.classes_id);
    newData.append('type', data.type);
    newData.append('device', data.device);
    if (data.package_id) {
      newData.append('package_id', data.package_id);
    }

    return fetch(API_BASE + '/booking/waiting', {
      method: 'POST',
      body: newData,
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        return { success: false, error };
      });
  }

  async updateClass(data, token) {
    const newData = new FormData();
    newData.append('booking_id', data.id);
    newData.append('seat', data.seat);
    newData.append('seat_text', data.seat_text);
    return fetch(API_BASE + '/booking/seat/update', {
      method: 'POST',
      body: newData,
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        return { success: false, error };
      });
  }

  async cancelClass(data, token) {
    const newData = new FormData();
    newData.append('booking_id', data);
    return fetch(API_BASE + '/booking/cancel', {
      method: 'POST',
      body: newData,
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        return { success: false, error };
      });
  }
}

