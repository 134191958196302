import React from 'react'
import ViewLayout from '../../../layout/viewLayout'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { RoundedDarkButton } from '../../../components/shared-layout/buttons/Button'

export default function OutdoorClassDetail({ item }) {

    console.log(item, 'item')
    return (
        <ViewLayout>
            <Box mx={2} mt={2} >
                <Typography variant='caption' mb={5}>{moment(item.start_date + ' ' + item.start_time).format('hh:mm A',)} - {moment(item.start_date + ' ' + item.end_time).format('hh:mm A',)}</Typography>
                <Typography mb={2} variant='h6' sx={{ textTransform: 'uppercase', fontFamily: 'Gotham-Medium' }}>{item.title} | {item.theme_name}</Typography>
                <Box>
                    <Box sx={{ width: "100%", height: "170px", background: item.image ? `url(${item.image})` : "black", backgroundRepeat: "no-repeat", backgroundSize: "cover", position: "relative" }}>
                        <Box component={"span"} sx={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            bottom: "-50px",
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <img src={item?.trainer?.image} alt='' width="100px" height="100px" style={{ borderRadius: "50%", overflow: "hidden", objectFit: "cover" }} />
                        </Box>
                    </Box>
                    <Box sx={{ mt: "65px", mb: 3 }}>
                        <Typography variant='h6' sx={{ textAlign: "center", fontFamily: 'Gotham-Medium' }}>
                            {item?.trainer?.first_name} {item?.trainer.last_name}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ textAlign: "center", mb: 3, "& .MuiTypography-root": { fontSize: "10px" } }}>
                    <Typography>CANCELLATION MUST BE MADE 3 HOURS BEFORE THE RIDE</Typography>
                    <Typography> BEGINS TO AVOID PENALTY</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {
                        (item.attributes.user_waiting === false &&
                            item.attributes.mine_booking === false &&
                            item.attributes.booking_count_status.waiting_available !== 0 &&
                            item.attributes.booking_count_status.available === 0) ?
                            <Typography sx={{ textAlign: "center", mb: 2, fontFamily: 'Gotham-Medium' }} variant='button'>WAITING LIST : {item.attributes.booking_count_status.waiting}/{item.attributes.booking_count_status.waiting_available}</Typography>
                            :
                            <Typography sx={{ textAlign: "center", mb: 2, fontFamily: 'Gotham-Medium' }} variant='button'>SPOTS AVAILABLE : {item.attributes.booking_count_status.available}/{item.seats}</Typography>

                    }
                </Box>
                {/* <Box sx={{ display: "flex", justifyContent: "center", }}>
                    <RoundedDarkButton style={{width:"auto",px:2}}>BOOK NOW</RoundedDarkButton>
                </Box> */}
            </Box>

        </ViewLayout>
    )
}
