import React, { lazy, useContext, useEffect, useState } from 'react'
import ViewLayout from '../../layout/viewLayout'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { CustomCard } from '../../components/shared-layout/card'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button'
import { RoundedDarkButton } from '../../components/shared-layout/buttons/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_SUCCESS } from '../../config/ApiConfig';
import { UserContext } from '../../context/UserContext';
import { HappeningContoller } from '../../controllers/HappeningController';
import moment from 'moment';
import { toast } from 'react-toastify';

export default function HappeningDetail() {

    const navigate = useNavigate()
    const [item, setItem] = useState();
    const { getToken } = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getHappeningDetail()
    }, [])


    const getHappeningDetail = async () => {
        const token = await getToken();
        const id = searchParams.get("id");
        const instance = new HappeningContoller();
        const result = await instance.getChallenges(id, token);
        setItem(result?.happening);
    };

    const acceptChallenge = async () => {
        setLoading(true);
        const id = searchParams.get("id");
        const token = await getToken();
        const instance = new HappeningContoller();
        const result = await instance.addChallenges(id, token);
        setLoading(false);
        if (result.status === 'success') {
            toast(result.msg);
            navigate('/happening-report?id=' + id);
        }
    };

    return (
        <ViewLayout title="" backgroundColor="#fff">
            <Box sx={{ height: "calc(100vh - 60px)", overflowY: "hidden", position: "relative", backgroundColor: "#e2e3e5" }}>
                <Box sx={{ height: "30%" }}>
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <img src={API_SUCCESS + '/' + item?.image} alt='' width={"100%"} height={"100%"} />
                    </Box>
                </Box>
                <Box sx={{ height: "calc(70% + 35px)", backgroundColor: "#ffffff", mt: "-35px", position: "relative", zIndex: 2, borderRadius: "40px 40px 0px 0px" }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        pt: 3
                    }}>
                        <Box sx={{
                            height: "25%",
                            p: 4,
                            rowGap: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                "& .MuiTypography-root": { fontFamily: "Gotham-Medium", fontSize: "20px", fontWeight: 700 }
                            }}>
                                <Box>
                                    <Typography sx={{ textTransform: 'uppercase' }}>{item?.title}</Typography>
                                    <Typography variant='button' sx={{ fontSize: "12px !important", fontFamily: "Gotham-Light !important", textTransform: 'uppercase' }}>Start from</Typography>
                                    <Typography sx={{ textTransform: 'uppercase', fontSize: 12 }}>{moment(item?.start_date).format('DD MMM, YYYY')} -{' '}
                                        {moment(item?.end_date).format('DD MMM, YYYY')}</Typography>
                                </Box>
                            </Box>
                            <Typography sx={{ fontSize: "12px", textAlign: "initial", fontFamily: "Gotham-Medium !important" }}>
                                {item?.description}
                            </Typography>
                            <RoundedDarkButton onClick={() => acceptChallenge()} style={{ fontFamily: "Gotham-Light" }}>
                                ENROLL NOW
                            </RoundedDarkButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ViewLayout>
    )
}
