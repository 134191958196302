import React from 'react'
import ViewLayout from '../../../layout/viewLayout'
import { Box } from '@mui/system'
import { useEffect, useContext, useState } from 'react'
import { GroveController } from '../../../controllers/GroveController'
import { UserContext } from '../../../context/UserContext'
import { toast } from 'react-toastify'
import PageLoader from '../../../components/pageLoader'
import { Chip, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment'

export default function DoubleJoyOrder() {
    const { getToken } = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const [expend, setExpend] = useState("")
    const [order, setOrder] = useState()
    console.log("order: ", order);

    const getColor = status => {
        if (status === 'New') {
            return '#000';
        } else if (status === 'Inprogress') {
            return '#ffc107';
        } else if (status === 'Ready') {
            return '#06ba0e';
        } else if (status === 'Picked') {
            return '#ffff';
        }
    };

    const MyOrder = async () => {
        setLoading(true)
        const token = getToken()
        const instance = new GroveController()
        const result = await instance.getDoubleJoyOrder(token)
        console.log("result: ", result);
        if (result.success === true) {
            setOrder(result.data.data);
            setLoading(false)
        }
        else {
            setLoading(false)
            toast.error(result.msg)
        }
    }

    useEffect(() => {
        MyOrder()
    }, [])

    const HandleExpend = (id) => {
        setExpend(id);
    }
    return (
        <ViewLayout title={"MY ORDER"}>
            <Box sx={{ height: "calc(100vh - 90px)", position: "relative", pt: 4 }} mx={2} >
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                    {order && order.map((item, i) =>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 1,
                            px: 2,
                            py: 1,
                            borderRadius: "20px",
                            overflow: "hidden",
                            backgroundColor: "#f2f2f2",
                        }}>
                            <Box key={i} sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
                                    <Typography sx={{
                                        fontSize: 14,
                                        textTransform: 'uppercase',
                                        fontFamily: 'Gotham-Medium',
                                        color: '#000',
                                    }}>{moment(item.attributes.date).format('DD MMM, YYYY HH:MM A')}</Typography>
                                    <Chip onClick={() => HandleExpend(i)} sx={{ cursor: "pointer", width: "100px" }} size="small" color='primary' label={`item's (${item.attributes.items.length})`} />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, alignItems: "center" }}>
                                    <Typography sx={{
                                        fontSize: "14px",
                                        textTransform: 'uppercase',
                                        fontFamily: 'Gotham-Medium',
                                        color: '#000',
                                    }}>{item.attributes.items_total} QAR</Typography>
                                    <Chip
                                        size='small'
                                        sx={{
                                            backgroundColor: getColor(item.attributes.status),
                                            color: item.attributes.status === 'New' ? '#fff' : '#000',
                                            paddingHorizontal: 10,
                                            fontWeight: '700',
                                        }} label={item.attributes.status} />
                                </Box>
                            </Box>
                            <Box>
                                {expend === i &&
                                    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 0.5 , }}>
                                        {item.attributes.items.map((item1, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                }}>
                                                <Box component={"span"} sx={{
                                                    fontSize: 12,
                                                    fontFamily: 'Gotham-Medium',
                                                }}>{item1.optional_item.name}</Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    columnGap: 3,
                                                }}>
                                                    <Box component={"span"} sx={{
                                                        fontSize: 12,
                                                        fontFamily: 'Gotham-Medium',
                                                    }}>{item1.optional_item.price / item1.quantity} x {item1.quantity}
                                                    </Box>
                                                    <Box component={"span"} sx={{
                                                        fontSize: 12,
                                                        fontFamily: 'Gotham-Medium',
                                                    }}>{item1.optional_item.price} {item1.optional_item.currency}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>}
                            </Box>
                            <Box>
                                <Typography sx={{
                                    fontSize: "12px",
                                    textTransform: 'uppercase',
                                    fontFamily: 'Gotham-Black',
                                    color: '#000',
                                }}>{item.attributes.order_ref}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            {loading && <PageLoader />}
        </ViewLayout>
    )
}
