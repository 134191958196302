import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ThemeDarkButton } from '../../components/shared-layout/buttons/Button'
import { deviceDetect, mobileModel } from "react-device-detect"



export default function PaymentMethod({ payNow }) {
    const [device, setDevice] = useState("")
    console.log("device: ", device);

    useEffect(() => {
        setDevice(mobileModel)
        console.log("deviceData deviceDetect", deviceDetect())
    }, [])

    return (
        <>
            <Box sx={{
                height: "calc(100vh - 60px)",
                position: "relative",
                backgroundColor: "#ffffff",
                display: "flex",
                flexDirection: "column",
                p: 6,
                alignItems: "center",
            }}>
                <Typography sx={{ fontSize: "18px", fontFamily: "Gotham-Medium", mb: 8 }}>PAYMENT METHOD</Typography>
                <Box sx={{ display: "flex", width: "100%", flexDirection: "column", rowGap: 2 }}>

                    <ThemeDarkButton onClick={() => payNow('gpay')} style={{ height: "50px", width: "100%" }}>
                        <img alt='' src="images/icons/google.png" style={{ width: 24, height: 24, marginRight: 10 }} />
                        <Typography sx={{ fontSize: "17px", fontFamily: "Gotham-Medium" }}>
                            PAY
                        </Typography>
                    </ThemeDarkButton>

                    {device && device === "iPhone" &&
                            <ThemeDarkButton onClick={() => payNow('apple')} style={{ height: "50px", width: "100%" }}>
                                <img src="images/icons/apple.png" style={{ width: 24, height: 24, marginRight: 10, filter: "invert(1)" }} alt='' />
                                <Typography sx={{ fontSize: "17px", fontFamily: "Gotham-Medium" }}>
                                    PAY
                                </Typography>
                            </ThemeDarkButton>
                        }

                    <ThemeDarkButton onClick={() => payNow('wallet')} style={{ height: "50px", width: "100%" }}>
                        <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "17px" }}>WALLET</Typography>
                    </ThemeDarkButton>

                    <ThemeDarkButton onClick={() => payNow('debit')} style={{ height: "50px", width: "100%" }}>
                        <Box>
                            <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Medium" }}>PAY WITH DEBIT/ CREDIT CARD</Typography>
                        </Box>
                    </ThemeDarkButton>
                </Box>

            </Box>
        </>
    )
}
