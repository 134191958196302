import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import ThemeInput from '../../components/shared-layout/Input'
import { ThemeDarkButton } from '../../components/shared-layout/buttons/Button'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import { AuthContoller } from '../../controllers/AuthController'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageLoader from '../../components/pageLoader'

export default function ForgotPassword() {

    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        if (loading === false) {
            if (email !== '') {
                let reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

                if (reg.test(email) === false) {
                    toast('Email is Not Correct');
                    return false;
                } else {
                    setLoading(true);
                    const instance = new AuthContoller();
                    const result = await instance.forgotPassword(email);
                        console.log(result.data.message,'result.data.message')
                    if (result.data.status === 'success') {
                        toast(result.data.message);
                        setTimeout(() => {
                            navigate('/login');
                            setLoading(false)
                        },1000)
                        
                    } else {
                        toast(result.data.message);
                        setLoading(false)
                    }
                }
            } else {
                toast('please enter email.and password');
            }
        }
    };

    return (
        <>
            <Box sx={{
                minHeight: "100vh",
                maxHeight: "100%",
                width: { md: "400px", xs: "100%" },
                marginX: "auto",
                overflow: "hidden",
                borderInline: "1px solid lightgray",
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    // height: "100%",
                    alignItems: "center",
                    backgroundColor: "white",
                    height: "60px"
                }}>
                    <img src="/images/logos/veloBlack.svg" alt='' />
                </Box>
                <Divider />
                <Box sx={{ height: "calc(100vh - 60px)" }}>
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                    }}>
                        <Box sx={{ pt: 2, px: 5 }}>
                            <Typography sx={{ fontSize: "20px", fontFamily: "Gotham-Book", textTransform: "uppercase" }}>forget password</Typography>
                        </Box>
                        <Box sx={{ px: 5 }}>
                            <Box mb={4}>
                                <ThemeInput
                                    label={"E-MAIL ADDRESS"}
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Box>
                            <Box mb={3}>
                                <ThemeDarkButton style={{ fontSize: "15px", fontWeight: 100, fontFamily: "Gotham-Light", letterSpacing: "1px" }} onClick={submit} >Send to Email</ThemeDarkButton>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Link onClick={() => navigate('/login')} sx={{ fontSize: "12px", fontFamily: "Gotham-Light", textTransform: "uppercase", textDecoration: "none" }}>Back to Login</Link>
                            </Box>
                        </Box>
                        <Box sx={{ pb: 10, px: 5, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography sx={{ fontSize: "12px", fontFamily: "Gotham-Light", mb: 1 }}>DON’T HAVE AN ACCOUNT?</Typography>
                            <Link sx={{ fontSize: "12px", fontFamily: "Gotham-Medium", textDecoration: "none" }} onClick={() => navigate("/sign-up")}>SIGN UP</Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
            {loading &&
                <PageLoader />
            }
        </>
    )
}
