import React, { useState } from 'react'
import { Box, Divider, IconButton, Typography, colors, } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function PaymentStatus() {
    const [params] = useSearchParams()
    const [color, setColor] = useState({ color1: "", color2: "" })
    console.log("color: ", color);
    const data = {
        status: params.get("status"),
        amount: params.get("amount"),
        InvoiceId: params.get("InvoiceId"),
        paymentMethod: params.get("paymentMethod"),
        type: params.get("type"),
    }
    console.log("data: ", data);

    const statusChange = (val) => {
        if (val === "Paid") return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{
                backgroundColor: "#d3f3e4",
                borderRadius: "50%",
                padding: "25px",
                mb: 2
            }}>
                <Box sx={{
                    backgroundColor: "#c2efda",
                    borderRadius: "50%",
                    padding: "25px"
                }}>
                    <img src="/images/icons/success.png" width={120} alt="" />
                </Box>
            </Box>
            <Typography sx={{ textAlign: "center", fontFamily: "Gotham-Medium", fontSize: "25px" }}>Payment Successful</Typography>
            <Typography sx={{ textAlign: "center", fontFamily: "Gotham-Medium", fontSize: "14px" }}>Transaction Number: <Box sx={{ color: "blueviolet" }} component="span">{data.InvoiceId}</Box></Typography>
        </Box>
        else if (val === "Canceled") return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{
                backgroundColor: "#f3d3d3",
                borderRadius: "50%",
                padding: "25px",
                mb: 2
            }}>
                <Box sx={{
                    backgroundColor: "#efc2c2",
                    borderRadius: "50%",
                    padding: "25px"
                }}>
                    <img src="/images/icons/reject.png" width={120} alt="" />
                </Box>
            </Box>
            <Typography sx={{ textAlign: "center", fontFamily: "Gotham-Medium", fontSize: "25px" }}>Payment Canceled</Typography>
            <Typography sx={{ textAlign: "center", fontFamily: "Gotham-Medium", fontSize: "14px" }}>Transaction Number: <Box sx={{ color: "blueviolet" }} component="span">{data.InvoiceId}</Box></Typography>
        </Box>
        else if (val === "Pending") return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{
                backgroundColor: "#f3ead3",
                borderRadius: "50%",
                padding: "25px",
                mb: 2
            }}>
                <Box sx={{
                    backgroundColor: "#efdbc2",
                    borderRadius: "50%",
                    padding: "25px"
                }}>
                    <img src="/images/icons/warning.png" width={120} alt="" />
                </Box>
            </Box>
            <Typography sx={{ textAlign: "center", fontFamily: "Gotham-Medium", fontSize: "25px" }}>Payment Pending</Typography>
            <Typography sx={{ textAlign: "center", fontFamily: "Gotham-Medium", fontSize: "14px" }}>Transaction Number: <Box sx={{ color: "blueviolet" }} component="span">{data.InvoiceId}</Box></Typography>
        </Box>
    }

    return (
        <>
            <Box sx={{
                height: "100vh",
                width: { md: "400px", xs: "100%" },
                marginX: "auto",
                borderInline: "1px solid lightgray",
            }}>
                {/* <Box sx={{ position: "fixed", width: { md: "400px", xs: "100%" }, height: "42px", zIndex: "9999" }}>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        backgroundColor: backgroundColor
                    }}>
                        <Box sx={{ position: "absolute", top: 0, left: 0, bottom: 0, my: "auto", display: "flex" }}>
                            <IconButton onClick={() => navigate('/')}>
                                <img src="/images/icons/back.png" style={{ width: 20, height: 20 }} alt='menu icon' />
                            </IconButton>
                        </Box>
                        <Typography sx={{ fontSize: "16px", textAlign: "center", fontFamily: "Gotham-Black" }}>Payment Confirmation</Typography>
                    </Box>
                </Box> */}
                <Box sx={{ height: "100%", width: { md: "400px", xs: "100%" }, marginX: "auto" }}>
                    <Box sx={{
                        height: "100%",
                        p: 4, position: "relative",
                    }}>
                        {data &&
                            <Box sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                            }}>
                                {statusChange(data.status)}
                                <Box>
                                    <Divider sx={{ mt: 6, mb: 2 }} />
                                    <Typography sx={{ textAlign: "center", fontFamily: "Gotham-Medium", fontSize: "14px" }}>Amount paid: <Box sx={{ color: "blueviolet" }} component="span">{data.amount}</Box></Typography>
                                    <Typography sx={{ textAlign: "center", fontFamily: "Gotham-Medium", fontSize: "14px" }}>By: <Box sx={{ color: "blueviolet" }} component="span">{data.paymentMethod}</Box></Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box >
        </>
    )
}
