import ViewLayout from '../../layout/viewLayout'
import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ThemeInput from '../../components/shared-layout/Input'
import { ThemeDarkButton } from '../../components/shared-layout/buttons/Button'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider';
import { ToastContainer, toast } from 'react-toastify';
import { ProfileController } from '../../controllers/ProfileController'
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../context/UserContext';

export default function ChangePassword() {
    const [showPassword, setShowPassword] = useState(false)
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { getToken } = useContext(UserContext);
    const navigate = useNavigate()
    useEffect(() => {
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
    }, [])

    const submit = async () => {
        const token = await getToken();
        const validate = validatePassword();
        console.log(validate, 'validate');
        if (!validate.msg) {
            setLoading(true);
            const data = {
                oldPassword: oldPassword,
                password: password,
                confirmPassword: confirmPassword,
            };
            const instance = new ProfileController();
            const result = await instance.changePassword(data, token);
            if (result.status === 'success') {
                toast(result.message);
                setLoading(false);
                setOldPassword('');
                setPassword('');
                setConfirmPassword('');
                navigate('/profile');
            } else {
                toast(result.message);
                setLoading(false);
            }
        } else {
            toast(validate.msg);
        }
    };

    function validatePassword() {
        if (oldPassword !== '' && password !== '' && confirmPassword !== '') {
            if (password === confirmPassword) {
                if (password.length > 7) {
                    return true;
                } else {
                    return { msg: 'Password must be 8 characters.' };
                }
            } else {
                return { msg: 'New Password and Confirm Password does not each other.' };
            }
        } else {
            return { msg: 'please fill all details' };
        }
    }



    return (
        <>

            <ViewLayout>
                <Box sx={{
                    minHeight: "100vh",
                    maxHeight: "100%",
                    width: { md: "400px", xs: "100%" },
                    marginX: "auto",
                    overflow: "hidden",
                    borderInline: "1px solid lightgray",
                }}>
                    <Divider />
                    <Box sx={{
                        height: "calc(100vh - 60px)",
                        overflowY: "auto",
                    }}>
                        <Box sx={{
                            height: "100%",
                        }}>
                            <Box sx={{ pt: 2, px: 5, mb:10 }}>
                            <Typography sx={{ fontSize: "20px", fontFamily: "Gotham-Book" }}>CHANGE PASSWORD</Typography>
                            </Box>
                            <Box sx={{ px: 5 }}>
                                <Box mb={4}>
                                    <ThemeInput
                                        label={"OLD PASSWORD"}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Box>
                                <Box mb={2}>
                                    <ThemeInput
                                        label={"NEW PASSWORD"}
                                        type='text'
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Box>
                                <Box mb={8}>
                                    <ThemeInput
                                        label={"REPEAT PASSWORD"}
                                        type='text'
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </Box>

                                <Box mt={2}>
                                    <ThemeDarkButton style={{fontSize:"15px"}} onClick={submit}>Change Password</ThemeDarkButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ViewLayout>
            <ToastContainer />
        </>
    )
}
