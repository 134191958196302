import React, { useEffect, useState } from 'react'
import ViewLayout from '../../layout/viewLayout'
import { Typography, Box } from '@mui/material'
import { TermsController } from '../../controllers/TermsController'

const TermsCondition = () => {

  const [data, setData] = useState({});
  console.log("data: ", data);

  useEffect(() => {
    getTerms();
  }, [])
  const getTerms = async () => {
    const instance = new TermsController();
    const result = await instance.getTermsCondition();
    if (result.status === 'success') {
      setData(result.terms_and_conditions);
    }
  }

  return (
    <ViewLayout>
      <Box sx={{ px: 4, pt: 1.5, pb: 3 }}>
        <Typography variant='button' sx={{ fontFamily: "Gotham-Book", mb: 1 }}>TERMS & CONDITIONS</Typography>

        <Box sx={{ padding: "12px", maxHeight: "560px", overflow: "auto" }}>

          <Box sx={{ mt: 1 }}>
            <Typography fontSize={'12px'}>{data.description}</Typography>
          </Box>
        </Box>
      </Box>
    </ViewLayout>
  )
}

export default TermsCondition