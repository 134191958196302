import { Box } from '@mui/material'
import React from 'react'

const CustomCard = ({ children, sx }) => {
    return (
        <>
            <Box sx={{
                backgroundColor: "#f2f2f2",
                borderRadius: "16px",
                p: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ...sx
            }}>
                {children}
            </Box>
        </>
    )
}

const CustomShadowCard = ({ children, sx }) => {
    return (
        <>
            <Box sx={{
                backgroundColor: "#fefefe",
                boxShadow: "0px 0px 8px 0px #00000045",
                borderRadius: "25px",
                p: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ...sx
            }}>
                {children}
            </Box>
        </>
    )
}

export { CustomShadowCard, CustomCard }
