import React, { useEffect, useContext, useState } from 'react'
import ViewLayout from '../../layout/viewLayout'
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material'
import { CustomCard } from '../../components/shared-layout/card'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button'
import { RoundedDarkButton, RoundedLightButton, ThemeIconButton } from '../../components/shared-layout/buttons/Button';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { GroveController } from '../../controllers/GroveController';
import { UserContext } from '../../context/UserContext';
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import PageLoader from '../../components/pageLoader';
import { API_SUCCESS } from '../../config/ApiConfig';
import { RemoveOutlined } from '@mui/icons-material';
import ModalLayout from '../../components/modal/ModalLayout';

export default function Items() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState(false)
    // console.log("note: ", note);
    const [noteData, setNoteData] = useState("")
    const [data, setData] = useState()
    // console.log("data: ", data);
    const [quantity, setQuantity] = useState(0)
    const [addons, setAddons] = useState([]);
    console.log("addons: ", addons);
    // console.log("addonsho gya: ", addons);
    const [addonPrice, setAddonPrice] = useState(0);
    // console.log("addonPrice: ", addonPrice);
    const [total, setTotal] = useState()
    // console.log("total: ", total);
    // console.log("addonPrice: ", addonPrice);
    const { getToken } = useContext(UserContext)
    const id = searchParams.get("id");


    useEffect(() => {
        getDetail()
        getCartQuantity()
    }, [])

    const getDetail = async () => {
        console.log("getDetail")
        setLoading(true)
        const token = getToken();
        const instance = new GroveController()
        const result = await instance.getDoubleJoyDetail(token, id)
        console.log("result: ", result);
        if (result.success === true) {
            setData(result.data)
            setLoading(false)
        } else {
            setLoading(false)
            toast.error(result.massage)
        }
    }

    const handleNoteData = () => {
        console.log(noteData)
        setNote(false)
        updateItem(addons)
    }


    const getCartQuantity = async () => {
        setLoading(true)
        const token = getToken();
        const instance = new GroveController()
        const result = await instance.getCartItem(token)
        if (result.success === true) {
            if (result?.data?.relation?.items.length > 0) {
                const result2 = result?.data?.relation?.items.filter(item => item.attributes.optional_item_id === JSON.parse(id));
                console.log("result2: ", result2);
                setQuantity(result2[0]?.attributes?.quantity);
                setTotal(result2[0]?.attributes?.total_price);
                if (result2[0]?.attributes?.addons?.length) {
                    console.log("addons pre set", result2[0]?.attributes?.addons)
                    setAddons(JSON.parse(result2[0]?.attributes?.addons))
                } else {
                    setAddons([])
                }
            } else {
                setQuantity(0)
                setTotal(0)
                setAddons([])
                setLoading(false)
            }
            setLoading(false)
        } else {
            setLoading(false)
            toast.error(result.massage)
        }
    }


    const addCart = async (val) => {
        setLoading(true)
        const token = getToken();
        const instance = new GroveController()
        const result = await instance.addItem(token, id, val, noteData, JSON.stringify(addons),)
        if (result.status === 'success') {
            console.log("result: ", result);
            getCartQuantity()
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const RemoveItem = async () => {
        setLoading(true)
        const token = getToken();
        const instance = new GroveController()
        const result = await instance.removeItem(token, id)
        if (result.status === 'success') {
            console.log("result: ", result);
            getCartQuantity()
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const updateItem = async (allAddon) => {
        if (quantity > 0) {
            const token = getToken();
            const instance = new GroveController();
            setLoading(true);
            const result = await instance.addItem(
                token,
                data.id,
                quantity,
                noteData,
                JSON.stringify(allAddon),
            );
            console.log("result: ", result);
            if (result.status === 'success') {
                console.log("result chal r5aha hai  ", result);
                getCartQuantity()
                // let newItem = props.route.params.item;
                // setItem(newItem);
                setLoading(false)
            }
        } else {
            setLoading(false)
            console.log("addons error")
        }
    };

    useEffect(() => {
        // Code that depends on the updated addons state
        console.log("Updated addons array in useEffect:", addons);
    }, [addons]); // This will run whenever addons state changes

    const addAddon = async addItem => {
        console.log("addAddon: ", addItem);
        let allAddon = [...addons, { name: addItem.name, price: addItem.price }]
        setAddons(allAddon);
        let pr = 0;
        allAddon.map((i, index) => {
            pr = pr + JSON.parse(i.price);
        });
        setAddonPrice(pr)
        await updateItem(allAddon);
    };
    const removeAddon = async addItem => {
        console.log("addAddon: ", addItem);
        const allAddon = addons.filter(i => i.name !== addItem.name);
        setAddons(allAddon);

        let pr = 0;
        allAddon.map((i, index) => {
            pr = pr + JSON.parse(i.price);
        });
        setAddonPrice(pr)
        await updateItem(allAddon);
    };

    return (
        <ViewLayout backFunction={"/double-joy"} title="Double Joy" backgroundColor="#e2e3e5">
            {data &&
                <Box sx={{ height: "calc(100vh - 42px)", position: "relative", backgroundColor: "#e2e3e5" }}>
                    <Box sx={{ height: "40%" }}>
                        <Box sx={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            py: 2
                        }}>
                            <img src={API_SUCCESS + "/" + data.attributes.image} alt='' height={"100%"} />
                        </Box>
                    </Box>
                    <Box sx={{ height: "70%", mt: "-10%", position: "relative", backgroundColor: "#f2f2f2", borderRadius: "40px 40px 0px 0px" }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                            overflowY: "auto",
                            py: 2
                        }}>
                            <Box sx={{
                                p: 4,
                                rowGap: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    "& .MuiTypography-root": { fontFamily: "Gotham-Medium", fontSize: "20px", fontWeight: 700 }
                                }}>
                                    <Box>
                                        <Typography>{data.attributes.name}</Typography>
                                        <Typography variant='button' sx={{ fontSize: "12px !important", fontFamily: "Gotham-Light !important" }}>INGREDIENTS</Typography>
                                    </Box>
                                    <Box>
                                        <Typography>{data.attributes.price} QR</Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ fontSize: "9px", textAlign: "initial", fontFamily: "Gotham-Book !important" }}>{data.attributes.description}</Typography>
                            </Box>
                            <Box sx={{
                                backgroundColor: "#ffffff",
                                borderRadius: "40px 40px 0px 0px",
                                pt: 4,
                                px: 5,
                                pb: 6,
                            }}>
                                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                                    <Grid container>
                                        {JSON.parse(data.attributes.ingredients).map((item, index) =>
                                            <Grid key={index} item xs={3}>
                                                <CustomCard sx={{ p: '5px', mx: 1, "& .MuiTypography-root": { fontFamily: "Gotham-Book", fontWeight: 700 } }}>
                                                    <Typography sx={{ fontSize: "20px" }}>{item.value}</Typography>
                                                    <Typography sx={{ fontSize: "10px" }}>{item.name}</Typography>
                                                </CustomCard>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {quantity > 0 &&
                                        <Box sx={{ display: "flex", justifyContent: "center", borderRadius: "8px" }}>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#f2f2f2", p: 1,
                                                "& .MuiButton-root": { p: 0, minWidth: 0 }
                                            }}>
                                                {quantity > 1 ?
                                                    <ThemeIconButton onClick={() => addCart(quantity - 1)} style={{ backgroundColor: "black" }}>
                                                        <RemoveOutlined sx={{ color: 'white', fontSize: "20px" }} />
                                                    </ThemeIconButton>
                                                    :
                                                    <ThemeIconButton onClick={() => RemoveItem()} style={{ backgroundColor: "black" }}>
                                                        <DeleteOutlineOutlinedIcon sx={{ color: 'white', fontSize: "20px" }} />
                                                    </ThemeIconButton>
                                                }
                                                <Typography sx={{ fontSize: "13px", fontWeight: 900, mx: 2 }}>{quantity}</Typography>
                                                <ThemeIconButton onClick={() => addCart(quantity + 1)} style={{ backgroundColor: "black", fontSize: "20px" }}>
                                                    <AddOutlinedIcon sx={{ color: 'white', fontSize: "20px" }} />
                                                </ThemeIconButton>
                                            </Box>
                                        </Box>
                                    }
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: '#161415',
                                                fontFamily: 'Gotham-Medium',
                                                fontSize: "16px",
                                                marginVertical: 2,
                                                marginLeft: "10px",
                                                marginTop: 1
                                            }}>Add Ons</Typography>
                                        <Box>
                                            {data.attributes.addons?.length > 0 &&
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                    }}>
                                                    {data.attributes.addons?.map((addItem, index) => (
                                                        <Box sx={{ marginTop: "10px", width: "calc(100% - 50px)" }}>
                                                            <Box
                                                                sx={[
                                                                    {
                                                                        backgroundColor: '#f2f2f2',
                                                                        px: 1,
                                                                        py: '5px',
                                                                        textAlign: 'center',
                                                                        borderRadius: '5px',
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'space-between',
                                                                        width: "100%",
                                                                        alignItems: "center"
                                                                    },
                                                                    addons.filter(i => i.name === addItem.name)
                                                                        .length === 0
                                                                        ? {
                                                                            border: '1px solid',
                                                                            borderWidth: "1px",
                                                                            borderColor: '#888',
                                                                            backgroundColor: '#fff',
                                                                        }
                                                                        : {
                                                                            border: '1px solid',
                                                                            borderWidth: "1px",
                                                                            borderColor: '#888'
                                                                        },
                                                                ]}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                        fontFamily: 'Gotham-Medium',
                                                                        paddingRight: "10px",
                                                                    }}>
                                                                    {addItem.name}
                                                                </Typography>
                                                                <Box
                                                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", columnGap: "10px" }}>
                                                                    <Typography sx={{
                                                                        fontSize: 12,
                                                                        fontFamily: 'Gotham-Medium',
                                                                    }}>
                                                                        {addItem.price} QR
                                                                    </Typography>

                                                                    {addons.filter(i => i.name === addItem.name)
                                                                        .length ? (
                                                                        <IconButton
                                                                            size='small'
                                                                            onClick={() => removeAddon(addItem)}
                                                                            sx={{
                                                                                backgroundColor: '#888',
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    ) : (
                                                                        <IconButton
                                                                            size='small'
                                                                            onClick={() => addAddon(addItem)}
                                                                        >
                                                                            <AddIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                    <Box>
                                        <RoundedDarkButton onClick={() => setNote(true)} style={{ fontFamily: "Gotham-Light" }}>
                                            ADD NOTE TO ORDER
                                        </RoundedDarkButton>
                                    </Box>
                                    <Box>
                                        {quantity > 0 ?
                                            <RoundedDarkButton onClick={() => navigate("/cart")} style={{ fontFamily: "Gotham-Light" }}>
                                                <Box sx={{ px: 2, display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                    <Box component={"span"}>
                                                        GO TO CART
                                                    </Box>
                                                    <Box component={"span"}>
                                                        {total} QAR
                                                    </Box>
                                                </Box>
                                            </RoundedDarkButton>
                                            :
                                            <RoundedDarkButton onClick={() => addCart(1)} style={{ fontFamily: "Gotham-Light" }}>
                                                ADD TO CART
                                            </RoundedDarkButton>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <ModalLayout
                        openModal={note}
                        setCancel={() => console.log("Cancelled")}
                        title="Add Note for Product Details"
                    >
                        <Box>
                            <TextField onChange={(e) => setNoteData(e.target.value)} variant='standard' sx={{ ".MuiInputBase-input": { fontSize: "18px", p: "5px" } }} fullWidth type='text' />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2, justifyContent: "flex-end" }}>
                            <RoundedLightButton onClick={() => setNote(false)}>Cancel</RoundedLightButton>
                            <RoundedDarkButton onClick={handleNoteData}>Add Note</RoundedDarkButton>
                        </Box>
                    </ModalLayout>
                </Box>
            }
            {loading && <PageLoader />}
        </ViewLayout>
    )
}
