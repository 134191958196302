import React from 'react'
import MainLayout from '../../layout/mainLayout'
import BackDiv from '../../components/backDiv'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ClassController } from '../../controllers/ClassController'
import { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../context/UserContext'
import PageLoader from '../../components/pageLoader'
import { HappeningContoller } from '../../controllers/HappeningController'
import { API_SUCCESS } from '../../config/ApiConfig'
import Skeleton from '@mui/material/Skeleton';
import Slider from "react-slick";
import { SkeltonBlackCard } from '../../components/skeleton/skeleton'



export default function HomePage() {
    const { getToken } = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({});
    const [marketImage, setMarketImage] = useState();
    const [challenges, setChallenges] = useState([]);

    const navigate = useNavigate()


    useEffect(() => {
        getData();
        getHappening();
    }, []);

    const getHappeningLength = () => {
        if (challenges.length > 2) {
            return true;
        }
        else {
            return false;
        }
    }

    const settings = {
        dots: true,
        infinite: getHappeningLength() ? true : false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const getData = async () => {
        setLoading(true)
        const token = getToken();
        const instance = new ClassController();
        const result = await instance.getHomeLocation(token);
        setData(result.locations)
        setLoading(false)
    }

    const getHappening = async () => {
        const token = await getToken();
        const instance = new HappeningContoller();
        const result = await instance.getAllChallenges(token);
        setMarketImage({ doubleJoy: result.doublejoy_banner, store: result.store_banner });
        const sortData = result?.happenings.sort((a, b) => a.position - b.position);
        setChallenges(sortData);
    };

    const goToDetail = (item) => {
        if (item?.enroll?.id) {
            navigate("/happening-report?id=" + item.id)
        }
        else {
            navigate("/happening-detail?id=" + item.id)
        }
    }

    return (
        <MainLayout>
            <Box sx={{ px: 3, pt: 2, pb: 2 }}>
                <Typography sx={{ fontSize: "14px", fontFamily: "Gotham-Book" }} variant='button'>TRAIN</Typography>
                {!loading ?
                    data.length && data.map((val, index) =>
                        <React.Fragment key={index}>
                            {index < 3 &&
                                <BackDiv onClick={() => {
                                    localStorage.setItem('activeStudio', JSON.stringify([index]));
                                    navigate(`/classes`)
                                }} sx={{ mt: "5px", "& .MuiBox-root": { height: "40px" } }} img={API_SUCCESS + '/' + val.image} imageStyle={{ objectFit: "cover" }} title={val.name} />
                            }
                        </React.Fragment >
                    )
                    : <>
                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                            <SkeltonBlackCard />
                            <SkeltonBlackCard />
                            <SkeltonBlackCard />
                        </Box>

                    </>
                }
            </Box>
            {(challenges && challenges.length > 0) &&
                <Box sx={{ px: 3, pt: 2, pb: 2 }}>
                    <Typography sx={{ fontSize: "14px", fontFamily: "Gotham-Book", pt: 2 }} variant='button'>HAPPENING NOW</Typography>
                    <Slider {...settings}>
                        {challenges.map((item, index) =>
                            <Box key={index}>
                                <BackDiv onClick={() => goToDetail(item)} sx={{ mt: "1px", pr: 2, "& .MuiBox-root": { height: "40px" } }} img={API_SUCCESS + '/' + item.image} />
                            </Box>
                        )}
                    </Slider>
                </Box>
                // :
                // <Box sx={{ px: 3, pt: 2, pb: 2 }}>
                //     <Typography sx={{ fontSize: "15px", fontFamily: "Gotham-Book", pt: 2 }} variant='button'>HAPPENING NOW</Typography>
                //     <Box sx={{ display: "flex", justifyContent: "space-between", columnGap: 2 }}>
                //         <Skeleton variant="rounded" width={"50%"} height={100} />
                //         <Skeleton variant="rounded" width={"50%"} height={100} />
                //     </Box>
                // </Box>
            }
            <Box sx={{ px: 3, pb: 2 }}>
                <Typography sx={{ fontSize: "14px", fontFamily: "Gotham-Book", pt: 2 }} variant='button'>DOUBLE JOY</Typography>
                <BackDiv onClick={() => navigate("/double-joy")} img={marketImage?.doubleJoy} imageStyle={{ objectFit: "cover" }} sx={{ mt: "1px", "& .MuiBox-root": { height: "70px" } }} />
            </Box>
            <Box sx={{ px: 3, pb: 2 }}>
                <Typography sx={{ fontSize: "14px", fontFamily: "Gotham-Book", pt: 2 }} variant='button'>STORE</Typography>
                <BackDiv title={"Coming Soon..."} onClick={() => console.log("/store")} img={marketImage?.store} imageStyle={{ objectFit: "cover" }} sx={{ mt: "1px", "& .MuiBox-root": { height: "70px" } }} />
            </Box>
        </MainLayout>
    )
}
