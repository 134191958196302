import Box from '@mui/material/Box';
import React from 'react'
import Header from '../components/shared-layout/header';
import Footer from '../components/shared-layout/footer';

export default function MainLayout(props) {
    const { children } = props;


    return (
        <>
            <Box sx={{
                height: "100vh",
                minHeight: "100vh",
                maxHeight: "100%",
                width: { md: "400px", xs: "100%" },
                marginX: "auto",
                overflow: "hidden",
                borderInline: { md: "1px solid lightgray", xs: "none" },
            }}>
                <Box sx={{ position: "fixed", width: { md: "400px", xs: "100%" }, height: "42px", zIndex: 1000 }}>
                    <Header />
                </Box>
                <Box sx={{ pt: "42px", mb: "50px", overflowY: "auto", height: "calc(100vh - 92px)" }}>
                    {children}
                </Box>
                <Box sx={{ position: "fixed", overflow: "hidden", zIndex: 99, boxShadow: "2px 0px 10px 0px black", width: { md: "400px", xs: "100%" }, bottom: -1, height: "50px" }}>
                    <Footer />
                </Box>
            </Box>
        </>
    )
}
