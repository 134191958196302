import { API_BASE } from '../config/ApiConfig';

export class GroveController {

    async getDoubleJoy(token) {
        return fetch(API_BASE + '/category/get', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return { success: true, data: responseJson };
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }

    async getDoubleJoyOrder(token) {
        return fetch(API_BASE + '/order/get', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return { success: true, data: responseJson };
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }

    async getDoubleJoyDetail(token, id) {
        return fetch(API_BASE + `/optionalitem/${id}/details`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return { success: true, data: responseJson };
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }

    async getCartItem(token) {
        return fetch(API_BASE + '/cart/get', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return { success: true, data: responseJson };
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }

    async addItem(token, id, qty, noteData, addons) {
        const newData = new FormData();
        newData.append('optional_item_id', id);
        newData.append('quantity', qty);
        newData.append('notes', noteData);
        if (addons) {
            newData.append('addons', addons);
        }

        console.log(newData, token)
        return fetch(API_BASE + '/cart/add', {
            method: 'POST',
            body: newData,
            headers: {
                Authorization: 'Bearer ' + token,
                Accept: 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return responseJson;
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }

    async removeItem(token, id) {
        const newdata = new FormData();
        newdata.append('optional_item_id', id);

        return fetch(API_BASE + '/cart/remove', {
            method: 'POST',
            body: newdata,
            headers: {
                Authorization: 'Bearer ' + token,
                Accept: 'application/json',
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                return responseJson;
            })
            .catch(error => {
                console.log(error);
                return { success: false, error };
            });
    }


    async checkout(token, cart_id, type, note) {
        var myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Authorization', 'Bearer ' + token);

        const newdata = new FormData();
        newdata.append('cart_id', cart_id);
        newdata.append('type', type);
        if (note || note?.length) {
            newdata.append('note', note);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: newdata,
            redirect: 'follow',
        };

        return fetch(API_BASE + '/order/checkout', requestOptions)
            .then(response => response.text())
            .then(result => {
                return JSON.parse(result);
            })
            .catch(error => {
                return error;
            });
    }




}