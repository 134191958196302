import { API_BASE } from '../config/ApiConfig';

export class AuthContoller {

  async loginUser(email, password) {
    const newdata = new FormData();
    newdata.append('email', email);
    newdata.append('password', password);
    return fetch(API_BASE + '/auth/login', {
      method: 'POST',
      body: newdata,
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson, 'response')
        return responseJson;
      })
      .catch(error => {
        console.log(error);
        return { success: false, error };
      });
  }

  async signUpUser(data) {

    const newdata = new FormData();
    newdata.append('first_name', data.firstName);
    newdata.append('last_name', data.lastName);
    newdata.append('email', data.email);
    newdata.append('password', data.password);
    newdata.append('phone', data.phone);
    newdata.append('dob', data.dob);
    newdata.append('password_confirmation', data.password);
    newdata.append('gender', data.gender);
    newdata.append('terms_and_conditions', 1);
    newdata.append('referral_code', '');

    return fetch(API_BASE + '/auth/register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: newdata,
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson, 'response')
        return responseJson;
      })
      .catch(error => {
        console.log(error);
        return { success: false, error };
      });
  }

  async forgotPassword(email) {
    const newdata = new FormData();
    newdata.append('email', email);
    return fetch(API_BASE + '/auth/password/reset/request', {
      method: 'POST',
      body: newdata,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result, 'reee')
        return { success: true, data: result };
      })
      .catch(error => {
        console.log(error);
        return { success: false, error };
      });
  }


  async resendOtp(email) {
    const newdata = new FormData();
    newdata.append('email', email);
    return fetch(API_BASE + '/auth/resend-otp', {
      method: 'POST',
      body: newdata,
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson, 'reee');
        return responseJson;
      })
      .catch(error => {
        console.log(error);
        return { success: false, error };
      });
  }

  async verifyOtp(email, otp) {
    const newdata = new FormData();
    newdata.append('email', email);
    newdata.append('otp', otp);
    return fetch(API_BASE + '/auth/verify-otp', {
      method: 'POST',
      body: newdata,
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson, 'reee');
        return responseJson;
      })
      .catch(error => {
        console.log(error);
        return { success: false, error };
      });
  }
}
