import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Card, Box, Typography, Grid } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock';
import { JourneyContoller } from '../../controllers/JourneyController';
import { UserContext } from '../../context/UserContext';
import { API_SUCCESS } from '../../config/ApiConfig';

const AllAchievements = () => {
    const [data, setData] = useState([]);
    console.log("data: ", data);
    const [loading, setLoading] = useState(false);
    const { getToken } = useContext(UserContext);

    useEffect(() => {
        getBadges();
    }, []);
    const getBadges = async () => {
        setLoading(true);
        const token = await getToken();
        const instance = new JourneyContoller();
        const result = await instance.getAllBedges(token);
        setLoading(false);
        setData(result.badges);
    };

    return (
        <MainLayout>
            <Box sx={{ px: 1.5, pt: 1.5, pb: 4 }}>
                <Typography variant="button" sx={{ fontFamily: "Gotham-Book" }}>
                    Achievements
                </Typography>

                <Box
                    sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        flexWrap: "wrap"
                    }}
                >
                    <Grid container sx={{ mx: 1, rowGap: 3 }}>
                        {data.map((item, index) => (
                            <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={4} sm={4} key={index}>
                                <Card
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "20px",
                                        p: 1,
                                        backgroundColor: "#f2f2f2",
                                        boxShadow: "none",
                                        backgroundImage: `url(${API_SUCCESS}/${item.image})`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        border: '1px solid #f2f2f2',
                                        position: 'relative',
                                    }}
                                >
                                    {!item?.status &&
                                        <Box sx={{
                                            width: "100%",
                                            height: "100%",
                                            position: "absolute",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backdropFilter: "blur(1px)",
                                            backgroundColor: "#ffe4c41f"
                                        }}>
                                            <LockIcon fontSize="large" />
                                        </Box>
                                    }
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </MainLayout>
    )
}

export default AllAchievements
