import React, { useEffect, useState, useContext } from 'react'
import MainLayout from '../../layout/mainLayout'
import { Box, Grid, Typography, Skeleton, CircularProgress } from '@mui/material'
import { RoundedDarkButton } from '../../components/shared-layout/buttons/Button';
import { CustomCard } from '../../components/shared-layout/card';
import CircleIcon from '@mui/icons-material/Circle';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { UserContext } from '../../context/UserContext';
import { PlannerContoller } from '../../controllers/PlannerController';
import { useNavigate } from 'react-router-dom';
import { SkeltonCard } from '../../components/skeleton/skeleton';
import ConfirmModal from '../../components/modal/confirmModal';
import { toast } from 'react-toastify';
import { ClassController } from '../../controllers/ClassController';
import PageLoader from '../../components/pageLoader';
import Calendar from '../../components/calendar/Calendar';
import moment from 'moment';




const UpcomingClasses = () => {
    // const [refresh, setRefresh] = useState(true);
    const [data, setData] = useState([]);
    const [filterClass, setFilterClass] = useState()
    const [selectedDate, setSelectedDate] = useState()
    const [open, setOpen] = useState(false)
    // const [active, setActive] = useState();
    const [globalIndex, setGlobalIndex] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const { getToken } = useContext(UserContext);
    const [cancelId, setCancelId] = useState();
    const navigate = useNavigate()


    useEffect(() => {
        getBookings();
    }, []);

    const getBookings = async () => {
        setLoading(true);
        setData();
        const token = await getToken();
        const instance = new PlannerContoller();
        const result = await instance.getAllBooking(token);
        setLoading(false);
        const result1 = result.data.filter(item => item.attributes.status !== 'Cancelled');
        setData(result1);
    };

    const joinGroup = allData => {
        const groupByCategory = allData.reduce((group, product) => {
            const loc = product.relation.classes.relations.location.attributes.name;
            group[loc] = group[loc] ?? [];
            group[loc].push(product);
            return group;
        }, {});

        const array = [];
        Object.keys(groupByCategory).map(function (key) {
            array.push({
                name: key,
                data: groupByCategory[key],
            });
        });
        return array;
    };

    const handleOpen = (val) => {
        setCancelId(val)
        setOpen(true)
    }

    const CancelBooking = async () => {
        setLoading1(true)
        const token = await getToken();
        const instance = new ClassController();
        const result = await instance.cancelClass(cancelId, token);
        if (result.status === 'success') {
            toast(result.msg);
            setLoading1(false)
            setOpen(false)
            getBookings()
        } else {
            toast(result.msg);
            setLoading1(false)
        }
    }

    const gableSet = async () => {
        const result = localStorage.getItem('di');
        if (result) {
            setGlobalIndex(JSON.parse(result));
            var date = moment(new Date(), 'YYYY-MM-DD')
                .add(result, 'days')
                .format('YYYY-MM-DD');
            if (data) {
                const filterData = data.filter(item =>
                    moment(item.attributes.booked_date_standard).isSame(date),
                );
                const allData = joinGroup(filterData)
                setFilterClass(allData);
            }
        } else {
            setGlobalIndex(0);
        }
    }

    useEffect(() => {
        gableSet()
    }, [data]);

    const onSelectDate = (date, i) => {
        // setLoading(true);
        var dt = moment(date).format('YYYY-MM-DD');
        localStorage.setItem('date', dt);
        localStorage.setItem('di', JSON.stringify(i));
        if (data) {
            const filterData = data.filter(item =>
                moment(item.attributes.booked_date_standard).isSame(dt),
            );
            const allData = joinGroup(filterData)
            setFilterClass(allData);
        }
        setLoading(false);
    };

    return (
        <>
            {loading === true ?
                <MainLayout>
                    <Box sx={{ px: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", zIndex: 1, width: { md: "400px", xs: "100%" }, paddingBlock: "20px", backgroundColor: "white" }}>
                            <RoundedDarkButton style={{ width: "200px", height: "35px" }}>UPCOMING</RoundedDarkButton>
                        </Box>
                        <Skeleton sx={{ mb: 1, height: "25px", width: "100px" }} />
                        <SkeltonCard />
                        <SkeltonCard />
                        <SkeltonCard />
                    </Box>
                </MainLayout>
                :
                <MainLayout>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "fixed", top: "75px", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1, width: { md: "400px", xs: "100%" }, paddingBlock: "20px", backgroundColor: "white" }}>
                        <RoundedDarkButton style={{ width: "200px", height: "35px" }}>UPCOMING</RoundedDarkButton>
                    </Box>

                    {(data && data.length > 0) ?
                        <Box sx={{ px: 1.5, pt: 10, pb: 4, paddingX: 1.5, position: "relative" }}>
                            {(globalIndex !== '') ?
                                <Calendar
                                    onSelectDate={onSelectDate}
                                    globalIndex={globalIndex}
                                />
                                :
                                <></>
                            }
                            {filterClass?.map((val, index) =>
                                <Box key={index}>
                                    <Typography sx={{ fontSize: "15px", display: "flex", fontFamily: "Gotham-Medium", letterSpacing: "1px", mb: "2px", textTransform: 'uppercase' }} variant='button'>{val.name}</Typography>
                                    {
                                        val.data.map((item, index) =>
                                            <Box
                                                onClick={() => navigate('/class-layout?id=' + item.relation.classes.id)}
                                                key={index}
                                            >
                                                <Box>
                                                    <CustomCard sx={{ mb: 1 }}>
                                                        <Box sx={{ width: "100%" }}>
                                                            <Grid sx={{ alignItems: "center" }} container spacing={1}>
                                                                <Grid item xs={2}>
                                                                    <Box sx={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                                                        <img src={item.relation.classes.relations.trainer.attributes.image} style={{ borderRadius: "12px" }} height="100%" width="100%" alt='Avatar' />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={2.5}>
                                                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", rowGap: 0.2 }}>
                                                                        <Box sx={{ display: "flex", justifyContent: "flex-start", }}>
                                                                            <Typography sx={{ fontSize: "14px", textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', lineHeight: 1, fontFamily: "Gotham-Black", letterSpacing: "1.5px", textTransform: 'uppercase' }} variant='button'>{item.relation.classes.attributes.title}</Typography>
                                                                        </Box>
                                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                            <Typography sx={{ fontSize: '8px', mt: '3px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textTransform: 'uppercase', fontFamily: 'Gotham-Book' }} variant='button'>{item.relation.classes.attributes.theme_name}</Typography>
                                                                        </Box>
                                                                        <Typography sx={{ fontSize: '8px', textTransform: 'uppercase', fontFamily: 'Gotham-Light', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', }} variant='button'>
                                                                            {item.relation.classes.relations.trainer.attributes.first_name}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", columnGap: 1, alignItems: "center" }} item xs={7.5}>
                                                                    <Box>
                                                                        <Box sx={{ display: "flex", rowGap: 0.2, flexDirection: "column", justifyContent: 'center', alignItems: "flex-start" }}>
                                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", columnGap: 1 }}>
                                                                                <img alt='' src="/images/icons/calendar.png" style={{ width: '8px', height: "8px" }} />
                                                                                <Typography sx={{ fontSize: "8px", fontFamily: "Gotham-Light" }}>{item.relation.classes.attributes.start_date}</Typography>
                                                                            </Box>

                                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", columnGap: 1 }}>
                                                                                <img alt='' src="/images/icons/clock.png" style={{ width: '8px', height: "8px" }} />
                                                                                <Typography sx={{ fontSize: "8px", fontFamily: "Gotham-Light" }}>{item.attributes.timing}</Typography>
                                                                            </Box>
                                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", columnGap: 1 }}>
                                                                                <CircleIcon sx={{ fontSize: '10px' }} />
                                                                                <Typography sx={{ fontSize: "8px", fontFamily: "Gotham-Light" }}>SPOT - {item.attributes.seat}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", rowGap: 0.5, paddingRight: "8px" }}>
                                                                        {item.attributes.waiting_no ? (
                                                                            <RoundedDarkButton
                                                                                style={{ fontSize: "8px", width: "100%", py: "3px", fontFamily: "Gotham-Book", backgroundColor: '#000' }}
                                                                                onClick={() => navigate('/class-layout?id=' + item.relation.classes.id)}>
                                                                                Waitlist : {item.attributes.waiting_no}
                                                                            </RoundedDarkButton>
                                                                        ) : (
                                                                            <RoundedDarkButton
                                                                                style={{ fontSize: "8px", width: "100%", py: "3px", fontFamily: "Gotham-Book", backgroundColor: '#161415' }}
                                                                                onClick={() => navigate('/class-layout?id=' + item.relation.classes.id)}>
                                                                                Go to class
                                                                            </RoundedDarkButton>
                                                                        )}
                                                                        <RoundedDarkButton
                                                                            style={{ fontSize: "8px", width: "100%", py: "3px", fontFamily: "Gotham-Book", backgroundColor: 'red' }}
                                                                            onClick={() => handleOpen(item.id)}
                                                                        >
                                                                            {item.attributes.waiting_no ? 'Leave Class' : 'Cancel Booking'}
                                                                        </RoundedDarkButton>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </CustomCard>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </Box>
                            )}
                        </Box>
                        :
                        <Box sx={{ mt: '50%', textAlign: 'center' }}>
                            <Typography sx={{ fontSize: 14, fontFamily: 'Gotham-Medium' }}>No upcoming bookings. </Typography>
                            <RoundedDarkButton style={{ width: "120px", mt: 2, padding: '3px' }} onClick={() => navigate('/classes')} >Book Now</RoundedDarkButton>
                        </Box>
                    }
                    <ConfirmModal setOpen={setOpen} title={"You want to leave this class"} open={open} handleWalletPay={CancelBooking} />
                    {loading1 && <PageLoader />}
                </MainLayout >
            }

        </>
    )
}

export default UpcomingClasses