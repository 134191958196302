import React from "react";
import { parseCookies } from 'nookies'
import { atom } from "recoil";


const getToken = () => {
    const cookies = parseCookies()
    return cookies.access_token
};

export const authState = atom({
    key: 'user',
    default: getToken()
});