import { useTheme } from "@emotion/react";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";

const ThemeButton = ({ onClick, children, style }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.secondary.main,
          textTransform: "uppercase",
          fontSize: "24px",
          color: theme.palette.common.dark,
          fontWeight: "700",
          padding: "7px 3rem",
          borderRadius: "10px",
          display: "block",
          margin: "0px auto",
          // ":hover": {
          //   backgroundColor: theme.palette.primary.main,
          //   color: theme.palette.common.white,
          // },
          ...style,

        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};
const ThemeLightButton = ({ onClick, children, style }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#f2f2f2",
          fontSize: "24px",
          textTransform: "uppercase",
          color: "black",
          fontWeight: "400",
          padding: "10px 3rem",
          borderRadius: "16px",
          width: "100%",
          ...style,
          ":hover": {
            backgroundColor: "#f2f2f2",
            color: theme.palette.common.black,
          }
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};

const ThemeDisabledButton = ({ onClick, children, style }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        disabled
        variant="contained"
        sx={{
          backgroundColor: theme.palette.common.white,
          textTransform: "uppercase",
          fontSize: "24px",
          color: "black",
          fontWeight: "700",
          padding: "8px 8px",
          borderRadius: "16px",
          width: "100%",
          ...style,
          // ":hover": {
          //   backgroundColor: theme.palette.primary.main,
          //   color: theme.palette.common.white,
          // },
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};
const ThemeDarkButton = ({ onClick, children, loading, style }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "black",
          fontSize: "24px",
          color: theme.palette.common.white,
          fontWeight: "700",
          padding: "8px 8px",
          borderRadius: "16px",
          width: "100%",
          textTransform: "uppercase",
          ...style,
          ":hover": {
            backgroundColor: "black",
            color: theme.palette.common.white,
          },
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};

const RoundedLightButton = ({ onClick, children, loading, style }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#f2f2f2",
          textTransform: "uppercase",
          color: theme.palette.common.black,
          padding: "8px 8px",
          borderRadius: "25px",
          width: "100%",
          ...style,
          ":hover": {
            backgroundColor: "#f2f2f2",
            color: theme.palette.common.black,
          }
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};

const RoundedDarkButton = ({ onClick, children, style }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        onClick={onClick}
        variant="contained"
        sx={{
          textTransform: "uppercase",
          backgroundColor: "black",
          color: theme.palette.common.white,
          padding: "8px 8px",
          borderRadius: "25px",
          width: "100%",
          ...style,
          ":hover": {
            backgroundColor: "black",
            color: theme.palette.common.white,
          },
        }}
      >
        {children}
      </Button>
    </>
  );
};

const RoundedRedButton = ({ onClick, children, style }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          textTransform: "uppercase",
          backgroundColor: "#eb0101",
          color: theme.palette.common.white,
          padding: "8px 8px",
          borderRadius: "25px",
          width: "100%",
          ...style,
          ":hover": {
            backgroundColor: "#eb0101",
            color: theme.palette.common.white,
          },
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};

const ThemeIconButton = ({ onClick, children, loading, style }) => {
  const theme = useTheme();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "black",
          color: theme.palette.common.white,
          ...style,
          ":hover": {
            backgroundColor: "black",
            color: theme.palette.common.white,
          },
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};
export {
  ThemeButton,
  ThemeLightButton,
  ThemeDarkButton,
  ThemeDisabledButton,
  RoundedDarkButton,
  RoundedLightButton,
  ThemeIconButton,
  RoundedRedButton
};