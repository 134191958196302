import React from 'react'
import MainLayout from '../../layout/mainLayout'
import { Typography, Box, CardContent } from '@mui/material'
import Card from '@mui/material/Card';
import { RoundedDarkButton } from '../../components/shared-layout/buttons/Button';
import { WalletController } from '../../controllers/WalletController';
import { useState,useEffect,useContext } from 'react';
import { UserContext } from '../../context/UserContext'
import { useNavigate } from 'react-router-dom';

const AddCard = () => {
    const [loading, setLoading] = useState(true);
    const {getToken} = useContext(UserContext);
    const [balance, setBalance] = useState(0);
    const navigate = useNavigate();
  
    useEffect(() => {
      getMyBanance()
    },[])
  
  
    const getMyBanance = async () => {
      const token = await getToken();
      const instance = new WalletController();
      const result = await instance.getBalance(token)
      console.log(result,'ress')
      setLoading(false)
      if(result?.attributes?.balance){
        setBalance(result.attributes.balance)
      }
    }
    return (
        <MainLayout>
            <Box sx={{ px: 4, pt: 1.5, pb: 4 }}>
                <Typography variant='button' sx={{ fontFamily: "Gotham-Book" }}>Wallet</Typography>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: 'column', mt: 2 }}>
                    <Card sx={{ display: 'flex', justifyContent: "center", alignitems: "center", borderRadius: "30px", height: "100%", width: "100%", boxShadow: " rgba(0, 0, 0, 0.15) 0px 6px 13px 2px" }}>
                        <CardContent>
                            <Typography sx={{ fontSize: "30px", fontWeight: "bold", paddingY: 6 }}>{balance} QR</Typography>
                        </CardContent>
                    </Card>
                    <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <Box mb={1.5}>
                            <RoundedDarkButton onClick={() => navigate('/add-amount')} style={{ width: "125px", height: "35px" }} >
                                TOP UP
                            </RoundedDarkButton>
                        </Box>
                        
                    </Box>
                </Box>
            </Box>
        </MainLayout>
    )
}

export default AddCard