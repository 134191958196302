import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress"

export default function PageLoader() {
    return (
        <Box
            sx={{
                position: "absolute",
                top: "0",
                right: "0",
                left: "0",
                bottom: "0",
                display:"flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "9999",
            }}
        >
            <CircularProgress sx={{ color: "darkgray" }} />
        </Box>
    )
}