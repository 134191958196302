import React from 'react'
import MainLayout from '../../layout/mainLayout'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useState, useEffect, useRef } from 'react';
import Badge from '@mui/material/Badge';
import ShoppingCard from '../../components/shopping/shoppingCard';
import { useNavigate } from 'react-router-dom';

export default function Store() {
    const [height, setHeight] = useState(null)
    const navigate = useNavigate()

    const ref = useRef()

    useEffect(() => {
        setHeight(ref.current.offsetHeight);
    }, [])
    return (
        <MainLayout>
            <Box sx={{ height: "calc(100vh - 130px)", position: "relative" }} mx={2}>
                <Box ref={ref} py={2}>
                    <Box sx={{ position: "relative", mb: 2 }}>
                        <Typography  sx={{fontSize:"15px", textAlign: "center", fontFamily:"Gotham-Medium" }}>STORE</Typography>
                        <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                            <IconButton>
                                <Badge badgeContent={1} color="error">
                                    <ShoppingCartOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </Box>
                    </Box>
                    {/* <Grid container>
                        <Grid item xs={4}>
                            <Box px={1}>
                                <RoundedLightButton>Shakes</RoundedLightButton>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box px={1}>
                                <RoundedLightButton>Shakes</RoundedLightButton>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box px={1}>
                                <RoundedLightButton>Shakes</RoundedLightButton>
                            </Box>
                        </Grid>
                    </Grid> */}
                </Box>
                <Box sx={{
                    height: `calc(100vh - (130px + ${height}px))`,
                    maxHeight: "100%",
                    overflowY: "auto",
                    '&::-webkit-scrollbar': { width: 0 }
                }}>
                    <Grid container>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0,].map(() =>

                            <Grid item xs={6}>
                                <Box p={2}>
                                    <ShoppingCard
                                        image="/images/shoo.png"
                                        title="LOREM IPSUM"
                                        price="15 QR"
                                        onClick={() => navigate("/items")}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
        </MainLayout>
    )
}
